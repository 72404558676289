import { Dispatch, SetStateAction, useState } from "react";

import { Modal, Text, Group } from "@mantine/core";

import { ExistingAlerts } from "components";
import { Service } from "types";

type ServiceAlertModalProps = {
  opened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
  selectedService: Service;
  renavRefresh: () => void;
};

export const ExistingAlertsModal = ({
  opened,
  setOpened,
  selectedService,
  renavRefresh,
}: ServiceAlertModalProps) => {
  const [modalTitle, setModalTitle] = useState<string>("Existing Alerts ");

  function title() {
    return (
      <>
        <Group spacing={5}>
          <Text>{modalTitle} for </Text>
          <Text fw={500}>{selectedService?.serviceName}</Text>
        </Group>
      </>
    );
  }

  return (
    <>
      <Modal
        size="xl"
        opened={opened}
        onClose={() => {
          setOpened(false);
          renavRefresh();
        }}
        title={title()}
      >
        <ExistingAlerts
          setModalTitle={setModalTitle}
          serviceId={selectedService?.serviceId}
          isModal
          isOpened={opened}
          setIsOpened={setOpened}
          renavRefresh={renavRefresh}
        />
      </Modal>
    </>
  );
};
