import { useState, useEffect } from "react";
import {
  Stack,
  Text,
  Timeline,
  ThemeIcon,
} from "@mantine/core";

import {
  statusIcons,
} from "../common/Data";

import externalApi from "../api";
import endpoints from "../api/endpoints";
import { CircleCheck } from "tabler-icons-react";
import { StatusServiceAffectedResponse, StatusUpdateTimeline } from "types";

type RecentServiceHistoryProps = {
  serviceId: string | undefined;
  siteId: string | undefined | null;
};

export const RecentServiceHistory = ({
  serviceId,
  siteId,
}: RecentServiceHistoryProps) => {
  //const [selectedSite, setSelectedSite] = useState<string | undefined | null>(siteId);
  const [statusUpdates, setStatusUpdates] = useState<StatusServiceAffectedResponse[]>([]);
  const [timelineItems, setTimelineItems] = useState<StatusUpdateTimeline[]>([]);
  const [statusUpdatesCount, setStatusUpdatesCount] = useState<number>(0);

  async function loadStatusUpdates(serviceId: string | undefined) {
    try {
      const updates: StatusServiceAffectedResponse[] = await externalApi
        .get(`${endpoints.getStatusServicesAffectedListByService.url}/${serviceId}/false`) //Allow inactives with the false param
        .json();

      setStatusUpdates(updates);

      const allTimelines: StatusUpdateTimeline[] = await externalApi
        .get(`${endpoints.getStatusUpdateTimelineList.url}`)
        .json();

      const timelineRows = updates.map((upd) => {
        const row = allTimelines.filter(
          (atl) => atl.statusUpdateId === upd.statusUpdateId
        );

        return row;
      });

      const tlFiltered = timelineRows.filter((tlr) => tlr !== undefined);

      //First status update timeline
      const firstSUTL = tlFiltered[0];

      if (firstSUTL) {
        setTimelineItems(firstSUTL);

        setStatusUpdatesCount(firstSUTL.length);
      }
    } catch (e) {
      setStatusUpdatesCount(0);
    }
  }

  useEffect(() => {
    //setSelectedSite(siteId);
    loadStatusUpdates(serviceId);
  }, [serviceId]);

  return (
    <>
      <Stack>
        {/* <Select
          value={selectedSite}
          onChange={(e) => {
            changeSiteSelection(e);
          }}
          data={siteList}
          style={{ width: "33%" }}
        /> */}
        {statusUpdatesCount > 0 && (
          <Timeline mt="md" ml="sm">
            {timelineItems[0]?.resolved !== false && (
              <Timeline.Item
                title={timelineItems[0]?.header}
                style={{ color: "green" }}
                bullet={
                  <ThemeIcon radius="lg" variant="default">
                    <CircleCheck color="green" />
                  </ThemeIcon>
                }
              >
                <Text
                  style={{
                    wordWrap: "break-word",
                    hyphens: "manual",
                  }}
                >
                  {timelineItems[0]?.shortDesc}
                </Text>
                <Text fz="sm" c="dimmed">
                  {"Resolved on " +
                    new Date(
                      timelineItems[0]?.statusUpdate!.startDateTime
                    ).toLocaleDateString() +
                    " at " +
                    new Date(
                      timelineItems[0]?.statusUpdate!.startDateTime
                    ).toLocaleTimeString()}
                </Text>
              </Timeline.Item>
            )}
            {timelineItems[0]?.resolved === false && (
              <Timeline.Item
                title={timelineItems[0]?.header}
                bullet={
                  <ThemeIcon radius="lg" variant="default">
                    {
                      statusIcons.find((e) => {
                        return (
                          e.statusId ===
                          statusUpdates.filter(
                            (su) =>
                              su.statusUpdateId ===
                              timelineItems[0]?.statusUpdateId
                          )[0]?.statusId
                        );
                      })?.icon
                    }
                  </ThemeIcon>
                }
              >
                <Text
                  style={{
                    wordWrap: "break-word",
                    hyphens: "manual",
                  }}
                >
                  {timelineItems[0]?.shortDesc}
                </Text>
                <Text fz="sm" c="dimmed">
                  {"Created on " +
                    new Date(
                      timelineItems[0]?.statusUpdate!.startDateTime
                    ).toLocaleDateString() +
                    " at " +
                    new Date(
                      timelineItems[0]?.statusUpdate!.startDateTime
                    ).toLocaleTimeString()}
                </Text>
                <Text fz="xs" c="dimmed">
                  {"Last Updated on " +
                    new Date(
                      timelineItems[0]?.updateDateTime
                    ).toLocaleDateString() +
                    " at " +
                    new Date(
                      timelineItems[0]?.updateDateTime
                    ).toLocaleTimeString()}
                </Text>
              </Timeline.Item>
            )}
            {statusUpdatesCount > 1 && timelineItems[1]?.resolved !== false && (
              <Timeline.Item
                title={timelineItems[1]?.header}
                style={{ color: "green" }}
                bullet={
                  <ThemeIcon radius="lg" variant="default">
                    <CircleCheck color="green" />
                  </ThemeIcon>
                }
              >
                <Text
                  style={{
                    wordWrap: "break-word",
                    hyphens: "manual",
                  }}
                >
                  {timelineItems[1]?.shortDesc}
                </Text>
                <Text fz="sm" c="dimmed">
                  {"Resolved on " +
                    new Date(
                      timelineItems[1]?.statusUpdate!.startDateTime
                    ).toLocaleDateString() +
                    " at " +
                    new Date(
                      timelineItems[1]?.statusUpdate!.startDateTime
                    ).toLocaleTimeString()}
                </Text>
              </Timeline.Item>
            )}
            {statusUpdatesCount > 1 && timelineItems[1]?.resolved === false && (
              <Timeline.Item
                title={timelineItems[1]?.header}
                bullet={
                  <ThemeIcon radius="lg" variant="default">
                    {
                      statusIcons.find((e) => {
                        return (
                          e.statusId ===
                          statusUpdates.filter(
                            (su) =>
                              su.statusUpdateId ===
                              timelineItems[1]?.statusUpdateId
                          )[0]?.statusId
                        );
                      })?.icon
                    }
                  </ThemeIcon>
                }
              >
                <Text
                  style={{
                    wordWrap: "break-word",
                    hyphens: "manual",
                  }}
                >
                  {timelineItems[1]?.shortDesc}
                </Text>
                <Text fz="sm" c="dimmed">
                  {"Created on " +
                    new Date(
                      timelineItems[1]?.statusUpdate!.startDateTime
                    ).toLocaleDateString() +
                    " at " +
                    new Date(
                      timelineItems[1]?.statusUpdate!.startDateTime
                    ).toLocaleTimeString()}
                </Text>
                <Text fz="xs" c="dimmed">
                  {"Last Updated on " +
                    new Date(
                      timelineItems[1]?.updateDateTime
                    ).toLocaleDateString() +
                    " at " +
                    new Date(
                      timelineItems[1]?.updateDateTime
                    ).toLocaleTimeString()}
                </Text>
              </Timeline.Item>
            )}
            {statusUpdatesCount > 2 && timelineItems[2]?.resolved !== false && (
              <Timeline.Item
                title={timelineItems[2]?.header}
                style={{ color: "green" }}
                bullet={
                  <ThemeIcon radius="lg" variant="default">
                    <CircleCheck color="green" />
                  </ThemeIcon>
                }
              >
                <Text
                  style={{
                    wordWrap: "break-word",
                    hyphens: "manual",
                  }}
                >
                  {timelineItems[2]?.shortDesc}
                </Text>
                <Text fz="sm" c="dimmed">
                  {"Resolved on " +
                    new Date(
                      timelineItems[2]?.statusUpdate!.startDateTime
                    ).toLocaleDateString() +
                    " at " +
                    new Date(
                      timelineItems[2]?.statusUpdate!.startDateTime
                    ).toLocaleTimeString()}
                </Text>
              </Timeline.Item>
            )}
            {statusUpdatesCount > 2 && timelineItems[2]?.resolved === false && (
              <Timeline.Item
                title={timelineItems[2]?.header}
                bullet={
                  <ThemeIcon radius="lg" variant="default">
                    {
                      statusIcons.find((e) => {
                        return (
                          e.statusId ===
                          statusUpdates.filter(
                            (su) =>
                              su.statusUpdateId ===
                              timelineItems[2]?.statusUpdateId
                          )[0]?.statusId
                        );
                      })?.icon
                    }
                  </ThemeIcon>
                }
              >
                <Text
                  style={{
                    wordWrap: "break-word",
                    hyphens: "manual",
                  }}
                >
                  {timelineItems[2]?.shortDesc}
                </Text>
                <Text fz="sm" c="dimmed">
                  {"Created on " +
                    new Date(
                      timelineItems[2]?.statusUpdate!.startDateTime
                    ).toLocaleDateString() +
                    " at " +
                    new Date(
                      timelineItems[2]?.statusUpdate!.startDateTime
                    ).toLocaleTimeString()}
                </Text>
                <Text fz="xs" c="dimmed">
                  {"Last Updated on " +
                    new Date(
                      timelineItems[2]?.updateDateTime
                    ).toLocaleDateString() +
                    " at " +
                    new Date(
                      timelineItems[2]?.updateDateTime
                    ).toLocaleTimeString()}
                </Text>
              </Timeline.Item>
            )}
            {statusUpdatesCount > 3 && timelineItems[3]?.resolved !== false && (
              <Timeline.Item
                title={timelineItems[3]?.header}
                style={{ color: "green" }}
                bullet={
                  <ThemeIcon radius="lg" variant="default">
                    <CircleCheck color="green" />
                  </ThemeIcon>
                }
              >
                <Text
                  style={{
                    wordWrap: "break-word",
                    hyphens: "manual",
                  }}
                >
                  {timelineItems[3]?.shortDesc}
                </Text>
                <Text fz="sm" c="dimmed">
                  {"Resolved on " +
                    new Date(
                      timelineItems[3]?.statusUpdate!.startDateTime
                    ).toLocaleDateString() +
                    " at " +
                    new Date(
                      timelineItems[3]?.statusUpdate!.startDateTime
                    ).toLocaleTimeString()}
                </Text>
              </Timeline.Item>
            )}
            {statusUpdatesCount > 3 && timelineItems[3]?.resolved === false && (
              <Timeline.Item
                title={timelineItems[3]?.header}
                bullet={
                  <ThemeIcon radius="lg" variant="default">
                    {
                      statusIcons.find((e) => {
                        return (
                          e.statusId ===
                          statusUpdates.filter(
                            (su) =>
                              su.statusUpdateId ===
                              timelineItems[3]?.statusUpdateId
                          )[0]?.statusId
                        );
                      })?.icon
                    }
                  </ThemeIcon>
                }
              >
                <Text
                  style={{
                    wordWrap: "break-word",
                    hyphens: "manual",
                  }}
                >
                  {timelineItems[3]?.shortDesc}
                </Text>
                <Text fz="sm" c="dimmed">
                  {"Created on " +
                    new Date(
                      timelineItems[3]?.statusUpdate!.startDateTime
                    ).toLocaleDateString() +
                    " at " +
                    new Date(
                      timelineItems[3]?.statusUpdate!.startDateTime
                    ).toLocaleTimeString()}
                </Text>
                <Text fz="xs" c="dimmed">
                  {"Last Updated on " +
                    new Date(
                      timelineItems[3]?.updateDateTime
                    ).toLocaleDateString() +
                    " at " +
                    new Date(
                      timelineItems[3]?.updateDateTime
                    ).toLocaleTimeString()}
                </Text>
              </Timeline.Item>
            )}
            {statusUpdatesCount > 4 && timelineItems[4]?.resolved !== false && (
              <Timeline.Item
                title={timelineItems[4]?.header}
                style={{ color: "green" }}
                bullet={
                  <ThemeIcon radius="lg" variant="default">
                    <CircleCheck color="green" />
                  </ThemeIcon>
                }
              >
                <Text
                  style={{
                    wordWrap: "break-word",
                    hyphens: "manual",
                  }}
                >
                  {timelineItems[4]?.shortDesc}
                </Text>
                <Text fz="sm" c="dimmed">
                  {"Resolved on " +
                    new Date(
                      timelineItems[4]?.statusUpdate!.startDateTime
                    ).toLocaleDateString() +
                    " at " +
                    new Date(
                      timelineItems[4]?.statusUpdate!.startDateTime
                    ).toLocaleTimeString()}
                </Text>
              </Timeline.Item>
            )}
            {statusUpdatesCount > 4 && timelineItems[4]?.resolved === false && (
              <Timeline.Item
                title={timelineItems[4]?.header}
                bullet={
                  <ThemeIcon radius="lg" variant="default">
                    {
                      statusIcons.find((e) => {
                        return (
                          e.statusId ===
                          statusUpdates.filter(
                            (su) =>
                              su.statusUpdateId ===
                              timelineItems[4]?.statusUpdateId
                          )[0]?.statusId
                        );
                      })?.icon
                    }
                  </ThemeIcon>
                }
              >
                <Text
                  style={{
                    wordWrap: "break-word",
                    hyphens: "manual",
                  }}
                >
                  {timelineItems[4]?.shortDesc}
                </Text>
                <Text fz="sm" c="dimmed">
                  {"Created on " +
                    new Date(
                      timelineItems[4]?.statusUpdate!.startDateTime
                    ).toLocaleDateString() +
                    " at " +
                    new Date(
                      timelineItems[4]?.statusUpdate!.startDateTime
                    ).toLocaleTimeString()}
                </Text>
                <Text fz="xs" c="dimmed">
                  {"Last Updated on " +
                    new Date(
                      timelineItems[4]?.statusUpdate!.startDateTime
                    ).toLocaleDateString() +
                    " at " +
                    new Date(
                      timelineItems[4]?.statusUpdate!.startDateTime
                    ).toLocaleTimeString()}
                </Text>
              </Timeline.Item>
            )}
          </Timeline>
        )}
        {statusUpdatesCount === 0 && <Text>No update history available.</Text>}
      </Stack>
    </>
  );
};
