import { Dispatch, SetStateAction, useEffect, useState } from "react";

import {
  Button,
  Group,
  MultiSelect,
  Text,
  TextInput,
  Textarea,
  Checkbox,
  Title,
  Center,
  Stack,
  Paper,
  Box,
  Select,
} from "@mantine/core";

import { DatePicker } from "@mantine/dates";

import { showNotification } from "@mantine/notifications";

import { useForm } from "@mantine/form";

import api from "../../api";
import endpoints from "../../api/endpoints";

import { Service, CheckOption, SelectData, UpdateServiceResponse, Site } from "../../types";

type Props = {
  opened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
  selectedService: Service | undefined;
  newService: boolean;
};

type serviceModalFormData = {
    title :string;
    value? :string;
    leftBlank: boolean;
};

export const EditServiceModal = ({
  opened,
  setOpened,
  selectedService,
  newService,
}: Props) => {
  const [servicesDropdownData, setServicesDropdownData] = useState<SelectData[]>([]);
  const [siteList, setSiteList] = useState<CheckOption[]>([]);
  const [selectedSites, setSelectedSites] = useState<string[]>([]);
  const [newServiceCreateClick, setNewServiceCreateClick] = useState<boolean>(false);
  const [formData, setFormData] = useState<serviceModalFormData[]>([]);

  const systemClassifications = [
    "Class 1",
    "Class 2",
    "Class 3",
    "Class 4",
    "N/A - Server/Infrastructure",
  ];
  const categories = [
    "Administrative",
    "Clinical",
    "Communications",
    "Infrastructure",
    "Infrastructure/Server",
    "Lab",
    "Medical Affairs",
    "Diagnostic Imaging",
    "Pharmacy",
    "Non-Clinical",
    "Unknown",
  ];
  const systemTypes = [
    "API",
    "End User System",
    "External Services",
    "Interface - SSIS/ETL",
    "Patient Facing",
    "Servers",
    "Unknown",
  ];

  const form = useForm<Service>({
    initialValues: {
      active: true,
      connectedResources: "",
      launchDate: new Date(),
      serviceDescription: "",
      serviceId: 0,
      serviceName: "",
      stakeholders: "",
      category: "",
      systemClassification: "",
      hasPHI: false,
      hasExternalAccess: false,
      userGroup: "",
      businessOwners: "",
      sites: "",
      systemVendor: "",
      itContacts: "",
      productionServers: "",
      testServers: "",
      dependentInfrastructure: "",
      downtimeProcedure: "",
      scheduledMaintenanceWindow: "",
      systemType: "",
    },
    validate: (values) => ({
      serviceName:
        values.serviceName.length === 0
          ? "Service Name is Required"
          : values.serviceName.length < 3
          ? "Service Name must be 3 or more characters long"
          : null,
      serviceDescription:
        values.serviceDescription === undefined || values.serviceDescription.length === 0
          ? "Service Description is Required"
          : null,
    }),
  });

  useEffect(() => {
    loadServices();
    loadSites();

    if (!newService) {
      let dbSites: string[];
      let sitesArray: string[];

      if (selectedService!.sites) {
        if (selectedService!.sites.includes("[")) {
          dbSites = selectedService!.sites.split(",");
          sitesArray = dbSites.map((site) => site.replace(/[^a-zA-Z ]/g, ""));

          setSelectedSites(sitesArray);
        } else {
          form.setFieldValue("sites", selectedService!.sites);
        }
      }

      form.setValues({
        active: selectedService!.active,
        connectedResources: selectedService!.connectedResources
          ? selectedService!.connectedResources
          : "",
        launchDate: selectedService!.launchDate,
        serviceDescription: selectedService!.serviceDescription
          ? selectedService!.serviceDescription
          : "",
        serviceId: selectedService!.serviceId,
        serviceName: selectedService!.serviceName,
        stakeholders: selectedService!.stakeholders
          ? selectedService!.stakeholders
          : "",
        category: selectedService!.category ? selectedService!.category : "",
        systemClassification: selectedService!.systemClassification
          ? selectedService!.systemClassification
          : "",
        hasPHI: selectedService!.hasPHI ? selectedService!.hasPHI : false,
        hasExternalAccess: selectedService!.hasExternalAccess
          ? selectedService!.hasExternalAccess
          : false,
        userGroup: selectedService!.userGroup ? selectedService!.userGroup : "",
        businessOwners: selectedService!.businessOwners
          ? selectedService!.businessOwners
          : "",
        systemVendor: selectedService!.systemVendor
          ? selectedService!.systemVendor
          : "",
        itContacts: selectedService!.itContacts
          ? selectedService!.itContacts
          : "",
        productionServers: selectedService!.productionServers
          ? selectedService!.productionServers
          : "",
        testServers: selectedService!.testServers
          ? selectedService!.testServers
          : "",
        dependentInfrastructure: selectedService!.dependentInfrastructure
          ? selectedService!.dependentInfrastructure
          : "",
        downtimeProcedure: selectedService!.downtimeProcedure
          ? selectedService!.downtimeProcedure
          : "",
        scheduledMaintenanceWindow: selectedService!.scheduledMaintenanceWindow
          ? selectedService!.scheduledMaintenanceWindow
          : "",
        systemType: selectedService!.systemType
          ? selectedService!.systemType
          : "",
      });
    }
  }, []);

  async function saveChanges() {
    const requestValues = {
      serviceName: form.values["serviceName"],
      serviceDescription: form.values["serviceDescription"],
      category: form.values["category"],
      systemType: form.values["systemType"],
      systemClassification: form.values["systemClassification"],
      hasPHI: form.values["hasPHI"],
      hasExternalAccess: form.values["hasExternalAccess"],
      userGroup: form.values["userGroup"],
      businessOwners: form.values["businessOwners"],
      sites: selectedSites ? selectedSites.toString() : form.values["sites"],
      systemVendor: form.values["systemVendor"],
      itContacts: form.values["itContacts"],
      productionServers: form.values["productionServers"],
      testServers: form.values["testServers"],
      dependentInfrastructure: form.values["dependentInfrastructure"],
      downtimeProcedure: form.values["downtimeProcedure"],
      scheduledMaintenanceWindow: form.values["scheduledMaintenanceWindow"],
      stakeHolders: form.values["stakeholders"],
      launchDate: form.values["launchDate"],
      connectedResources: form.values["connectedResources"].toString(),
      active: true,
    };
    console.log(requestValues);
    console.log(selectedService);

    let response: UpdateServiceResponse;

    if (newService) {
      response = await api
        .put(endpoints.useService.url, {
          json: {
            ...requestValues,
          },
          timeout: 30000,
        })
        .json();
    } else {
      response = await api
        .post(`${endpoints.useService.url}/${selectedService?.serviceId}`, {
          json: {
            ...requestValues,
          }, 
          timeout: 30000,
        })
        .json();
    }

    if (newService) {
      if (response.success === true) {
        showNotification({
          title: "Success!",
          color: "green",
          message: "Your changes have been successfully saved.",
          styles: (theme) => ({
            root: {
              backgroundColor: theme.colors.green[6],
              borderColor: theme.colors.green[6],

              "&::before": { backgroundColor: theme.white },
            },

            title: { color: theme.white },
            description: { color: theme.white },
            closeButton: {
              color: theme.white,
              "&:hover": { backgroundColor: theme.colors.green[7] },
            },
          }),
        });

        setOpened(false);
      } else {
        showNotification({
          title: "Error",
          color: "red",
          message: "Error saving changes.",
          styles: (theme) => ({
            root: {
              backgroundColor: theme.colors.red[6],
              borderColor: theme.colors.red[6],

              "&::before": { backgroundColor: theme.white },
            },

            title: { color: theme.white },
            description: { color: theme.white },
            closeButton: {
              color: theme.white,
              "&:hover": { backgroundColor: theme.colors.red[7] },
            },
          }),
        });
      }
    } else {
      if (response.success === true && response.valueChanged === true) {
        showNotification({
          title: "Success!",
          color: "green",
          message: "Your changes have been successfully saved.",
          styles: (theme) => ({
            root: {
              backgroundColor: theme.colors.green[6],
              borderColor: theme.colors.green[6],

              "&::before": { backgroundColor: theme.white },
            },

            title: { color: theme.white },
            description: { color: theme.white },
            closeButton: {
              color: theme.white,
              "&:hover": { backgroundColor: theme.colors.green[7] },
            },
          }),
        });

        setOpened(false);
      } else if (response.success === true && response.valueChanged === false) {
        showNotification({
          title: "No Changes",
          color: "yellow",
          message:
            "We didn't detect any values to change, no changes were saved.",
          styles: (theme) => ({
            root: {
              backgroundColor: theme.colors.yellow[6],
              borderColor: theme.colors.yellow[6],

              "&::before": { backgroundColor: theme.white },
            },

            title: { color: theme.white },
            description: { color: theme.white },
            closeButton: {
              color: theme.white,
              "&:hover": { backgroundColor: theme.colors.yellow[7] },
            },
          }),
        });

        setOpened(false);
      } else {
        showNotification({
          title: "Error",
          color: "red",
          message: "Error saving changes.",
          styles: (theme) => ({
            root: {
              backgroundColor: theme.colors.red[6],
              borderColor: theme.colors.red[6],

              "&::before": { backgroundColor: theme.white },
            },

            title: { color: theme.white },
            description: { color: theme.white },
            closeButton: {
              color: theme.white,
              "&:hover": { backgroundColor: theme.colors.red[7] },
            },
          }),
        });
      }
    }
  }

  async function loadServices() {
    const services: Service[] = await api.get(endpoints.getServiceList.url).json();

    const mappedServices: SelectData[] = services.map((svc) => {
      return {
        label: svc.serviceName,
        value: svc.serviceId.toString(),
        key: svc.serviceId,
      };
    });

    setServicesDropdownData(mappedServices);
  }

  async function loadSites() {
    const siteList: Site[] = await api.get(endpoints.getSiteList.url).json();

    const sites: CheckOption[] = siteList.map((sl) => {
      return {
        label: sl.siteName,
        value: sl.siteName,
        key: sl.siteId.toString(),
      };
    });

    setSiteList(sites);
  }

  function navToConfirm() {
    form.validate();

    if (!form.validate().hasErrors) {
      setNewServiceCreateClick(true);

      const newServiceDetails: serviceModalFormData[] = [
        {
          title: "Service Name",
          value: form.values["serviceName"],
          leftBlank: form.values["serviceName"] ? false : true,
        },
        {
          title: "Service Description",
          value: form.values["serviceDescription"],
          leftBlank: form.values["serviceDescription"] ? false : true,
        },
        {
          title: "Active",
          value: form.values["active"] === true ? "Yes" : "No",
          leftBlank: false,
        },
        {
          title: "Category",
          value: form.values["category"],
          leftBlank: form.values["category"] ? false : true,
        },
        {
          title: "System Type",
          value: form.values["systemType"],
          leftBlank: form.values["systemType"] ? false : true,
        },
        {
          title: "System Classification",
          value: form.values["systemClassification"],
          leftBlank: form.values["systemClassification"] ? false : true,
        },
        {
          title: "Launch Date",
          value: form.values["launchDate"].toDateString(),
          leftBlank: form.values["launchDate"] ? false : true,
        },
        {
          title: "Connected Resources",
          value: form.values["connectedResources"].toString(),
          leftBlank: form.values["connectedResources"] ? false : true,
        },
        {
          title: "System Has PHI?",
          value: form.values["hasPHI"] === true ? "Yes" : "No",
          leftBlank: false,
        },
        {
          title: "System Has External Access?",
          value: form.values["hasExternalAccess"] === true ? "Yes" : "No",
          leftBlank: false,
        },
        {
          title: "User Groups",
          value: form.values["userGroup"],
          leftBlank: form.values["userGroup"] ? false : true,
        },
        {
          title: "Business Owners",
          value: form.values["businessOwners"],
          leftBlank: form.values["businessOwners"] ? false : true,
        },
        {
          title: "Sites",
          value: form.values["sites"],
          leftBlank: form.values["sites"] ? false : true,
        },
        {
          title: "System Vendor",
          value: form.values["systemVendor"],
          leftBlank: form.values["systemVendor"] ? false : true,
        },
        {
          title: "IT Contacts",
          value: form.values["itContacts"],
          leftBlank: form.values["itContacts"] ? false : true,
        },
        {
          title: "Production Servers",
          value: form.values["productionServers"],
          leftBlank: form.values["productionServers"] ? false : true,
        },
        {
          title: "Test Servers",
          value: form.values["testServers"],
          leftBlank: form.values["testServers"] ? false : true,
        },
        {
          title: "Dependent Infrastructure",
          value: form.values["dependentInfrastructure"],
          leftBlank: form.values["dependentInfrastructure"] ? false : true,
        },
        {
          title: "Downtime Procedure",
          value: form.values["downtimeProcedure"],
          leftBlank: form.values["downtimeProcedure"] ? false : true,
        },
        {
          title: "Scheduled Maintenance Window",
          value: form.values["scheduledMaintenanceWindow"],
          leftBlank: form.values["scheduledMaintenanceWindow"] ? false : true,
        },
        {
          title: "System Stakeholders",
          value: form.values["stakeholders"],
          leftBlank: form.values["stakeholders"] ? false : true,
        },
      ];

      setFormData(newServiceDetails);
    }
  }

  function newServiceField(title: string, value: string, leftBlank: boolean) {
    if (!leftBlank) {
      return (
        <div key={title}>
          <Text fz="xs" fw={700} mb={-3}>
            {title}
          </Text>
          <Text fz="sm" ml={3}>
            {value}
          </Text>
        </div>
      );
    }
  }

  return (
    <>
      {!newServiceCreateClick && (
        <>
          <form onSubmit={form.onSubmit(() => saveChanges())}>
            <TextInput
              label="Service Name"
              {...form.getInputProps("serviceName")}
              value={form.values["serviceName"]}
              withAsterisk
              mt="xs"
            />
            <Textarea
              label="Service Description"
              {...form.getInputProps("serviceDescription")}
              value={form.values["serviceDescription"]}
              withAsterisk
            />

            <Select
              data={systemTypes}
              mt="xs"
              label="System Type"
              {...form.getInputProps("systemType")}
            />
            <Select
              data={categories}
              mt="xs"
              label="System Category"
              {...form.getInputProps("category")}
            />
            <Select
              data={systemClassifications}
              mt="xs"
              label="System Classification"
              {...form.getInputProps("systemClassification")}
            />

            <Checkbox
              label="System Active?"
              mt="xs"
              {...form.getInputProps("active", { type: "checkbox" })}
              checked={form.values["active"]}
            />

            <DatePicker
              label="Launch Date"
              {...form.getInputProps("launchDate")}
              value={form.values["launchDate"]}
            />

            <MultiSelect
              mt="xs"
              label="Connected Resources"
              data={servicesDropdownData}
              searchable
              clearable
              nothingFound="No service found"
              {...form.getInputProps("connectedResources")}
            />

            <Group spacing="sm" mt="sm">
              <Checkbox
                label="Has PHI?"
                {...form.getInputProps("hasPHI", { type: "checkbox" })}
                checked={form.values["hasPHI"]}
              />
              <Checkbox
                label="Has External Access?"
                {...form.getInputProps("hasExternalAccess", {
                  type: "checkbox",
                })}
                checked={form.values["hasExternalAccess"]}
              />
            </Group>

            <TextInput
              mt="xs"
              label="Stakeholders"
              {...form.getInputProps("stakeholders")}
              value={form.values["stakeholders"]}
            />
            <TextInput
              mt="xs"
              label="User Groups"
              {...form.getInputProps("userGroup")}
              value={form.values["userGroup"]}
            />
            <TextInput
              mt="xs"
              label="Business Owners"
              {...form.getInputProps("businessOwners")}
              value={form.values["businessOwners"]}
            />
            {selectedSites.length > 0 && (
              <MultiSelect
                mt="xs"
                label="Sites"
                data={siteList}
                value={selectedSites}
                onChange={setSelectedSites}
              />
            )}
            {selectedSites.length < 1 && !selectedService && (
              <MultiSelect
                mt="xs"
                label="Sites"
                data={siteList}
                value={selectedSites}
                onChange={setSelectedSites}
              />
            )}
            {selectedSites.length < 1 && selectedService && (
              <TextInput
                mt="xs"
                label="Sites"
                {...form.getInputProps("sites")}
                value={form.values["sites"]}
              />
            )}

            <TextInput
              mt="xs"
              label="System Vendor"
              {...form.getInputProps("systemVendor")}
              value={form.values["systemVendor"]}
            />
            <TextInput
              mt="xs"
              label="IT Contacts"
              {...form.getInputProps("itContacts")}
              value={form.values["itContacts"]}
            />
            <TextInput
              mt="xs"
              label="Production Servers"
              {...form.getInputProps("productionServers")}
              value={form.values["productionServers"]}
            />
            <TextInput
              mt="xs"
              label="Test Servers"
              {...form.getInputProps("testServers")}
              value={form.values["testServers"]}
            />
            <TextInput
              mt="xs"
              label="Dependent Infrastructure"
              {...form.getInputProps("dependentInfrastructure")}
              value={form.values["dependentInfrastructure"]}
            />
            <Textarea
              mt="xs"
              label="Downtime Procedure"
              {...form.getInputProps("downtimeProcedure")}
              value={form.values["downtimeProcedure"]}
            />
            <Textarea
              mt="xs"
              label="Schedule Maintenance Window"
              {...form.getInputProps("scheduledMaintenanceWindow")}
              value={form.values["scheduledMaintenanceWindow"]}
            />

            <Center mt="xs">
              <Group>
                <Button
                  variant="outline"
                  color="gray"
                  onClick={() => form.reset()}
                >
                  Discard Changes
                </Button>
                {!newService && <Button type="submit">Save Changes</Button>}
                {newService && (
                  <Button onClick={() => navToConfirm()}>Create Service</Button>
                )}
              </Group>
            </Center>
          </form>

          {/* <Code block>{form.values["connectedResources"].toString()}</Code>
          <Code block>{JSON.stringify(form.values, null, 2)}</Code> */}
          {/* <Code block>{JSON.stringify(form.errors, null, 2)}</Code> */}
        </>
      )}

      {newServiceCreateClick && (
        <>
          <Center>
            <Stack align="center" justify="flex-start">
              <Title order={2} ta="center">
                Please confirm you would like to create a service with the below
                details.
              </Title>

              <Text fz="lg" color="dimmed" ta="center">
                Once you have confirmed your changes, click the Confirm button
                below. If you would like to make any changes, please click
                Cancel.
              </Text>
            </Stack>
          </Center>

          <Center>
            <Paper withBorder shadow="xs" p="sm" mt="sm">
              {formData.map((fd) =>
                newServiceField(fd.title, fd.value ?? "", fd.leftBlank)
              )}

              {formData.filter((fd) => fd.leftBlank === true).length > 0 && (
                <Stack spacing="xs" mt="lg">
                  <Text fz="xs" fw={700}>
                    The following fields were left blank. While these fields are
                    not mandatory, please ensure you intended to leave these
                    fields blank prior to pressing Confirm.
                  </Text>
                  <Box
                    sx={(theme) => ({
                      boxShadow: "inset 0 0 2px",
                      backgroundColor: theme.colors.gray[3],
                      padding: theme.spacing.sm,
                    })}
                  >
                    <Group spacing={2}>
                      {formData
                        .filter((fd) => fd.leftBlank === true)
                        .map((fd, index) => {
                          return (
                            <Text fz="xs" key={fd.title}>
                              {fd.title}
                              {index ===
                              formData.filter((fd) => fd.leftBlank === true)
                                .length -
                                1
                                ? ""
                                : ", "}
                            </Text>
                          );
                        })}
                    </Group>
                  </Box>
                </Stack>
              )}
            </Paper>
          </Center>
          <Center mt="sm">
            <Group spacing="lg" mt="sm">
              <Button
                color="red"
                onClick={() => setNewServiceCreateClick(false)}
              >
                Cancel
              </Button>
              <Button onClick={() => saveChanges()}>Confirm</Button>
            </Group>
          </Center>
        </>
      )}
    </>
  );
};
