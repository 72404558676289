import { useState, useEffect } from "react";
import { Stack, MediaQuery, createStyles, Loader } from "@mantine/core";

import { Calendar } from "@mantine/dates";

import { CalendarIndicator } from "./";

import externalApi from "../api";
import endpoints from "../api/endpoints";
import { StatusUpdateResponse } from "types";

type ServiceHistoryProps = {
  serviceId: number | undefined;
  siteId: string | undefined | null;
};

const useStyles = createStyles((theme) => ({
  outside: {
    color: `${theme.colors.dark[0]} !important`,
  },

  weekend: {
    color: `${theme.colors.dark[9]} !important`,
  },

  calendarSize: {
    width: "100%",

    [theme.fn.smallerThan(1200)]: {
      width: "60%",
    },
  },
}));

export const ServiceHistoryCalendar = ({
  serviceId,
  siteId,
}: ServiceHistoryProps) => {
  //const [selectedSites, setSelectedSites] = useState<string | null | undefined>(siteId);
  //const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const isDataLoaded = false;
  const [statusUpdates, setStatusUpdates] = useState<StatusUpdateResponse[]>([]);
  //const [ssas, setSSAs] = useState<any[]>([]);
  //const [filteredSUs, setFilteredSUs] = useState<any[] | undefined>([]);
  const [month, onMonthChange] = useState(new Date());
  const { classes, cx } = useStyles();

  const hide = { display: "none" };

  //   function getIndicator(calendarDate: Date) {
  //     return (
  //       <CalendarIndicator date={calendarDate} serviceId={serviceId} sites={selectedSites}  />
  //     );
  //   }

  async function loadData() {
    const statusUpdates: StatusUpdateResponse[] = await externalApi
      .get(`${endpoints.getStatusUpdateListByService.url}/${serviceId}/true`)
      .json();

    Promise.all(statusUpdates).then((values) => setStatusUpdates(values));

    // const statusSitesAffected: any[] = await externalApi
    //   .get(`${endpoints.getStatusSitesAffectedListByService.url}/${serviceId}`)
    //   .json();

    // Promise.all(statusSitesAffected)
    //   .then((values) => setSSAs(values))
    //   .then(() => {
    //     setIsDataLoaded(true);
    //   });
  }

  useEffect(() => {
    if (!isDataLoaded) {
      loadData();
    }

    //setSelectedSites(siteId);
  }, [siteId]);

  return (
    <>
      <Stack>
        {/* <Select
          data={siteList}
          value={selectedSites}
          onChange={setSelectedSites}
        /> */}
        {/* <Checkbox.Group value={selectedSites} onChange={setSelectedSites}>
          {siteList?.map((site: any) => (
            <Checkbox key={site.key} value={site.value} label={site.label} />
          ))}
          
        </Checkbox.Group> */}
        {isDataLoaded && (
          <MediaQuery smallerThan={670} styles={hide}>
            <Calendar
              size="lg"
              fullWidth
              month={month}
              onMonthChange={onMonthChange}
              renderDay={(date) => {
                return (
                  <CalendarIndicator
                    date={date}
                    month={month}
                    serviceId={serviceId}
                    sites={siteId}
                    statusUpdates={statusUpdates}
                    ssas={/*ssas*/ undefined}
                  />
                );
              }}
              dayClassName={(date, modifiers) =>
                cx({
                  [classes.weekend]: modifiers.weekend,
                  [classes.outside]: modifiers.outside,
                })
              }
              styles={(theme) => ({
                calendarBase: {
                  width: "100%",

                  [theme.fn.smallerThan(1200)]: {
                    width: "80%",
                    minWidth: 440,
                    alignSelf: "center",
                  },
                },
                cell: {
                  border: `1px solid ${
                    theme.colorScheme === "dark"
                      ? theme.colors.dark[4]
                      : theme.colors.gray[2]
                  }`,
                },
                day: {
                  borderRadius: 0,
                  height: 70,
                  fontSize: theme.fontSizes.lg,
                },
                weekday: { fontSize: theme.fontSizes.lg },
                weekdayCell: {
                  fontSize: theme.fontSizes.xl,
                  backgroundColor:
                    theme.colorScheme === "dark"
                      ? theme.colors.dark[5]
                      : theme.colors.gray[0],
                  border: `1px solid ${
                    theme.colorScheme === "dark"
                      ? theme.colors.dark[4]
                      : theme.colors.gray[2]
                  }`,
                  height: 70,
                },
              })}
            />
          </MediaQuery>
        )}
        {!isDataLoaded && <Loader variant="dots" size="md" />}
      </Stack>
    </>
  );
};
