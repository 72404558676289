import React, { forwardRef } from "react";

import { useState, useEffect } from "react";
import {
  Stack,
  Title,
  Center,
  Box,
  Paper,
  Stepper,
  Group,
  Button,
  Select,
  TextInput,
  Textarea,
  Container,
  Checkbox,
  Text,
  Alert,
  createStyles,
  ActionIcon,
  Flex,
  SelectItem,
} from "@mantine/core";

import { useForm } from "@mantine/form";

import { useMediaQuery } from "@mantine/hooks";

import api from "../api";
import endpoints from "../api/endpoints";

import { Link } from "react-router-dom";

import {
  CircleCheck,
  AlertTriangle,
  Tool,
  CircleX,
  Trash,
  Plus,
  AlertCircle,
} from "tabler-icons-react";

import { IconChevronDown } from "@tabler/icons";

import {
  SelectData,
  PriorityLevel,
  GraphUser,
  Service,
  StatusUpdateResponse,
  StatusServiceAffectedResponse,
  AddNewServiceAlertRequest,
  AddNewServiceAlertResponse,
} from "types";
import { randomId } from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
  formHeading: {
    backgroundColor: theme.fn.variant({
      variant: "filled",
      color: theme.primaryColor,
    }).background,
    borderBottom: 0,
    borderTopLeftRadius: theme.radius.md,
    borderTopRightRadius: theme.radius.md,
    padding: theme.spacing.sm,
  },
}));

const statusList = [
  {
    image: <CircleCheck color="green" />,
    label: "Available",
    value: "2",
    statusId: 2,
    color: "green",
  },
  {
    image: <AlertTriangle color="orange" />,
    label: "Issue",
    value: "4",
    statusId: 4,
    color: "orange",
  },
  {
    image: <CircleX color="red" />,
    label: "Outage",
    value: "5",
    statusId: 5,
    color: "red",
  },
  {
    image: <Tool color="blue" />,
    label: "Maintenance",
    value: "3",
    statusId: 3,
    color: "blue",
  },
];

interface AffectedServices {
  serviceId: number;
  statusId: number;
  key: string;
}

interface FormData {
  statusId: number;
  statusUpdateTitle: string;
  statusUpdateDesc: string;
  affectedServices: AffectedServices[];
  header: string;
  shortDesc: string;
  desc: string;
  displayOnBanner: boolean;
  override: boolean;
  priorityLevel: string;
}

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  image: any;
  label: string;
  statusId: number;
}

const SelectItemDiv = forwardRef<HTMLDivElement, ItemProps>(
  ({ image, label, statusId, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        {image}

        <div>
          <Text size="sm">{label}</Text>
        </div>
      </Group>
    </div>
  )
);

interface PriorityProps extends React.ComponentPropsWithoutRef<"div"> {
  label: string;
  description: string;
}

const PriorityItem = forwardRef<HTMLDivElement, PriorityProps>(
  ({ label, description, ...others }: PriorityProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <div>
          <Text size="sm" fw={500}>
            {label}
          </Text>
          <Text size="xs" opacity={0.65}>
            {description}
          </Text>
        </div>
      </Group>
    </div>
  )
);

export const NewServiceAlert = () => {
  const [me, setMe] = useState<GraphUser>();

  const [servicesList, setServicesList] = useState<Service[]>([]);
  const [selectServices, setSelectServices] = useState<SelectData[]>([]);
  const [priorityList, setPriorityList] = useState<SelectItem[]>([]);

  const { classes } = useStyles();

  const serviceError = true;
  const [submitError, setSubmitError] = useState<string>("");
  const [loadingState, setLoadingState] = useState<boolean>(false);

  const form = useForm<FormData>({
    initialValues: {
      statusId: -1,
      statusUpdateTitle: "",
      statusUpdateDesc: "",
      affectedServices: [{ serviceId: -1, statusId: -1, key: randomId() }],
      header: "",
      shortDesc: "",
      desc: "",
      displayOnBanner: false,
      override: false,
      priorityLevel: "",
    },
    validate: (values) => {
      if (active === 0) {
        return {
          statusUpdateTitle:
            values.statusUpdateTitle.trim().length < 1
              ? "You must enter a title."
              : values.statusUpdateTitle.trim().length > 300
              ? "Title must be 300 characters or less"
              : null,
          statusUpdateDesc:
            values.statusUpdateDesc.trim().length < 1
              ? "You must include a description."
              : values.statusUpdateDesc.trim().length > 3000
              ? "Description must be 3000 characters or less"
              : null,
        };
      }
      if (active === 1) {
        return {
          header:
            values.header.trim().length < 1
              ? "You must enter a header."
              : values.header.trim().length > 100
              ? "Header must be 100 characters or less"
              : null,
          shortDesc:
            values.shortDesc.trim().length < 1
              ? "You must include a short description."
              : values.shortDesc.trim().length > 300
              ? "Short Description must be 300 characters or less"
              : null,
          priorityLevel:
            values.priorityLevel.toString().trim().length < 1
              ? "You must select a priority level."
              : null,
        };
      }
      if (active === 1) {
        // setServiceError(
        //   values.affectedServices.filter((as) => as.serviceId)
        //     .length < 1
        //     ? true
        //     : values.affectedServices.filter((as) => as.statusId)
        //         .length < 1
        //     ? true
        //     : false
        // );
        // return {
        //   affectedServices:
        //     values.affectedServices.filter((as) => as.serviceId)
        //       .length < 1
        //       ? "You must select at least one Service."
        //       : values.affectedServices.filter((as) => as.statusId)
        //           .length < 1
        //       ? "You must select a status."
        //       : null,
        // };
      }

      return {};
    },
  });

  const affectedServices: JSX.Element[] = form.values["affectedServices"]
    .filter(
      (svc: AffectedServices, index: number, array: AffectedServices[]) => {
        const svcIndex = array.findIndex((s) => s.serviceId === svc.serviceId);
        return index === svcIndex;
      }
    )
    .map((item: AffectedServices, index: number) => (
      <Group key={item.key}>
        <Select
          data={selectServices}
          searchable
          label="Service"
          nothingFound="No services found."
          {...form.getInputProps(`affectedServices.${index}.serviceId`)}
        />

        <Select
          label="Status"
          itemComponent={SelectItemDiv}
          data={statusList}
          maxDropdownHeight={400}
          nothingFound="No statuses found"
          {...form.getInputProps(`affectedServices.${index}.statusId`)}
          icon={
            item.statusId ? (
              statusList.find((ssl) => ssl.statusId === item.statusId)?.image
            ) : (
              <IconChevronDown />
            )
          }
          styles={(theme) => ({
            item: {
              // applies styles to selected item
              "&[data-selected]": {
                "&, &:hover": {
                  backgroundColor: "#fff",
                  color: theme.black,
                  border: "2px solid #087f5b",
                  borderRadius: theme.radius.md,
                },
              },

              // applies styles to hovered item (with mouse or keyboard)
              "&[data-hovered]": {},
            },
          })}
        />

        <ActionIcon
          color="red"
          variant="subtle"
          onClick={() => form.removeListItem("affectedServices", index)}
          style={{ marginTop: "25px" }}
        >
          <Trash size={20} />
        </ActionIcon>
      </Group>
    ));

  // Stepper
  const [active, setActive] = useState(0);

  const nextStep = () => {
    setActive((current) => {
      if (form.validate().hasErrors) {
        return current;
      }

      return current < 4 ? current + 1 : current;
    });
  };

  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  async function loadServices() {
    const services: Service[] = await api
      .get(`${endpoints.getServiceList.url}`)
      .json();

    setServicesList(services);

    const statusUpdates: StatusUpdateResponse[] = await api
      .get(`${endpoints.getStatusUpdateList.url}`)
      .json();

    const activeUpdates: number[] = statusUpdates
      .filter((su) => su.resolved === false)
      .map((su) => su.statusUpdateId);

    const statusServicesAffected: StatusServiceAffectedResponse[] = await api
      .get(`${endpoints.getStatusServicesAffectedList.url}`)
      .json();

    const servicesWithActiveUpdates: number[] = statusServicesAffected
      .filter((ssa) => activeUpdates.includes(ssa.statusUpdateId))
      .map((ssa) => ssa.serviceId);

    const mapService: SelectData[] = services
      .filter((s) => !servicesWithActiveUpdates.includes(s.serviceId))
      .map((s) => {
        return {
          key: s.serviceId,
          value: s.serviceId.toString(),
          label: s.serviceName,
        };
      });

    setSelectServices(mapService);
  }

  async function loadUser() {
    const user: GraphUser = await api.get(endpoints.getMe.url).json();

    setMe(user);
  }

  async function loadPriorityItems() {
    const priorities: PriorityLevel[] = await api
      .get(`${endpoints.getPriorityList.url}`)
      .json();

    const mapPriority: SelectItem[] = priorities.map((p: PriorityLevel) => {
      return {
        key: p.priorityLevelId,
        value: p.priorityLevelId.toString(),
        label: p.priorityLevelName,
        description: p.priorityLevelDescription,
      };
    });

    setPriorityList(mapPriority);
  }



  async function submitUpdate() {
    setLoadingState(true);
    //Status Update
    const createdByUserID = me!.graphUserId;
    const displayOnBanner = form.values["displayOnBanner"];
    const statusUpdateTitle = form.values["statusUpdateTitle"];
    const statusUpdateDescription = form.values["statusUpdateDesc"];

    //Status Update Timeline
    const priorityLevel = form.values["priorityLevel"];
    const header = form.values["header"];
    const shortDesc = form.values["shortDesc"];
    const desc = form.values["desc"];
    const override = form.values["override"];

    //Status Services Affected
    const affectedServices = form.values["affectedServices"];

    const newServiceAlert: AddNewServiceAlertRequest = {
        statusUpdate: {
            createdByUserId: createdByUserID,
            title: statusUpdateTitle,
            description: statusUpdateDescription,
            displayOnBanner: displayOnBanner,
            createDateTime: undefined,
            startDateTime: undefined,
            resolved: undefined,
            active: undefined
        },
        timeline: {
            priorityLevelId: parseInt(priorityLevel),
            header: header,
            shortDesc: shortDesc,
            description: desc,
            resolved: false,
            overrideAndAlertAll: override,
            updateDateTime: undefined,
            active: undefined
        },
        affectedServices: affectedServices.map((as) => {
            return {
                serviceId: as.serviceId,
                statusId: as.statusId,
                serviceDetails: undefined,
                createdDateTime: undefined,
                active: undefined
            };
        }),
        sendEmails: undefined
    };

    const response: AddNewServiceAlertResponse = await api
        .put(endpoints.addNewServiceAlert.url, {
            json: {
                ...newServiceAlert
            },
            timeout: 30000
        })
        .json();

    if (!response.success) {
        setLoadingState(false);
        setSubmitError("Error submitting service alert. - " + response.response);
    }

    nextStep();
  }

  // Media

  const mobile = useMediaQuery("(max-width: 767px)");

  useEffect(() => {
    loadUser();
    loadPriorityItems();
    loadServices();
  }, []);

  return (
    <form onSubmit={form.onSubmit(() => submitUpdate())}>
      <Stepper
        active={active}
        onStepClick={setActive}
        //breakpoint="sm"
        size="sm"
        styles={
          mobile
            ? {
                stepBody: {
                  display: "none",
                },

                step: {
                  padding: 0,
                },

                stepIcon: {
                  borderWidth: 4,
                },

                separator: {
                  marginLeft: -2,
                  marginRight: -2,
                  height: 4,
                },
              }
            : undefined
        }
        allowNextStepsSelect={false}
      >
        <Stepper.Step label="Alert Details">
          <Container fluid className={classes.formHeading}>
            <Title order={3} color="white">
              Alert Details
            </Title>
          </Container>
          <Paper p="sm" shadow="xs" withBorder>
            <TextInput
              withAsterisk
              label="Alert Title"
              description="Enter a title for the alert. This will be the overall subject of the alert and will be shown on the Alert page as well as used in the banner message if displayed. "
              placeholder="[NAME OF SERVICE OR TITLE] - [Planned/Unplanned]"
              {...form.getInputProps("statusUpdateTitle")}
            />

            <Textarea
              withAsterisk
              my="sm"
              label="Alert Description"
              description="Enter a description for the alert. This should describe the overall issue at hand and timeline updates can be more granular. This will be displayed on the Alert page as well as in the banner message if displayed.."
              {...form.getInputProps("statusUpdateDesc")}
            />
          </Paper>
        </Stepper.Step>
        <Stepper.Step label="Timeline">
          <Container fluid className={classes.formHeading}>
            <Title order={3} color="white">
              Initial Timeline Item
            </Title>
          </Container>
          <Paper p="sm" shadow="xs" withBorder>
            <TextInput
              withAsterisk
              label="Header"
              description="Enter a header message for the first timeline item. This will be shown on the dashboard and should be kept to 100 characters or less. "
              {...form.getInputProps("header")}
            />

            <Textarea
              withAsterisk
              my="sm"
              label="Short Description"
              description="Enter a header message for the first timeline item. This will be shown on the details screen. This should be kept to 200 characters or less."
              {...form.getInputProps("shortDesc")}
            />

            <Textarea
              label="Description"
              description="Enter a header message for the Service Alert. This will be shown on the details screen."
              {...form.getInputProps("desc")}
            />
            <Box style={{ width: mobile ? "75%" : "20%", minWidth: "100px" }}>
              <Select
                withAsterisk
                mt="sm"
                label="Priority Level"
                itemComponent={PriorityItem}
                data={priorityList}
                maxDropdownHeight={400}
                nothingFound="No priority options found"
                {...form.getInputProps("priorityLevel")}
                styles={(theme) => ({
                  item: {
                    // applies styles to selected item
                    "&[data-selected]": {
                      "&, &:hover": {
                        backgroundColor: "#fff",
                        color: theme.black,
                        border: "2px solid #087f5b",
                        borderRadius: theme.radius.md,
                      },
                    },

                    // applies styles to hovered item (with mouse or keyboard)
                    "&[data-hovered]": {},
                  },
                })}
              />
            </Box>
          </Paper>
          <Container fluid className={classes.formHeading} mt="xs">
            <Title order={3} color="white">
              Affected Services
            </Title>
          </Container>
          <Paper p="sm" shadow="xs" withBorder>
            <Text fz="sm" c="dimmed" mb="xs">
              Please note that a service can only be under one active alert at a
              time. If the service you are looking for does not appear in the
              list below, please check if there are any current active alerts
              impacting the service.
            </Text>
            {serviceError && (
              <Text fz="xs" color="red">
                {form.errors["affectedServices"]}
              </Text>
            )}
            {affectedServices}

            <Button
              my="sm"
              variant="light"
              color="gray"
              onClick={() =>
                form.insertListItem("affectedServices", {
                  serviceId: "",
                  statusId: "",
                  key: randomId(),
                })
              }
            >
              <Plus size={16} /> Add Affected Service
            </Button>
          </Paper>
        </Stepper.Step>
        <Stepper.Step label="Finalize Alert">
          <Container fluid className={classes.formHeading}>
            <Title order={3} color="white">
              Finalize Alert
            </Title>
          </Container>
          <Paper p="sm" shadow="xs" withBorder>
            <Stack align="center" spacing="xs">
              <Alert
                variant="outline"
                color="teal"
                title=""
                style={{ maxWidth: mobile ? "100%" : "70%" }}
              >
                Please confirm the Service Alert details below, and indicate
                whether or not to display the Alert as a banner message, and/or
                to override user notification preferences when sending out the
                alert.
              </Alert>
              <Checkbox
                size={mobile ? "md" : "lg"}
                label="Display as Banner Message"
                {...form.getInputProps("displayOnBanner")}
              />
              {form.values["displayOnBanner"] && (
                <Box>
                  <Text fz="sm">
                    This is a sample of what the banner message will look like.
                  </Text>
                  <Alert
                    mt="xs"
                    title={form.values["statusUpdateTitle"]}
                    color="red"
                    style={{ width: mobile ? "90%" : "300px" }}
                    icon={<AlertCircle size={22} />}
                    radius="sm"
                  >
                    {form.values["statusUpdateDesc"]}
                  </Alert>
                </Box>
              )}
              <Checkbox
                mt="xs"
                size={mobile ? "md" : "lg"}
                label="Override User Notification Preferences"
                {...form.getInputProps("override")}
              />
              {form.values["override"] && (
                <Alert
                  color="yellow"
                  radius="lg"
                  title="Warning"
                  style={{ width: mobile ? "95%" : "600px" }}
                  icon={<AlertCircle size={22} />}
                >
                  <Stack align="center">
                    <Text>
                      Overriding Notification preferences should only be used in
                      the event of a critical issue that would impact the
                      organization. This will alert all email users regardless
                      of their notification preferences.{" "}
                    </Text>
                    <Text fw={700}>
                      Please ensure this issue is critical enough for this type
                      of communication before hitting submit.
                    </Text>
                  </Stack>
                </Alert>
              )}

              <Paper
                p="md"
                shadow="xs"
                withBorder
                style={{ width: mobile ? "90%" : "70%" }}
              >
                <Flex direction="column" align="center" mb="sm">
                  <Text fw={700}>{form.values["statusUpdateTitle"]}</Text>
                  <Text fz="sm">{form.values["statusUpdateDesc"]}</Text>
                </Flex>

                <Text fw={500} fz="md">
                  Timeline
                </Text>
                <Paper p="xs" withBorder>
                  <Text fw={700} fz="sm">
                    {form.values["header"]}
                  </Text>
                  <Text c="dimmed" fz="sm">
                    Priority Level: {form.values["priorityLevel"]}
                  </Text>
                  <Text>{form.values["shortDesc"]}</Text>
                  <Text>{form.values["desc"]}</Text>
                </Paper>

                <Text fw={500} fz="md" mt="sm">
                  Affected Services
                </Text>
                <Paper p="xs" withBorder>
                  {form.values["affectedServices"].map(
                    (item: AffectedServices, index: number) => {
                      const serviceName = servicesList.filter(
                        (svc) => svc.serviceId === item.serviceId
                      )[0]?.serviceName;

                      const statusName = statusList.filter(
                        (st) => st.statusId === item.statusId
                      )[0]?.label;

                      const statusColour = statusList.filter(
                        (st) => st.statusId === item.statusId
                      )[0]?.color;

                      return (
                        <Group key={index}>
                          <Text fw={700} fz="sm">
                            {serviceName}
                          </Text>
                          {" - "}
                          <Text fw={500} fz="sm" c={statusColour}>
                            {statusName}
                          </Text>
                        </Group>
                      );
                    }
                  )}
                </Paper>
              </Paper>
            </Stack>
          </Paper>
        </Stepper.Step>
      </Stepper>
      {active === 3 && !submitError && (
        <Center mt="xl">
          <Stack justify="space-between">
            <Title order={3}>Service Event Submitted!</Title>

            <Button variant="light" component={Link} to="/">
              Click here to return to the Dashboard
            </Button>
          </Stack>
        </Center>
      )}

      {active === 3 && submitError && (
        <Center mt="xl">
          <Stack
            justify="space-between"
            align="center"
            style={{ width: "80%" }}
          >
            <Title order={3} c="red">
              Service Event Submission Failed
            </Title>
            <Text fw={500}>{submitError}</Text>
            <Text align="center">
              Please retry submitting the event or submitting a Service Desk
              ticket to the Innovation Lab.
            </Text>
          </Stack>
        </Center>
      )}

      <Group position="center" mt="xl">
        {active !== 0 && active !== 3 && (
          <Button variant="outline" onClick={prevStep}>
            Back
          </Button>
        )}
        {active !== 2 && active !== 3 && (
          <Button onClick={nextStep}>Next</Button>
        )}
        {active === 2 && (
          <Button onClick={submitUpdate} uppercase loading={loadingState}>
            Submit
          </Button>
        )}
      </Group>
    </form>
  );
};
