import { useMsal } from "@azure/msal-react";

import {
  Header,
  HeaderProps,
  Anchor,
  createStyles,
  Group,
  Box,
  useMantineTheme,
  Menu,
  UnstyledButton,
  Burger,
  Image,
  MediaQuery
} from "@mantine/core";

import { Link } from "react-router-dom";

import { UserProfile } from "./UserProfile";

import { Logout } from "tabler-icons-react";

import siteLogo from "../../assets/serviceHealth_TempLogo.png";

const useStyles = createStyles((theme) => ({
  header: {
    backgroundColor: theme.colors.dark[4],
    border: "none",
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.xs,
    zIndex: 10,
  },

  inner: {
    color: "#FFF",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing.sm,
  },

  menuButton: {
    [theme.fn.smallerThan("lg")]: {
      display: "none",
    },
  },

  burger: {
    [theme.fn.largerThan("lg")]: {
      display: "none",
    },
  },
}));

type HeaderSimpleProps = Omit<HeaderProps, "children"> & {
  isOpen: boolean;
  setOpen(): void;
};

export const HeaderSimple = (props: HeaderSimpleProps) => {
  const { isOpen, setOpen, ...rest } = props;

  const theme = useMantineTheme();
  const { classes } = useStyles();

  const { instance, accounts } = useMsal();
  const account = accounts[0];

  const userIsTechnician: boolean | undefined =
    account?.idTokenClaims?.roles?.includes("ServiceHealth.Technician");

  return (
    <Header {...rest} className={classes.header}>
      <Box className={classes.inner}>
        <Anchor component={Link} to={`/`}>
          <MediaQuery smallerThan={390} styles={{ display: "none" }}>
            <Image
              radius="sm"
              src={siteLogo}
              height={60}
              fit="contain"
              alt="Site Logo"
            />
          </MediaQuery>
          <MediaQuery largerThan={390} styles={{ display: "none" }}>
            <Image
              radius="sm"
              src={siteLogo}
              height={60}
              fit="contain"
              alt="Site Logo"
            />
          </MediaQuery>
        </Anchor>
        <Group spacing="md">
          <MediaQuery smallerThan={490} styles={{ display: "none" }}>
            <Menu>
              <Menu.Target>
                <UnstyledButton
                  p="xs"
                  mt={-5}
                  sx={(theme) => ({
                    "&:hover": {
                      backgroundColor: theme.colors.dark[6],
                    },
                  })}
                >
                  <Group spacing="xs">
                    <UserProfile
                      firstName={account.name!.split(", ")[1]}
                      lastName={account.name!.split(", ")[0]}
                      email={account.username}
                      tech={userIsTechnician ? userIsTechnician : false}
                    />
                  </Group>
                </UnstyledButton>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  icon={<Logout color="red" size={16} />}
                  onClick={() =>
                    instance.logoutRedirect({ postLogoutRedirectUri: "/" })
                  }
                >
                  Logout
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </MediaQuery>

          <Burger
            //className={classes.burger}
            opened={isOpen}
            onClick={() => setOpen()}
            color={theme.colors.gray[0]}
            size="sm"
          />
        </Group>
      </Box>
    </Header>
  );
};
