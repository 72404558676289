import { useState, useEffect, useMemo } from "react";

import { useMsal } from "@azure/msal-react";

import { showNotification } from "@mantine/notifications";

import {
  Stack,
  Title,
  Center,
  Box,
  Paper,
  Group,
  ActionIcon,
  Grid,
  Text,
  Anchor,
  Menu,
  Flex,
  Pagination,
  TextInput,
  createStyles,
  Tooltip,
  Loader,
  MediaQuery,
} from "@mantine/core";

import { randomId, useMediaQuery } from "@mantine/hooks";

import { Link, useNavigate } from "react-router-dom";

import api from "../api";
import { endpoints, graphApi } from "../api";

import {
  CircleCheck,
  AlertTriangle,
  Tool,
  CircleX,
  Star,
  BellRinging,
  BellOff,
  Flag,
  Eye,
  Note,
  Refresh,
  List,
} from "tabler-icons-react";

import { statusIcons } from "../common/Data";

import {
  EmailModal,
  ServiceModal,
  ExistingAlertModal,
} from "../components/modals";

import {
  AddUserServiceNotificationPreferenceResponse,
  DashboardPull,
  DashboardService,
  GraphApiUser,
  GraphUser,
  Service,
  StatusIcon,
  StatusServiceAffectedResponse,
  StatusUpdateResponse,
  StatusUpdateTimeline,
  UserNotificationPreference,
} from "types";

const useStyles = createStyles((theme) => ({
  legendSize: {
    width: "70%",
    justifyContent: "flex-end",

    [theme.fn.smallerThan("sm")]: {
      width: "300px",
      justifyContent: "flex-end",
    },
  },

  allLegendSize: {
    width: "50%",
    justifyContent: "flex-end",

    [theme.fn.smallerThan("sm")]: {
      width: "250px",
      justifyContent: "flex-end",
    },
  },
}));

const HomePage = () => {
  const { accounts } = useMsal();
  const account = accounts[0];

  const navigate = useNavigate();
  const [refreshTime, setRefreshTime] = useState<string>("");

  const userIsTechnician: boolean | undefined =
    account?.idTokenClaims?.roles?.includes("ServiceHealth.Technician");

  const [emailModal, setEmailModal] = useState<boolean>(false);
  const [serviceModal, setServiceModal] = useState<boolean>(false);
  const [existingAlertModal, setExistingAlertModal] = useState<boolean>(false);
  const [userId, setUserId] = useState<number>(0);
  const [dashboardRows, setDashboardRows] = useState<DashboardPull>();
  const [otherDashboardRows, setOtherDashboardRows] = useState<
    DashboardService[]
  >([]);
  const [userNotifications, setUserNotifications] = useState<
    UserNotificationPreference[]
  >([]);
  const [selectedService, setSelectedService] = useState<Service>();
  //const [selectedNotificationPreference, setSelectedNotificationPreference] = useState<UserNotificationPreference[]>([]);
  const [actualStatusUpdates, setActualStatusUpdates] = useState<
    StatusUpdateResponse[]
  >([]);
  const [allStatusUpdates, setAllStatusUpdates] = useState<
    StatusServiceAffectedResponse[]
  >([]);
  const [allTimelines, setAllTimelines] = useState<StatusUpdateTimeline[]>([]);

  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [areServicesLoaded, setAreServicesLoaded] = useState<boolean>(false);
  const [favouritesLoaded, setFavouritesLoaded] = useState<boolean>(false);

  const [activePage, setPage] = useState(1);
  const servicesPerPage = 10;
  const [maxPages, setMaxPages] = useState(10);

  const [search, setSearch] = useState("");

  const tablet = useMediaQuery("(max-width: 1235px) and (min-width: 631px)");
  const phone = useMediaQuery("(max-width: 630px)");

  const { classes } = useStyles();

  const refreshPage = () => {
    navigate(0);
  };

  async function loadUser() {
    //Get user data from the Graph API so we can submit it for saving in the backend.
    const graphMe: GraphApiUser = await graphApi
      .get(endpoints.graphMe.url)
      .json();

    const getMe: GraphUser = await api
      .post(endpoints.getMe.url, {
        json: {
          ...graphMe,
        },
      })
      .json();
    setUserId(getMe.graphUserId);
    loadNotificationPreferences(getMe.graphUserId);
    loadServices(getMe.graphUserId);
  }

  async function loadServices(graphUserId: number) {
    setAreServicesLoaded(false);
    setFavouritesLoaded(false);
    setSearch("");

    const dashboardPull: DashboardPull = await api
      .get(`${endpoints.getDashboard.url}/${graphUserId}`)
      .json();

    setDashboardRows(dashboardPull);

    const otherDashboardRows: DashboardService[] = dashboardPull.otherServices
      .filter((svc: DashboardService) => svc.service.active === true)
      .filter(
        (e: DashboardService, index: number, self: DashboardService[]) =>
          self.findIndex((f) => e.service.serviceId === f.service.serviceId) ===
          index
      );

    const sortedDashboardRows = otherDashboardRows.sort(
      (a: DashboardService, b: DashboardService) => {
        const statusA =
          a.statuses.length > 0 && a.statuses[0].status
            ? a.statuses[0].status.statusId
            : 2;
        const statusB =
          b.statuses.length > 0 && b.statuses[0].status
            ? b.statuses[0].status.statusId
            : 2;

        const nameA = a.service.serviceName;
        const nameB = b.service.serviceName;

        if (statusA === statusB) {
          return nameA > nameB ? 1 : -1;
        }

        return statusB - statusA;
      }
    );

    setOtherDashboardRows(sortedDashboardRows);

    setMaxPages(Math.ceil(otherDashboardRows.length / servicesPerPage));

    setFavouritesLoaded(true);
    setAreServicesLoaded(true);
  }

  async function loadNotificationPreferences(graphUserId: number) {
    const notificationList: UserNotificationPreference[] = await api
      .get(
        `${endpoints.getUserServiceNotificationPreferencesListByUser.url}/${graphUserId}`
      )
      .json();

    setUserNotifications(notificationList);
  }

  async function loadData() {
    loadUser();
    loadStatusUpdates();
    setIsDataLoaded(true);
  }

  async function loadStatusUpdates() {
    const actualStatusUpdates: StatusUpdateResponse[] = await api
      .get(endpoints.getStatusUpdateList.url)
      .json();

    setActualStatusUpdates(actualStatusUpdates);

    const allStatusUpdates: StatusServiceAffectedResponse[] = await api
      .get(endpoints.getStatusServicesAffectedList.url)
      .json();

    setAllStatusUpdates(allStatusUpdates);

    const timelines: StatusUpdateTimeline[] = await api
      .get(endpoints.getStatusUpdateTimelineList.url)
      .json();

    setAllTimelines(timelines);

    setFavouritesLoaded(true);
    setAreServicesLoaded(true);
  }

  async function changeFavourite(
    serviceId: number,
    notificationEnabled: boolean,
    isFavourite: boolean,
    isNotification: boolean,
    notificationPreference: UserNotificationPreference[] | undefined
  ) {
    if (notificationPreference !== undefined) {
      let updatedPreferences = userNotifications.map((i) => {
        if (i.serviceId === notificationPreference[0]?.serviceId) {
          return { ...i, emailEnabled: !isNotification };
        }
        return i;
      });

      setUserNotifications(updatedPreferences);
    } else {
      const newPreference: UserNotificationPreference = {
        active: true,
        graphUserId: userId,
        serviceId: serviceId,
        emailEnabled: true,
        emailAvailable: false,
        emailIncident: false,
        emailOutage: false,
        emailMaintenance: false,
        isFavourite: !isFavourite,
        phoneEnabled: false,
        phoneAvailable: false,
        phoneIncident: false,
        phoneMaintenance: false,
        phoneOutage: false,
        passiveEnabled: false,
        siteId: 1,
      };

      setUserNotifications((currentPrefs) => [...currentPrefs, newPreference]);
    }

    if (isFavourite) {
      await api
        .put(endpoints.useUserServiceNotificationPreferencesFavourites.url, {
          json: {
            graphUserId: userId,
            serviceId: serviceId,
            emailEnabled: false,
            isFavourite: !isFavourite,
          },
          timeout: 30000,
        })
        .json();
    } else {
      await api
        .put(endpoints.useUserServiceNotificationPreferencesFavourites.url, {
          json: {
            graphUserId: userId,
            serviceId: serviceId,
            emailEnabled: true,
            isFavourite: !isFavourite,
          },
          timeout: 30000,
        })
        .json();
    }

    loadServices(userId);
    loadNotificationPreferences(userId);
    // const updatedData = services.map((obj) => {
    //   if (obj.serviceId === serviceId) {
    //     return { ...obj, isFavourite: !obj.isFavourite };
    //   } else return obj;
    // });
    // setServices(updatedData);
  }

  async function changeNotification(
    serviceId: number,
    isNotification: boolean,
    notificationPreference: UserNotificationPreference[] | undefined
  ) {
    // let updatedData = allServices.map((obj) => {
    //   if (obj.id === serviceId) {
    //     return { ...obj, notificationEnabled: !obj.notificationEnabled };
    //   } else return obj;
    // });
    // setAllServices(updatedData);;

    if (notificationPreference !== undefined) {
      let updatedPreferences = userNotifications.map((i) => {
        if (i.serviceId === notificationPreference[0]?.serviceId) {
          return { ...i, emailEnabled: !isNotification };
        }
        return i;
      });

      setUserNotifications(updatedPreferences);
    } else {
      const newPreference: UserNotificationPreference = {
        active: true,
        graphUserId: userId,
        serviceId: serviceId,
        emailEnabled: true,
        emailAvailable: false,
        emailIncident: false,
        emailOutage: false,
        emailMaintenance: false,
        isFavourite: false,
        phoneEnabled: false,
        phoneAvailable: false,
        phoneIncident: false,
        phoneMaintenance: false,
        phoneOutage: false,
        passiveEnabled: false,
        siteId: 1,
      };

      setUserNotifications((currentPrefs) => [...currentPrefs, newPreference]);
    }

    const response: AddUserServiceNotificationPreferenceResponse = await api
      .put(endpoints.useUserServiceNotificationPreferencesFavourites.url, {
        json: {
          graphUserId: userId,
          serviceId: serviceId,
          emailEnabled: isNotification,
        },
        timeout: 30000,
      })
      .json();

    if (response.success) {
      loadNotificationPreferences(userId);
      showNotification({
        title: "Success!",
        message: "Updated Notification Settings",
        color: "green",
      });
    } else {
      showNotification({
        title: "Error",
        message: "Error saving changes.",
        color: "red",
      });
    }

    //loadServices(user);
    //Reload notifications before reloading services to build table
    // Promise.all([loadNotificationPreferences(user)]).then(() => {
    //   loadServices(user);
    // });
  }

  interface FieldsObject {
    status: number;
    statusUpdateHeader: string;
    statusUpdateDateTime?: Date;
    statusUpdateDescription: string;
    mostRecentIcon?: StatusIcon;
  }

  function checkUpdates(service: DashboardService) {
    let fields: FieldsObject = {
      status: 2,
      statusUpdateHeader: "Available.",
      statusUpdateDateTime: undefined,
      statusUpdateDescription: "",
      mostRecentIcon: undefined,
    };
    //let fontColour: string;

    let resolutionExists: boolean;
    let previousUnresolvedExists: boolean = false;

    let dashboardUpdate: StatusServiceAffectedResponse;

    const serviceUpdates = allStatusUpdates?.filter(
      (asu: StatusServiceAffectedResponse) =>
        asu.serviceId === service.service.serviceId
    );

    const mostRecentUpdate = serviceUpdates.sort()[0];

    if (mostRecentUpdate) {
      resolutionExists = actualStatusUpdates.filter(
        (su) => su.statusUpdateId === mostRecentUpdate.statusUpdateId
      )[0]?.resolved;

      if (resolutionExists) {
        const unresolvedUpdates = actualStatusUpdates.filter(
          (su: StatusUpdateResponse) => {
            return serviceUpdates.some(
              (serv: StatusServiceAffectedResponse) => {
                return (
                  serv.statusUpdateId === su.statusUpdateId &&
                  su.resolved === false
                );
              }
            );
          }
        );

        if (unresolvedUpdates.length > 0) {
          const mostRecentUnresolvedUpdate = unresolvedUpdates[0];

          const mostRecentUnresolved = allStatusUpdates?.filter(
            (asu: StatusServiceAffectedResponse) =>
              asu.statusUpdateId === mostRecentUnresolvedUpdate.statusUpdateId
          )[0];

          if (mostRecentUnresolved) {
            previousUnresolvedExists = true;
            dashboardUpdate = mostRecentUnresolved;
          } else {
            dashboardUpdate = mostRecentUpdate;
          }
        } else {
          dashboardUpdate = mostRecentUpdate;
        }
      } else {
        dashboardUpdate = mostRecentUpdate;
      }

      fields.status = dashboardUpdate.statusId;

      fields.mostRecentIcon = statusIcons.find((e) => {
        if (resolutionExists && !previousUnresolvedExists) {
          return e.statusId === 2;
        } else if (!resolutionExists && !previousUnresolvedExists) {
          return (
            e.statusId === (fields.status === undefined ? 2 : fields.status)
          );
        } else {
          return (
            e.statusId === (fields.status === undefined ? 2 : fields.status)
          );
        }
      });

      const mostRecentTimelineItem = allTimelines
        ?.filter(
          (tl: StatusUpdateTimeline) =>
            tl.statusUpdateId === dashboardUpdate.statusUpdateId
        )
        .sort()[0];

      if (mostRecentTimelineItem) {
        if (!resolutionExists && !previousUnresolvedExists) {
          fields.statusUpdateHeader = mostRecentTimelineItem.header;
          fields.statusUpdateDateTime = new Date(
            mostRecentTimelineItem.updateDateTime + "Z"
          );

          //   if (mostRecentTimelineItem.resolved) {
          //     fontColour = "green.5";
          //   } else {
          //     fontColour = "dark.9";
          //   }
        } else if (resolutionExists && previousUnresolvedExists) {
          fields.statusUpdateHeader = mostRecentTimelineItem.header;
          fields.statusUpdateDateTime = new Date(
            mostRecentTimelineItem.updateDateTime + "Z"
          );

          //   if (mostRecentTimelineItem.resolved) {
          //     fontColour = "green.5";
          //   } else {
          //     fontColour = "dark.9";
          //   }
        } else {
          fields.statusUpdateHeader = "Available.";

          fields.mostRecentIcon = statusIcons.find((e) => {
            return e.statusId === 2;
          });
        }
      }
    } else {
      fields.statusUpdateHeader = "Available.";

      fields.mostRecentIcon = statusIcons.find((e) => {
        return e.statusId === 2;
      });
    }

    return fields;
  }

  interface TableRowProps {
    dashboardService: DashboardService;
    notificationEnabled: boolean;
    notificationPreferences: UserNotificationPreference[];
    isFav: boolean;
    keyId: number;
  }

  function NewTableRow({
    dashboardService,
    notificationEnabled,
    notificationPreferences,
    isFav,
    keyId,
  }: TableRowProps) {
    //const [menuOpened, setMenuOpened] = useState<boolean>(false);
    if (dashboardService !== undefined) {
      let fieldsObject: FieldsObject = checkUpdates(dashboardService);

      let statusUpdateHeader: string = fieldsObject.statusUpdateHeader;
      let statusUpdateDateTime: Date | undefined =
        fieldsObject.statusUpdateDateTime;
      let mostRecentIcon: StatusIcon | undefined = fieldsObject.mostRecentIcon;

      const notificationIcon = notificationEnabled ? (
        <BellRinging size="1.425rem" />
      ) : (
        <BellOff size="1.425rem" />
      );

      return (
        <Box key={keyId} mt={-8}>
          <Paper p="sm" shadow="xs">
            <Grid gutter="xs" align="center">
              <Grid.Col span={phone ? 8 : tablet ? 3 : 4}>
                <Tooltip
                  events={{ hover: true, focus: true, touch: true }}
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    border: "1px solid black",
                  }}
                  multiline
                  width={phone ? 200 : "auto"}
                  openDelay={500}
                  label={
                    dashboardService.service.serviceDescription
                      ? dashboardService.service.serviceDescription
                      : "No description found."
                  }
                >
                  <Group spacing={tablet ? "sm" : "xl"} noWrap>
                    <ActionIcon
                      color={isFav === true ? "yellow.4" : "gray"}
                      onClick={() => {
                        changeFavourite(
                          dashboardService.service.serviceId,
                          notificationEnabled,
                          isFav,
                          !notificationEnabled,
                          notificationPreferences
                        );
                      }}
                      ml={tablet ? "sm" : "xl"}
                    >
                      <Star />
                    </ActionIcon>

                    <Text fz="sm" truncate>
                      {dashboardService.service.serviceName}
                    </Text>
                  </Group>
                </Tooltip>
              </Grid.Col>
              <MediaQuery largerThan={590} styles={{ display: "none" }}>
                <Grid.Col span={1}>{mostRecentIcon?.icon}</Grid.Col>
              </MediaQuery>
              <MediaQuery smallerThan={590} styles={{ display: "none" }}>
                <Grid.Col span={tablet ? 7 : 6}>
                  <Group spacing="sm" noWrap>
                    {mostRecentIcon?.icon}
                    <MediaQuery smallerThan={520} styles={{ display: "none" }}>
                      <Text fz="sm" truncate lineClamp={1}>
                        {statusUpdateHeader}
                      </Text>
                    </MediaQuery>
                    <MediaQuery smallerThan={670} styles={{ display: "none" }}>
                      <Text c="dimmed" fz="sm" lineClamp={1}>
                        {statusUpdateDateTime &&
                          statusUpdateDateTime.toLocaleTimeString("en-US", {
                            day: "numeric",
                            month: "numeric",
                            year: "numeric",
                            timeZone: "America/New_York",
                            hour12: true,
                          })}
                      </Text>
                    </MediaQuery>
                  </Group>
                </Grid.Col>
              </MediaQuery>
              <MediaQuery smallerThan={1235} styles={{ display: "none" }}>
                <Grid.Col span={2}>
                  <Box>
                    <Group spacing="md" position="center">
                      <Anchor
                        style={{ alignSelf: "flex-end" }}
                        component={Link}
                        to={`/service-details/${dashboardService.service.serviceId}`}
                      >
                        <Tooltip label="View Service Details">
                          <ActionIcon
                            color={"green.4"}
                            variant="light"
                            radius="xl"
                            size="lg"
                          >
                            <List size="1.425rem" />
                          </ActionIcon>
                        </Tooltip>
                      </Anchor>

                      <Menu
                      // key={dashboardService.service.serviceId}
                      // shadow="md"
                      // width={200}
                      // opened={menuOpened}
                      // onChange={setMenuOpened}
                      >
                        <Menu.Target>
                          <Tooltip label="Edit Notification Preferences">
                            <ActionIcon
                              size="lg"
                              variant="light"
                              radius="xl"
                              color={
                                notificationEnabled ? "orange.7" : "gray.5"
                              }
                            >
                              {notificationIcon}
                            </ActionIcon>
                          </Tooltip>
                        </Menu.Target>
                        <Menu.Dropdown>
                          {notificationEnabled && (
                            <Menu.Item
                              icon={<BellOff size={14} />}
                              onClick={() => {
                                changeNotification(
                                  dashboardService.service.serviceId,
                                  !notificationEnabled,
                                  notificationPreferences
                                );
                              }}
                            >
                              Disable Notifications
                            </Menu.Item>
                          )}
                          {!notificationEnabled && (
                            <Menu.Item
                              icon={<BellRinging size={14} />}
                              onClick={() => {
                                changeNotification(
                                  dashboardService.service.serviceId,
                                  !notificationEnabled,
                                  notificationPreferences
                                );
                              }}
                            >
                              Enable Notifications
                            </Menu.Item>
                          )}
                          {/* <Menu.Divider /> */}

                          {/* <Menu.Label>Notification Settings</Menu.Label> */}
                          {/* <Menu.Item
                            icon={<Mail size={14} />}
                            onClick={() => {
                            setSelectedService(dashboardService.service);
                            setSelectedNotificationPreference(
                                notificationPreferences!
                            );
                            setEmailModal(true);
                            }}
                        >
                            Email
                        </Menu.Item> */}
                        </Menu.Dropdown>
                      </Menu>
                      {userIsTechnician && (
                        <Menu>
                          <Menu.Target>
                            <Tooltip label="Create/Edit Service Alerts">
                              <ActionIcon
                                variant="light"
                                radius="xl"
                                color="red"
                                size="lg"
                              >
                                <Flag size="1.425rem" />
                              </ActionIcon>
                            </Tooltip>
                          </Menu.Target>
                          <Menu.Dropdown>
                            <Menu.Item
                              icon={<Note size={14} />}
                              onClick={() => {
                                setSelectedService(dashboardService.service);
                                setServiceModal(true);
                              }}
                            >
                              Create New Service Alert
                            </Menu.Item>

                            <Menu.Divider />
                            <Menu.Item
                              icon={<Eye size={14} />}
                              onClick={() => {
                                setSelectedService(dashboardService.service);
                                setExistingAlertModal(true);
                              }}
                            >
                              View Existing Service Alerts
                            </Menu.Item>
                          </Menu.Dropdown>
                        </Menu>
                      )}
                    </Group>
                  </Box>
                </Grid.Col>
              </MediaQuery>

              {/* MOBILE */}
              <MediaQuery
                largerThan={1235}
                styles={{ display: "none", justifyContent: "right" }}
              >
                <Grid.Col span="content">
                  <Menu
                  // key={keyId}
                  // shadow="md"
                  // width={200}
                  // opened={menuOpened}
                  // onChange={setMenuOpened}
                  >
                    <Menu.Target>
                      <ActionIcon
                        color={"teal.7"}
                        variant="outline"
                        radius="xl"
                        size={phone ? "md" : tablet ? "lg" : "lg"}
                        ml="xs"
                      >
                        <List size="1.25rem" />
                      </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown>
                      {notificationEnabled && (
                        <Menu.Item
                          icon={<BellOff size={14} />}
                          onClick={() => {
                            changeNotification(
                              dashboardService.service.serviceId,
                              !notificationEnabled,
                              notificationPreferences
                            );
                          }}
                        >
                          Disable Notifications
                        </Menu.Item>
                      )}
                      {!notificationEnabled && (
                        <Menu.Item
                          icon={<BellRinging size={14} />}
                          onClick={() => {
                            changeNotification(
                              dashboardService.service.serviceId,
                              !notificationEnabled,
                              notificationPreferences
                            );
                          }}
                        >
                          Enable Notifications
                        </Menu.Item>
                      )}
                      <Menu.Divider />
                      {userIsTechnician && (
                        <Menu.Label>Technician Actions</Menu.Label>
                      )}
                      {userIsTechnician && (
                        <Menu.Item
                          icon={<Note size={14} />}
                          onClick={() => {
                            setSelectedService(dashboardService.service);
                            setServiceModal(true);
                          }}
                        >
                          Create New Service Alert
                        </Menu.Item>
                      )}
                      {userIsTechnician && <Menu.Divider />}
                      {userIsTechnician && (
                        <Menu.Item
                          icon={<Eye size={14} />}
                          onClick={() => {
                            setSelectedService(dashboardService.service);
                            setExistingAlertModal(true);
                          }}
                        >
                          View Existing Service Alerts
                        </Menu.Item>
                      )}
                    </Menu.Dropdown>
                  </Menu>
                </Grid.Col>
              </MediaQuery>
            </Grid>
          </Paper>
        </Box>
      );
    } else {
      return null;
    }
  }

  const myRowData = dashboardRows?.favouriteServices.map(
    (service: DashboardService) => {
      if (service.service !== null && service.service.active === true) {
        const notificationPreferences = userNotifications.filter(
          (un) =>
            un.active === true && un.serviceId === service.service.serviceId
        );

        var notificationEnabled = false;

        if (notificationPreferences!.length > 0) {
          if (notificationPreferences![0].emailEnabled) {
            notificationEnabled = true;
          }
        }
        return (
          <NewTableRow
            key={service.service.serviceId}
            dashboardService={service}
            notificationEnabled={notificationEnabled}
            notificationPreferences={notificationPreferences}
            isFav={true}
            keyId={service.service.serviceId}
          />
        );
      } else {
        return (
          <Center key={randomId()}>
            <Text c="dimmed">No favourites to display.</Text>
          </Center>
        );
      }
    }
  );

  // Filtering/paging All Services Data ************************************
  const allDashboardServices = useMemo(() => {
    let currentAllServices = otherDashboardRows;

    if (search) {
      currentAllServices = currentAllServices.filter(
        (e) =>
          e.service.serviceName.toLowerCase().includes(search.toLowerCase()) ||
          e.service.category?.toLowerCase().includes(search.toLowerCase())
      );

      setMaxPages(Math.ceil(currentAllServices.length / servicesPerPage));
      setPage(1);
    }

    return currentAllServices.slice(
      (activePage - 1) * servicesPerPage,
      (activePage - 1) * servicesPerPage + servicesPerPage
    );
  }, [otherDashboardRows, activePage, search]);

  const allRowData = allDashboardServices.map((service: DashboardService) => {
    if (service.service !== null && service.service.active === true) {
      const notificationPreferences = userNotifications.filter(
        (un) => un.active === true && un.serviceId === service.service.serviceId
      );

      var notificationEnabled = false;

      if (notificationPreferences.length > 0) {
        if (notificationPreferences![0].emailEnabled) {
          notificationEnabled = true;
        }
      }

      return (
        <NewTableRow
          key={service.service.serviceId}
          dashboardService={service}
          notificationEnabled={notificationEnabled}
          notificationPreferences={notificationPreferences}
          isFav={false}
          keyId={service.service.serviceId}
        />
      );
    } else {
      return (
        <Center key={randomId()}>
          <Text c="dimmed">No services to display.</Text>
        </Center>
      );
    }
  });

  function renavRefresh() {
    loadData();
  }

  useEffect(() => {
    const interval = setInterval(() => {
      loadData();
    }, 30000);

    if (!isDataLoaded) {
      loadData();
    }

    setRefreshTime(new Date().toLocaleTimeString());
    //setRefreshTime(Date.now().toString());

    return () => clearInterval(interval);
  }, [isDataLoaded]);

  return (
    //<Center>

    <Box
      pos="relative"
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      {/* <LoadingOverlay
        visible={!areServicesLoaded}
        overlayOpacity={0.7}
        overlayBlur={2}
      /> */}
      {emailModal && (
        <EmailModal
          opened={emailModal}
          setOpened={setEmailModal}
          selectedService={selectedService!}
          graphUserId={userId}
          notificationPreference={[] /*selectedNotificationPreference!*/}
        />
      )}
      <ServiceModal
        opened={serviceModal}
        setOpened={setServiceModal}
        selectedService={selectedService!}
        renavRefresh={renavRefresh}
      />
      <ExistingAlertModal
        opened={existingAlertModal}
        setOpened={setExistingAlertModal}
        selectedService={selectedService!}
        renavRefresh={renavRefresh}
      />
      <Stack style={{ width: "100%" }}>
        <>
          <Box>
            <Box
              sx={(theme) => ({
                backgroundColor: theme.colors.gray[0],
                display: "flex",
                width: "100%",
                padding: theme.spacing.sm,
                borderRadius: theme.radius.md,
                justifyContent: areServicesLoaded ? "flex-start" : "center",
                alignItmes: areServicesLoaded ? "flex-start" : "center",
              })}
            >
              {favouritesLoaded && (
                <Stack style={{ width: "100%" }}>
                  <Group position="apart">
                    <Title order={2}>My Services</Title>

                    <Grid
                      gutter="xs"
                      gutterSm="lg"
                      justify="flex-end"
                      className={classes.legendSize}
                    >
                      <Grid.Col span={6} sm="content">
                        <Group spacing="xs">
                          <CircleCheck color="green" height="18" />
                          <Text fz="sm">Available</Text>
                        </Group>
                      </Grid.Col>
                      <Grid.Col span={6} sm="content">
                        <Group spacing="xs">
                          <Tool color="blue" height="18" />
                          <Text fz="sm">Maintenance</Text>
                        </Group>
                      </Grid.Col>
                      <Grid.Col span={6} sm="content">
                        <Group spacing="xs">
                          <AlertTriangle color="orange" height="18" />
                          <Text fz="sm">Issue</Text>
                        </Group>
                      </Grid.Col>
                      <Grid.Col span={6} sm="content">
                        <Group spacing="xs">
                          <CircleX color="red" height="18" />
                          <Text fz="sm">Outage</Text>
                        </Group>
                      </Grid.Col>
                    </Grid>

                    {/* <SimpleGrid
                    cols={4}
                    spacing="xs"
                    verticalSpacing="xs"
                    breakpoints={[{ maxWidth: 1200, cols: 2 }]}
                  >
                    <Group spacing="xs">
                      <CircleCheck color="green" height="18" />
                      <Text fz="sm">Available</Text>
                    </Group>
                    <Group spacing="xs">
                      <Tool color="blue" height="18" />
                      <Text fz="sm">Maintenance</Text>
                    </Group>
                    <Group spacing="xs">
                      <AlertTriangle color="orange" height="18" />
                      <Text fz="sm">Issue</Text>
                    </Group>
                    <Group spacing="xs">
                      <CircleX color="red" height="18" />
                      <Text fz="sm">Outage</Text>
                    </Group>
                  </SimpleGrid> */}
                  </Group>
                  {myRowData}
                </Stack>
              )}
              {!favouritesLoaded && (
                <Flex align="center" justify="center">
                  <Loader />
                </Flex>
              )}
            </Box>
          </Box>

          <Box pos="relative">
            <Box
              sx={(theme) => ({
                backgroundColor: theme.colors.gray[0],
                width: "100%",
                display: "flex",
                padding: theme.spacing.sm,
                borderRadius: theme.radius.md,
                justifyContent: areServicesLoaded ? "flex-start" : "center",
                alignItmes: areServicesLoaded ? "flex-start" : "center",
              })}
            >
              {areServicesLoaded && (
                <Stack style={{ width: "100%" }}>
                  <Group position="apart">
                    <Group spacing="md">
                      <Title order={2}>All Services</Title>
                      <TextInput
                        placeholder="Search"
                        radius="xl"
                        ml="sm"
                        style={{ width: "50%" }}
                        value={search}
                        onChange={(e) => setSearch(e.currentTarget.value)}
                      />
                    </Group>
                    <Grid
                      gutter="xs"
                      gutterSm="lg"
                      justify="flex-end"
                      className={classes.allLegendSize}
                    >
                      <Grid.Col span={6} sm="content">
                        <Group spacing="xs">
                          <CircleCheck color="green" height="18" />
                          <Text fz="sm">Available</Text>
                        </Group>
                      </Grid.Col>
                      <Grid.Col span={6} sm="content">
                        <Group spacing="xs">
                          <Tool color="blue" height="18" />
                          <Text fz="sm">Maintenance</Text>
                        </Group>
                      </Grid.Col>
                      <Grid.Col span={6} sm="content">
                        <Group spacing="xs">
                          <AlertTriangle color="orange" height="18" />
                          <Text fz="sm">Issue</Text>
                        </Group>
                      </Grid.Col>
                      <Grid.Col span={6} sm="content">
                        <Group spacing="xs">
                          <CircleX color="red" height="18" />
                          <Text fz="sm">Outage</Text>
                        </Group>
                      </Grid.Col>
                    </Grid>
                  </Group>
                  {/* <Paper shadow="xs">
                  {areServicesLoaded && (
                    <Table
                      verticalSpacing="md"
                      style={{ tableLayout: "fixed" }}
                    >
                      <thead>
                        <tr>
                          <th></th>
                          <th style={{ width: "30%" }}>Service Name</th>
                          <th>LHO</th>
                          <th>LHAP</th>
                          <th>LHB</th>
                          <th>LHPP</th>
                          <th>LHW</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </Table>
                  )}

                  <Center mt="md">
                    <Pagination
                      mb="md"
                      page={activePage}
                      onChange={setPage}
                      total={maxPages}
                    />
                  </Center>
                </Paper> */}

                  {allRowData}

                  <Center mt="md">
                    <Pagination
                      mb="md"
                      size={phone ? "sm" : "md"}
                      page={activePage}
                      onChange={setPage}
                      total={maxPages}
                    />
                  </Center>
                </Stack>
              )}
              {!areServicesLoaded && (
                <Flex justify="center" align="center">
                  <Loader />
                </Flex>
              )}
            </Box>
            <Group spacing="xs" mt="sm">
              <ActionIcon onClick={() => refreshPage()}>
                <Refresh size="24" color="gray" />
              </ActionIcon>
              <Text c="dimmed" fz="sm">
                Data last refreshed at: {refreshTime}
              </Text>
            </Group>
          </Box>
        </>
      </Stack>
    </Box>
    //</Center>
  );
};

export default HomePage;
