import { useParams } from "react-router-dom";
import { Box } from "@mantine/core";

import { CreateAlert, NewAlert } from "components";

const NewServiceAlert = () => {
  const { serviceId } = useParams();

  return (
    <Box p="lg">
      {serviceId !== undefined && (
        <CreateAlert isModal={false} serviceId={parseInt(serviceId)} />
      )}
      {serviceId === undefined && <NewAlert />}
    </Box>
  );
};

export default NewServiceAlert;
