import {
  Grid,
} from "@mantine/core";

import { RecentHistory } from "components";
import { StatusUpdateResponse } from "types";

type ServiceHistoryProps = {
  serviceId: string | undefined;
  statusUpdates: StatusUpdateResponse[] | undefined;
};

export const ServiceHistory = ({
  serviceId,
  statusUpdates,
}: ServiceHistoryProps) => {
    const selectedSite = "1";
//   const [siteList, setSiteList] = useState<SelectData[]>([]);
//   const [selectedSite, setSelectedSite] = useState<string | null | undefined>(
//     "1"
//   );

//   async function loadSiteList() {
//     const sites: Site[] = await externalApi
//       .get(endpoints.getSiteList.url)
//       .json();

//     const allSites = sites.map((site) => {
//       return {
//         label: site.siteName,
//         value: site.siteId.toString(),
//         key: site.siteId,
//       };
//     });

//     setSiteList(allSites);
//   }

//   useEffect(() => {
//     loadSiteList();
//   }, []);

  return (
    <>
      {/* <Select
        value={selectedSite}
        onChange={setSelectedSite}
        data={siteList}
        style={{ width: "33%" }}
      /> */}

      <Grid justify="flex-start" align="flex-start">
        <Grid.Col lg={6}>
          <RecentHistory serviceId={serviceId} siteId={selectedSite} />
        </Grid.Col>
        {/* <Grid.Col lg={6}>
          {" "}
          <HistoryCalendar serviceId={serviceId} siteId={selectedSite} />
        </Grid.Col> */}
      </Grid>

      {/* <SimpleGrid
        cols={2}
        spacing="xl"
        breakpoints={[{ maxWidth: 1519, cols: 1 }]}
      >
        <Box style={{ justifyContent: "center" }}>
          <RecentHistory serviceId={serviceId} siteId={selectedSite} />
        </Box>
        <Box style={{ justifyContent: "center" }}>
          <HistoryCalendar serviceId={serviceId} siteId={selectedSite} />
        </Box>
      </SimpleGrid> */}

      {/* <MediaQuery largerThan={1216} styles={hide}>
        <Center>
          <Select
            value={selectedSite}
            onChange={setSelectedSite}
            data={siteList}
            style={{ width: "33%" }}
          />
        </Center>
      </MediaQuery>
      <MediaQuery largerThan={1216} styles={hide}>
        <Center>
          <Stack align="center" justify="flex-start">
            <RecentHistory serviceId={serviceId} siteId={selectedSite} />
            <HistoryCalendar serviceId={serviceId} siteId={selectedSite} />
          </Stack>
        </Center>
      </MediaQuery> */}
    </>
  );
};
