import { useEffect, useState, useMemo } from "react";

import {
  Box,
  Title,
  Container,
  Paper,
  Stack,
  Text,
  Group,
  createStyles,
  ActionIcon,
  Tooltip,
  Anchor,
  TextInput,
  Center,
  Pagination,
  Select,
} from "@mantine/core";

import { Link } from "react-router-dom";

import { Edit, Checkbox } from "tabler-icons-react";

import api from "../api";
import endpoints from "../api/endpoints";

import { statusIcons } from "../common/Data";

import {
  GraphUser,
  StatusServiceAffectedResponse,
  StatusUpdateResponse,
  StatusUpdateTimeline,
} from "types";

import { ResolveModal } from "components/modals";

const useStyles = createStyles((theme) => ({
  formHeading: {
    backgroundColor: theme.fn.variant({
      variant: "filled",
      color: theme.primaryColor,
    }).background,
    borderBottom: 0,
    borderTopLeftRadius: theme.radius.md,
    borderTopRightRadius: theme.radius.md,
    padding: theme.spacing.sm,
  },
}));

const ActiveAlerts = () => {
  const [me, setMe] = useState<GraphUser>();
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [alertListLoaded, setAlertListLoaded] = useState<boolean>(false);
  const [resolveModalShow, setResolveModalShow] = useState<boolean>(false);
  const [selectedStatusUpdate, setSelectedStatusUpdate] =
    useState<StatusUpdateResponse | null>();
  const [statusUpdates, setStatusUpdates] = useState<StatusUpdateResponse[]>(
    []
  );
  const [timelineItems, setTimelineItems] = useState<StatusUpdateTimeline[]>(
    []
  );
  const [statusServices, setStatusServices] = useState<
    StatusServiceAffectedResponse[]
  >([]);
  const [activePage, setPage] = useState(1);
  const [alertsPerPage, setAlertsPerPage] = useState(10);
  const [maxPages, setMaxPages] = useState(10);
  const [search, setSearch] = useState("");
  const [userList, setUserList] = useState<GraphUser[]>([]);
  const { classes } = useStyles();

  type ActiveAlertProps = {
    statusUpdate: StatusUpdateResponse;
    key: number;
  };

  function ActiveAlertBox({ statusUpdate }: ActiveAlertProps) {
    const relevantTimelineItems = timelineItems.filter(
      (tl) => tl.statusUpdateId === statusUpdate.statusUpdateId
    );

    const as = statusServices.filter(
      (ss) => ss.statusUpdateId === statusUpdate.statusUpdateId
    );

    const affectedServices = as.filter(
      (
        svc: StatusServiceAffectedResponse,
        index: number,
        array: StatusServiceAffectedResponse[]
      ) => {
        const svcIndex = array.findIndex((s) => s.serviceId === svc.serviceId);
        return index === svcIndex;
      }
    );

    return (
      <Box key={statusUpdate.statusUpdateId}>
        <Container fluid className={classes.formHeading}>
          <Stack spacing="xs">
            <Group position="apart">
              <Title order={3} color="white">
                {statusUpdate.title !== "" && statusUpdate.title}
              </Title>
              <Group>
                <Anchor
                  style={{ alignSelf: "flex-end" }}
                  component={Link}
                  to={`/edit-alert/${statusUpdate.statusUpdateId}`}
                >
                  <Tooltip label="Edit">
                    <ActionIcon color="teal" variant="filled">
                      <Edit size={50} />
                    </ActionIcon>
                  </Tooltip>
                </Anchor>
                <Tooltip label="Resolve">
                  <ActionIcon
                    color="teal"
                    variant="filled"
                    onClick={() => {
                      setResolveModalShow(true);
                      setSelectedStatusUpdate(statusUpdate);
                    }}
                  >
                    <Checkbox size={50} />
                  </ActionIcon>
                </Tooltip>
              </Group>
            </Group>
            <Group position="apart">
              {relevantTimelineItems.length > 0 && (
                <Text fz="xs" color="white">
                  Last Updated at{" "}
                  {new Date(
                    new Date(
                      relevantTimelineItems.sort()[0].updateDateTime + "Z"
                    )
                  ).toLocaleTimeString("en-US", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    timeZone: "America/New_York",
                    hour12: true,
                  })}
                </Text>
              )}
              <Text fz="xs" color="white">
                #{statusUpdate.statusUpdateId}
              </Text>
            </Group>
          </Stack>
        </Container>
        <Paper withBorder p="sm" shadow="xs" mb="sm">
          <Stack>
            <Box>
              {statusUpdate.description !== "" && (
                <Text mb="sm">{statusUpdate.description}</Text>
              )}

              <Text fw={500}>Impacted Services </Text>
              {affectedServices.map((as) => {
                const recentStatusIcon = statusIcons.find((e) => {
                  return e.statusId === as.statusId;
                })?.icon;

                return (
                  <Group key={as.serviceId}>
                    {recentStatusIcon}
                    <Text>{as.service?.serviceName}</Text>
                  </Group>
                );
              })}
            </Box>
            <Box>
              {relevantTimelineItems.length > 0 && (
                <Title order={6} color="gray">
                  Updates Timeline
                </Title>
              )}
              {relevantTimelineItems.sort().map((tl) => {
                const convertedTime = new Date(tl.updateDateTime + "Z");

                const userName = userList.filter(
                  (ul) => tl.createdByUserId === ul.graphUserId
                )[0]?.displayName;

                return (
                  <Paper
                    p="sm"
                    withBorder
                    radius="lg"
                    mb="xs"
                    key={tl.statusUpdateTimelineId}
                  >
                    <Group spacing="xs">
                      <Title order={4}>{tl.header} - </Title>
                      <Title order={5} color="gray" ml={-3}>
                        {" "}
                        {convertedTime.toLocaleTimeString("en-US", {
                          day: "numeric",
                          month: "numeric",
                          year: "numeric",
                          timeZone: "America/New_York",
                          hour12: true,
                        })}
                      </Title>
                    </Group>
                    <Text fz="sm" c="dimmed">
                      By {userName}
                    </Text>
                    <Text fz="sm" mt="sm">
                      {tl.shortDesc}
                    </Text>
                  </Paper>
                );
              })}
            </Box>
          </Stack>
        </Paper>
      </Box>
    );
  }

  async function loadData() {
    const user: GraphUser = await api.get(endpoints.getMe.url).json();

    setMe(user);

    const statusUpdatePull: StatusUpdateResponse[] = await api
      .get(`${endpoints.getStatusUpdateList.url}`)
      .json();

    setStatusUpdates(statusUpdatePull.filter((su) => su.resolved === false));

    const activeUpdates = statusUpdatePull.filter(
      (sp) => sp.resolved === false && sp.active === true
    );

    setMaxPages(Math.ceil(activeUpdates.length / alertsPerPage));

    const statusTimelinePull: StatusUpdateTimeline[] = await api
      .get(`${endpoints.getStatusUpdateTimelineList.url}`)
      .json();

    setTimelineItems(statusTimelinePull);

    const statusServicesPull: StatusServiceAffectedResponse[] = await api
      .get(`${endpoints.getStatusServicesAffectedList.url}`)
      .json();

    setStatusServices(statusServicesPull);

    const users: GraphUser[] = await api
      .get(`${endpoints.getUserList.url}`)
      .json();

    setUserList(users);

    setAlertListLoaded(true);
  }

  function renavRefresh() {
    loadData();
  }

  const allAlerts = useMemo(() => {
    let currentAllAlerts = statusUpdates;

    if (search) {
      let fSS = statusServices
        .filter((e) =>
          e.service?.serviceName.toLowerCase().includes(search.toLowerCase())
        )
        .map((e) => e.statusUpdateId);

      currentAllAlerts = currentAllAlerts.filter((e) =>
        fSS.includes(e.statusUpdateId)
      );

      setPage(1);
    }

    setMaxPages(Math.ceil(currentAllAlerts.length / alertsPerPage));

    return currentAllAlerts.slice(
      (activePage - 1) * alertsPerPage,
      (activePage - 1) * alertsPerPage + alertsPerPage
    );
  }, [statusUpdates, activePage, search, alertsPerPage]);

  useEffect(() => {
    if (!isDataLoaded) {
      loadData();
      setIsDataLoaded(true);
    }
  }, []);

  return (
    <>
      <ResolveModal
        opened={resolveModalShow}
        setOpened={setResolveModalShow}
        selectedStatusUpdate={selectedStatusUpdate}
        setSelectedStatusUpdate={setSelectedStatusUpdate}
        me={me}
        renavRefresh={renavRefresh}
      />
      <Title order={1}>Active Alerts</Title>
      <Group position="apart" align="flex-end">
        <TextInput
          placeholder="Search by Impacted Service..."
          radius="xl"
          mb="sm"
          style={{ width: "50%" }}
          value={search}
          onChange={(e) => setSearch(e.currentTarget.value)}
        />
        <Select
          mb="sm"
          data={["5", "10", "15", "20"]}
          label="Alerts per page"
          value={alertsPerPage.toString()}
          onChange={(e) => setAlertsPerPage(parseInt(e!))}
        />
      </Group>

      {alertListLoaded &&
        allAlerts.map((su: StatusUpdateResponse) => {
          return <ActiveAlertBox statusUpdate={su} key={su.statusUpdateId} />;
        })}

      <Center mt="md">
        <Pagination
          mb="md"
          page={activePage}
          onChange={setPage}
          total={maxPages}
        />
      </Center>
    </>
  );
};

export default ActiveAlerts;
