import { MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import { InteractionType } from "@azure/msal-browser";
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { loginRequest, msalInstance } from "./auth/config";

import Layout from "common/Layout";
import {
  HomePage,
  ServiceDetails,
  NothingFoundPage,
  NewServiceAlert,
  ServiceManager,
  ActiveAlerts,
  EditServiceAlert,
  ViewServiceAlert,
  ResolvedAlerts,
  AddHistoricalAlert,
} from "pages";

const App = () => (
  <MantineProvider
    withNormalizeCSS
    withGlobalStyles
    theme={{ primaryColor: "teal" }}
  >
    <NotificationsProvider>
      <MsalProvider instance={msalInstance}>
        <BrowserRouter>
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={loginRequest}
          >
            <Layout>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/service-manager" element={<ServiceManager />} />
                <Route
                  path="/service-details/:serviceId"
                  element={<ServiceDetails />}
                />
                <Route
                  path="/edit-alert/:statusUpdateId"
                  element={<EditServiceAlert />}
                />
                <Route
                  path="/view-alert/:statusUpdateId"
                  element={<ViewServiceAlert />}
                />
                <Route path="/new-alert" element={<NewServiceAlert />} />
                <Route
                  path="/service-alert/:serviceId"
                  element={<NewServiceAlert />}
                />
                <Route path="/active-alerts" element={<ActiveAlerts />} />
                <Route path="/resolved-alerts" element={<ResolvedAlerts />} />
                <Route
                  path="/add-historical-alert"
                  element={<AddHistoricalAlert />}
                />
                <Route path="*" element={<NothingFoundPage />} />
              </Routes>
            </Layout>
          </MsalAuthenticationTemplate>
        </BrowserRouter>
      </MsalProvider>
    </NotificationsProvider>
  </MantineProvider>
);

export default App;
