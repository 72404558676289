import { InteractionRequiredAuthError } from "@azure/msal-browser";
import ky from "ky";

import { loginRequest, msalInstance } from "../auth/config";

export const api = ky
  .create({ prefixUrl: `${process.env.REACT_APP_API}` })
  .extend({
    timeout: 60000,
    hooks: {
      /*
       * Request interceptor for API calls. This instance implements
       * authentication by silently with a redirect fallback attaching
       * a Bearer token to each request.
       */
      beforeRequest: [
        async (request) => {
          const account = msalInstance.getAllAccounts()[0];
          let token = await msalInstance
            .acquireTokenSilent({
              ...loginRequest,
              account,
            })
            .catch(async (error) => {
              if (error instanceof InteractionRequiredAuthError) {
                token = await msalInstance.acquireTokenRedirect({
                  ...loginRequest,
                  account,
                });
              }
            });
          if (token != null)
            request.headers.set("Authorization", `Bearer ${token.accessToken}`);
        },
      ],
      afterResponse: [
        (_request, _options, response) => {
          if (response.status === 403 || response.status === 401) {
            console.log("Could not be signed in");
          }
        },
      ],
    },
  });
