import { useParams } from "react-router-dom";

import { useState } from "react";
import { Box } from "@mantine/core";


import { EditAlert } from "components";

const ViewServiceAlert = () => {
  const { statusUpdateId } = useParams();
  const edit = false;

  const [openUpdate, setOpenUpdate] = useState<boolean>(true);

  return (
    <Box p="lg">
      {statusUpdateId !== undefined && (
        <EditAlert
          statusUpdateId={parseInt(statusUpdateId)}
          edit={edit}
          openUpdate={openUpdate}
          setOpenUpdate={setOpenUpdate}
          fromDash={false}
        />
      )}
    </Box>
  );
};

export default ViewServiceAlert;
