import React, { Dispatch, SetStateAction, forwardRef } from "react";

import { useState, useEffect } from "react";
import {
  Stack,
  Title,
  Center,
  Box,
  Paper,
  Group,
  Button,
  Select,
  TextInput,
  Textarea,
  Container,
  Checkbox,
  Loader,
  Text,
  Anchor,
  Alert,
  createStyles,
  Menu,
  ActionIcon,
  Tooltip,
  SelectItem,
} from "@mantine/core";

import { useMediaQuery } from "@mantine/hooks";

import { useForm } from "@mantine/form";

import { useMsal } from "@azure/msal-react";

import { showNotification } from "@mantine/notifications";

import api from "../api";
import endpoints from "../api/endpoints";

import { Link } from "react-router-dom";

import {
  CircleCheck,
  AlertTriangle,
  Tool,
  CircleX,
  Trash,
  Plus,
  AlertCircle,
  Edit,
  BellRinging,
  BellOff,
} from "tabler-icons-react";

import { IconChevronDown } from "@tabler/icons";

import {
  SelectData,
  NewStatusUpdateTimelineResponse,
  NewStatusServicesAffectedResponse,
  UpdateStatusServicesAffectedResponse,
  UserNotificationPreference,
  UpdateStatusUpdateResponse,
  GraphUser,
  Service,
  StatusUpdateTimeline,
  PriorityLevel,
  StatusUpdateResponse,
  StatusServiceAffectedResponse,
  AddUserServiceNotificationPreferenceResponse,
  AddUserServiceNotificationPreferenceRequest,
  ResolveStatusUpdateResponse,
  SendNotificationResponse,
  UpdateStatusUpdateTimelineResponse,
} from "types";

import { randomId } from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
  formHeading: {
    backgroundColor: theme.fn.variant({
      variant: "filled",
      color: theme.primaryColor,
    }).background,
    borderBottom: 0,
    borderTopLeftRadius: theme.radius.md,
    borderTopRightRadius: theme.radius.md,
    padding: theme.spacing.sm,
  },
  formSubheading: {
    backgroundColor: theme.fn.variant({
      variant: "filled",
      color: theme.primaryColor,
    }).background,
    borderBottom: 0,
    padding: theme.spacing.sm,
  },
}));

const statusList = [
  {
    image: <CircleCheck color="green" />,
    label: "Available",
    value: "2",
    statusId: 2,
    color: "green",
  },
  {
    image: <AlertTriangle color="orange" />,
    label: "Issue",
    value: "4",
    statusId: 4,
    color: "orange",
  },
  {
    image: <CircleX color="red" />,
    label: "Outage",
    value: "5",
    statusId: 5,
    color: "red",
  },
  {
    image: <Tool color="blue" />,
    label: "Maintenance",
    value: "3",
    statusId: 3,
    color: "blue",
  },
];

const selectStatuses = [
  {
    key: 2,
    label: "Available",
    value: "2",
    image: <CircleCheck color="green" />,
  },
  {
    key: 4,
    label: "Issue",
    value: "4",
    image: <AlertTriangle color="orange" />,
  },
  {
    key: 5,
    label: "Outage",
    value: "5",
    image: <CircleX color="red" />,
  },
  {
    key: 3,
    label: "Maintenance",
    value: "3",
    image: <Tool color="blue" />,
  },
];

interface AffectedServices {
  statusServicesAffectedId: number;
  serviceId: number;
  statusId: number;
  preLoad: boolean;
  key: string;
}

interface FormData {
  statusUpdateTitle: string;
  statusUpdateDescription: string;
  affectedServices: AffectedServices[];
  header: string;
  shortDesc: string;
  desc: string;
  displayOnBanner: boolean;
  override: boolean;
  priorityLevel: string;
}

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  image: any;
  label: string;
  statusId: number;
}

const SelectItemDiv = forwardRef<HTMLDivElement, ItemProps>(
  ({ image, label, statusId, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        {image}

        <div>
          <Text size="sm">{label}</Text>
        </div>
      </Group>
    </div>
  )
);

interface PriorityProps extends React.ComponentPropsWithoutRef<"div"> {
  label: string;
  description: string;
}

const PriorityItem = forwardRef<HTMLDivElement, PriorityProps>(
  ({ label, description, ...others }: PriorityProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <div>
          <Text size="sm">{label}</Text>
          <Text size="xs" opacity={0.65}>
            {description}
          </Text>
        </div>
      </Group>
    </div>
  )
);

export const EditServiceAlert = ({
  statusUpdateId,
  edit,
  openUpdate,
  setOpenUpdate,
  fromDash,
}: {
  statusUpdateId?: number;
  edit: boolean;
  openUpdate: boolean;
  setOpenUpdate: Dispatch<SetStateAction<boolean>>;
  fromDash: boolean;
}) => {
  const [me, setMe] = useState<GraphUser>();

  const { accounts } = useMsal();
  const { classes } = useStyles();
  const account = accounts[0];

  const userIsTechnician: boolean | undefined =
    account?.idTokenClaims?.roles?.includes("ServiceHealth.Technician");

  const [servicesList, setServicesList] = useState<Service[]>([]);
  const [selectServices, setSelectServices] = useState<SelectData[]>([]);
  const [userList, setUserList] = useState<GraphUser[]>([]);
  const [currentTimelineItems, setCurrentTimelineItems] = useState<
    StatusUpdateTimeline[]
  >([]);
  const [priorityList, setPriorityList] = useState<SelectItem[]>([]);
  const [serviceError, setServiceError] = useState<boolean>(true);
  const [alertIsResolved, setAlertIsResolved] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<string>("");
  const [timelineSuccess, setTimelineSuccess] = useState<boolean>(false);
  const [submitAsResolution, setSubmitAsResolution] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSubmittingStatuses, setIsSubmittingStatuses] =
    useState<boolean>(false);
  const [displayAsBanner, setDisplayAsBanner] = useState<boolean>(false);
  const [overrideAndAlertAll, setOverrideAndAlertAll] =
    useState<boolean>(false);
  const [addNewTimelineCheck, setAddNewTimelineCheck] =
    useState<boolean>(false);
  const [userNotifications, setUserNotifications] = useState<
    UserNotificationPreference[]
  >([]);

  const phone = useMediaQuery("(max-width: 630px)");

  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);

  const form = useForm<FormData>({
    initialValues: {
      statusUpdateTitle: "",
      statusUpdateDescription: "",
      affectedServices: [],
      header: "",
      shortDesc: "",
      desc: "",
      displayOnBanner: false,
      override: false,
      priorityLevel: "",
    },
    validate: (values) => {
      if (active === 0 && addNewTimelineCheck) {
        return {
          header:
            values.header.trim().length < 1
              ? "You must enter a header."
              : values.header.trim().length > 100
              ? "Header must be 100 characters or less"
              : null,
          shortDesc:
            values.shortDesc.trim().length < 1
              ? "You must include a short description."
              : values.shortDesc.trim().length > 300
              ? "Short Description must be 300 characters or less"
              : null,
          priorityLevel:
            values.priorityLevel.toString().trim().length < 1
              ? "You must select a priority level."
              : null,
        };
      }
      if (active === 1) {
        setServiceError(
          values.affectedServices.filter((as) => as.serviceId).length < 1
            ? true
            : values.affectedServices.filter((as) => as.statusId).length < 1
            ? true
            : false
        );

        return {
          affectedServices:
            values.affectedServices.filter((as) => as.serviceId).length < 1
              ? "You must select at least one Service."
              : values.affectedServices.filter((as) => as.statusId).length < 1
              ? "You must select a status."
              : null,
        };
      }

      return {};
    },
  });

  // Stepper
  //const [active, setActive] = useState(0);
  const active: number = 0;

  async function loadNotificationPreferences(graphUserId: number) {
    //setNotificationLoaded(false);
    const notificationList: UserNotificationPreference[] = await api
      .get(
        `${endpoints.getUserServiceNotificationPreferencesListByUser.url}/${graphUserId}`
      )
      .json();

    setUserNotifications(notificationList);
  }

  async function loadStatusUpdate(statusUpdateId: number | undefined) {
    setCurrentTimelineItems([]);
    form.setFieldValue("affectedServices", []);
    if (statusUpdateId !== undefined) {
      const statusUpdate: StatusUpdateResponse = await api
        .get(`${endpoints.useStatusUpdate.url}/${statusUpdateId}`)
        .json();

      if (statusUpdate.resolved) {
        setAlertIsResolved(true);
      }

      form.setFieldValue("statusUpdateTitle", statusUpdate.title);
      form.setFieldValue("statusUpdateDescription", statusUpdate.description);
      form.setFieldValue("displayOnBanner", statusUpdate.displayOnBanner);
      setDisplayAsBanner(statusUpdate.displayOnBanner);

      const filteredServices: StatusServiceAffectedResponse[] = await api
        .get(
          `${endpoints.getStatusServicesAffectedListByStatusUpdate.url}/${statusUpdateId}`
        )
        .json();

      filteredServices.forEach((svc: StatusServiceAffectedResponse) => {
        const affectedService: AffectedServices = {
          statusServicesAffectedId: svc.statusServicesAffectedId,
          serviceId: svc.serviceId,
          statusId: svc.statusId,
          preLoad: true,
          key: randomId(),
        };
        form.insertListItem("affectedServices", {
          ...affectedService,
        });
      });

      const timeline: StatusUpdateTimeline[] = await api
        .get(`${endpoints.getStatusUpdateTimelineList.url}`)
        .json();

      const relevantTimeline = timeline.filter(
        (tl) => tl.statusUpdateId === statusUpdateId
      );

      setCurrentTimelineItems(relevantTimeline);

      //Setting Form Values
    }
  }

  async function loadServices() {
    const services: Service[] = await api
      .get(`${endpoints.getServiceList.url}`)
      .json();

    setServicesList(services);

    const statusUpdates: StatusUpdateResponse[] = await api
      .get(`${endpoints.getStatusUpdateList.url}`)
      .json();

    let activeUpdates: number[] = statusUpdates
      .filter(
        (su) => su.resolved === false && su.statusUpdateId !== statusUpdateId
      )
      .map((su) => su.statusUpdateId);

    const statusServicesAffected: StatusServiceAffectedResponse[] = await api
      .get(`${endpoints.getStatusServicesAffectedList.url}`)
      .json();

    let servicesWithActiveUpdates: number[] = statusServicesAffected
      .filter((ssa) => activeUpdates.includes(ssa.statusUpdateId))
      .map((ssa) => ssa.serviceId);

    const mapService: SelectData[] = services
      .filter((s: Service) => !servicesWithActiveUpdates.includes(s.serviceId))
      .map((s: Service) => {
        return {
          key: s.serviceId,
          value: s.serviceId.toString(),
          label: s.serviceName,
        };
      });

    setSelectServices(mapService);
  }

  async function loadUser() {
    const user: GraphUser = await api.get(endpoints.getMe.url).json();

    setMe(user);
    loadNotificationPreferences(user.graphUserId);
  }

  async function loadUserList() {
    const users: GraphUser[] = await api
      .get(`${endpoints.getUserList.url}`)
      .json();

    setUserList(users);
  }

  async function loadPriorityItems() {
    const priorities: PriorityLevel[] = await api
      .get(`${endpoints.getPriorityList.url}`)
      .json();

    const mapPriority: SelectItem[] = priorities.map((p: PriorityLevel) => {
      return {
        key: p.priorityLevelId,
        value: p.priorityLevelId.toString(),
        label: p.priorityLevelName,
        description: p.priorityLevelDescription,
      };
    });

    setPriorityList(mapPriority);
  }

  async function changeNotification(
    serviceId: number,
    isNotification: boolean,
    notificationPreference: UserNotificationPreference[] | undefined
  ) {
    // let updatedData = allServices.map((obj) => {
    //   if (obj.id === serviceId) {
    //     return { ...obj, notificationEnabled: !obj.notificationEnabled };
    //   } else return obj;
    // });
    // setAllServices(updatedData);

    if (notificationPreference !== undefined) {
      let updatedPreferences = userNotifications.map((i) => {
        if (i.serviceId === notificationPreference[0]?.serviceId) {
          return { ...i, emailEnabled: !isNotification };
        }
        return i;
      });

      setUserNotifications(updatedPreferences);
    } else {
      const newPreference: UserNotificationPreference = {
        active: true,
        graphUserId: me!.graphUserId,
        serviceId: serviceId,
        emailEnabled: true,
        emailAvailable: false,
        emailIncident: false,
        emailOutage: false,
        emailMaintenance: false,
        isFavourite: false,
        phoneEnabled: false,
        phoneAvailable: false,
        phoneIncident: false,
        phoneMaintenance: false,
        phoneOutage: false,
        passiveEnabled: false,
        siteId: 1,
      };

      setUserNotifications((currentPrefs) => [...currentPrefs, newPreference]);
    }

    const request: AddUserServiceNotificationPreferenceRequest = {
      graphUserId: me!.graphUserId,
      serviceId: serviceId,
      emailEnabled: isNotification,
    };

    const response: AddUserServiceNotificationPreferenceResponse = await api
      .put(endpoints.useUserServiceNotificationPreferencesFavourites.url, {
        json: {
          ...request,
        },
        timeout: 30000,
      })
      .json();

    if (response.success) {
      loadNotificationPreferences(me!.graphUserId);
      showNotification({
        title: "Success!",
        message: "Updated Notification Settings",
        color: "green",
      });
    } else {
      showNotification({
        title: "Error",
        message: "Error saving changes.",
        color: "red",
      });
    }

    //loadServices(user);
    //Reload notifications before reloading services to build table
    // Promise.all([loadNotificationPreferences(user)]).then(() => {
    //   loadServices(user);
    // });
  }

  function AffectedService({
    item,
    index,
  }: //key,
  {
    item: AffectedServices;
    index: number;
    //key: number;
  }) {
    const [menuOpened, setMenuOpened] = useState<boolean>(false);
    const serviceId: number = item.serviceId;

    const notificationPreferences = userNotifications.filter(
      (un) => un.active === true && un.serviceId === serviceId
    );

    var notificationEnabled = false;

    if (notificationPreferences!.length > 0) {
      if (notificationPreferences![0].emailEnabled) {
        notificationEnabled = true;
      }
    }

    const service = servicesList.filter(
      (svc) => svc.serviceId === serviceId
    )[0];

    const notificationIcon = notificationEnabled ? (
      <BellRinging size="1.425rem" />
    ) : (
      <BellOff size="1.425rem" />
    );

    const selectedStatus = form.values["affectedServices"][index].statusId;

    const [selectedServices, setSelectedService] = useState<string | null>(
      serviceId.toString()
    );
    const [selectedFormStatus, setSelectedFormStatus] = useState<string | null>(
      selectedStatus.toString()
    );

    function setFormService(value: string | null) {
      setSelectedService(value);
      form.setFieldValue(`affectedServices.${index}.serviceId`, value);
    }

    function setFormStatus(value: string | null) {
      setSelectedFormStatus(value);
      form.setFieldValue(`affectedServices.${index}.statusId`, value);
    }

    return (
      <Group>
        <Select
          data={selectServices}
          readOnly={!edit}
          searchable
          label="Service"
          nothingFound="No services found. "
          value={selectedServices}
          onChange={(sel) => setFormService(sel)}
        />

        <Select
          label="Status"
          readOnly={!edit}
          itemComponent={SelectItemDiv}
          data={selectStatuses}
          maxDropdownHeight={400}
          nothingFound="No statuses found"
          value={selectedFormStatus}
          onChange={(sel) => setFormStatus(sel)}
          icon={
            item.statusId ? (
              statusList.find((ssl) => ssl.statusId === item.statusId)?.image
            ) : (
              <IconChevronDown />
            )
          }
          styles={(theme) => ({
            item: {
              // applies styles to selected item
              "&[data-selected]": {
                "&, &:hover": {
                  backgroundColor: "#fff",
                  color: theme.black,
                  border: "2px solid #087f5b",
                  borderRadius: theme.radius.md,
                },
              },

              // applies styles to hovered item (with mouse or keyboard)
              "&[data-hovered]": {},
            },
          })}
        />

        {!item.preLoad && (
          <ActionIcon
            color="red"
            variant="subtle"
            onClick={() => form.removeListItem("affectedServices", index)}
            style={{ marginTop: "25px" }}
          >
            <Trash size={20} />
          </ActionIcon>
        )}
        {/* {form.isTouched(`affectedServices.${index}.statusId`) && (
          <Text>Touched!</Text>
        )} */}
        {!edit && (
          <Menu
            key={serviceId}
            shadow="md"
            width={200}
            opened={menuOpened}
            onChange={setMenuOpened}
          >
            <Menu.Target>
              <Tooltip label="Edit Notification Preferences">
                <ActionIcon
                  size="md"
                  variant="light"
                  radius="xl"
                  style={{ marginTop: "25px" }}
                  color={notificationEnabled ? "orange.7" : "gray.3"}
                >
                  {notificationIcon}
                </ActionIcon>
              </Tooltip>
            </Menu.Target>
            <Menu.Dropdown>
              {notificationEnabled && (
                <Menu.Item
                  icon={<BellOff size={14} />}
                  onClick={() => {
                    changeNotification(
                      service.serviceId,
                      !notificationEnabled,
                      undefined //service.notificationPreferences
                    );
                  }}
                >
                  Disable Notifications
                </Menu.Item>
              )}
              {!notificationEnabled && (
                <Menu.Item
                  icon={<BellRinging size={14} />}
                  onClick={() => {
                    changeNotification(
                      service.serviceId,
                      !notificationEnabled,
                      undefined //service.notificationPreferences
                    );
                  }}
                >
                  Enable Notifications
                </Menu.Item>
              )}
            </Menu.Dropdown>
          </Menu>
        )}
      </Group>
    );
  }

  const affectedServices = form.values["affectedServices"]
    .filter(
      (svc: AffectedServices, index: number, array: AffectedServices[]) => {
        const svcIndex = array.findIndex((s) => s.serviceId === svc.serviceId);
        return index === svcIndex;
      }
    )
    .map((item: AffectedServices, index: number) => (
      <AffectedService item={item} index={index} key={index} />
    ));

  async function submitTimeline() {
    setIsSubmitting(true);
    const createdByUserID = me!.graphUserId;

    //Status Update Timeline
    const priorityLevel = form.values["priorityLevel"];
    const header = form.values["header"];
    const shortDesc = form.values["shortDesc"];
    const desc = form.values["desc"];

    //displayOnBanner TOADD - update statusUpdateTable

    const resolvedByUserId = me!.graphUserId;

    let timelineResponse!: NewStatusUpdateTimelineResponse;
    let statusUpdateResponse!: UpdateStatusUpdateResponse;

    const timelineRequestValues = {
      statusUpdateId: statusUpdateId,
      createdByUserId: createdByUserID,
      priorityLevelId: parseInt(priorityLevel),
      header: header,
      shortDesc: shortDesc,
      description: desc,
      resolved: false,
      overrideAndAlertAll: overrideAndAlertAll,
    };

    const statusUpdateRequestValues = {
      statusUpdateId: statusUpdateId,
      displayOnBanner: displayAsBanner,
    };

    if (!submitAsResolution) {
      timelineResponse = await api
        .put(endpoints.useStatusUpdateTimeline.url, {
          json: {
            ...timelineRequestValues,
          },
          timeout: 30000,
        })
        .json();

      statusUpdateResponse = await api
        .post(`${endpoints.useStatusUpdate.url}/${statusUpdateId}`, {
          json: {
            ...statusUpdateRequestValues,
          },
          timeout: 30000,
        })
        .json();

      if (!statusUpdateResponse.success) {
        setIsSubmitting(false);
        setSubmitError("Error displaying as banner.");
        const timer = setTimeout(() => {
          setSubmitError("");
        }, 3000);
        return () => clearTimeout(timer);
      }

      if (timelineResponse.success) {
        setIsSubmitting(false);
        setTimelineSuccess(true);
        const timer = setTimeout(() => {
          form.setFieldValue("header", "");
          form.setFieldValue("shortDesc", "");
          form.setFieldValue("desc", "");
          form.setFieldValue("description", "");
          form.setFieldValue("priorityLevel", "");
          loadStatusUpdate(statusUpdateId);
          setTimelineSuccess(false);
        }, 4000);
        return () => clearTimeout(timer);
      } else {
        setIsSubmitting(false);
        setSubmitError("Error saving Timeline entry, please try again.");
        const timer = setTimeout(() => {
          setSubmitError("");
        }, 3000);
        return () => clearTimeout(timer);
      }
    } else {
      const response: ResolveStatusUpdateResponse = await api
        .post(`${endpoints.resolveStatusUpdate.url}/${statusUpdateId}`, {
          json: {
            statusUpdateId: statusUpdateId,
            resolvedByUserId: resolvedByUserId,
            resolvedHeader: header,
            resolvedShortDesc: shortDesc,
            resolvedDescription: desc,
          },
          timeout: 30000,
        })
        .json();

      if (response.success) {
        const emailResponse: SendNotificationResponse = await api
          .post(
            `${endpoints.sendNotification.url}/${response.statusUpdateTimelineId}`
          )
          .json();

        if (emailResponse.success) {
          setIsSubmitting(false);
          setAlertIsResolved(true);
          setTimelineSuccess(true);
          const timer = setTimeout(() => {
            form.setFieldValue("header", "");
            form.setFieldValue("shortDesc", "");
            form.setFieldValue("desc", "");
            form.setFieldValue("description", "");
            form.setFieldValue("priorityLevel", "");
            loadStatusUpdate(statusUpdateId);
            setTimelineSuccess(false);
          }, 4000);
          return () => clearTimeout(timer);
        } else {
          setIsSubmitting(false);
          setSubmitError(
            "Error notifying users, please try re-submitting the timeline item."
          );
          const timer = setTimeout(() => {
            setSubmitError("");
          }, 3000);
          return () => clearTimeout(timer);
        }
      } else {
        setIsSubmitting(false);
        setSubmitError(
          "Error resolving alert, please try re-submitting the timeline item."
        );
        const timer = setTimeout(() => {
          setSubmitError("");
        }, 3000);
        return () => clearTimeout(timer);
      }
    }
  }

  async function submitUpdate() {
    setIsSubmittingStatuses(true);
    //Update Status Alert Details
    const suTitle = form.values["statusUpdateTitle"];
    const suDesc = form.values["statusUpdateDescription"];

    const suUpdateResponse: UpdateStatusUpdateResponse = await api
      .post(`${endpoints.useStatusUpdate.url}/${statusUpdateId}`, {
        json: {
          title: suTitle,
          description: suDesc,
        },
        timeout: 30000,
      })
      .json();

    if (suUpdateResponse.success) {
      setIsSubmittingStatuses(false);
      showNotification({
        title: "Success!",
        message: "Status Alert Title and Description Saved",
        color: "green",
      });
    } else {
      setIsSubmittingStatuses(false);
      showNotification({
        title: "Error",
        message: "Error saving Status Alert Title and Description",
        color: "red",
      });
    }

    //Status Services Affected
    // const affectedServices = form.values["affectedServices"];

    const touchedServices: AffectedServices[] = form.values[
      "affectedServices"
    ].filter((af: AffectedServices, index: number) => {
      return form.isTouched(`affectedServices.${index}.statusId`);
    });

    //Submitting All Services Status Updates
    const ssaUpdates = touchedServices.map((as: AffectedServices) => {
      return {
        statusServicesAffectedId: as.statusServicesAffectedId
          ? as.statusServicesAffectedId
          : "",
        statusUpdateId: statusUpdateId,
        active: false,
        serviceId: as.serviceId,
        statusId: as.statusId,
      };
    });

    const ssaUpdateResponses = ssaUpdates.map(async (ssa) => {
      // Creating insert object without Active param
      const { active, ...insertObject } = ssa;

      if (ssa.statusServicesAffectedId !== "") {
        // Creating an object to represent the old row, removing the new status
        const { statusId, ...oldSSARow } = ssa;

        const updateResponse: UpdateStatusServicesAffectedResponse = await api
          .post(
            `${endpoints.useStatusServicesAffected.url}/${ssa.statusServicesAffectedId}`,
            {
              json: {
                ...oldSSARow,
              },
              timeout: 30000,
            }
          )
          .json();

        if (updateResponse.success) {
          const { active, ...insertObject } = ssa;

          const insertResponse: NewStatusServicesAffectedResponse = await api
            .put(endpoints.useStatusServicesAffected.url, {
              json: {
                ...insertObject,
              },
              timeout: 30000,
            })
            .json();

          return insertResponse;
        } else {
          return updateResponse;
        }
      } else {
        const response: NewStatusServicesAffectedResponse = await api
          .put(endpoints.useStatusServicesAffected.url, {
            json: {
              ...insertObject,
            },
            timeout: 30000,
          })
          .json();

        return response;
      }
    });

    Promise.all(ssaUpdateResponses).then((values) => {
      setIsDataLoaded(false);
      setDataLoaded();
      let errResponse: boolean = false;

      values.forEach((ssaR) => {
        if (!ssaR.success) {
          errResponse = true;
        }
      });

      if (!errResponse) {
        showNotification({
          title: "Success!",
          message: "Affected Services Changes Saved",
          color: "green",
        });
      } else {
        showNotification({
          title: "Error",
          message: "Error saving Affected Services changes",
          color: "red",
        });
      }
    });
  }

  type TimelineBoxProps = {
    tl: StatusUpdateTimeline;
    key: number;
  };

  function TimelineBox({ tl }: TimelineBoxProps) {
    const lastUpdated: Date = new Date(tl.updateDateTime + "Z");
    const convertedTime: string = lastUpdated.toLocaleTimeString("en-US", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      timeZone: "America/New_York",
      hour12: true,
    });

    const userName = userList.filter(
      (ul) => tl.createdByUserId === ul.graphUserId
    )[0]?.displayName;

    const [editMode, setEditMode] = useState<boolean>(false);
    const [header, setHeader] = useState<string>(tl.header);
    const [shortDesc, setShortDesc] = useState<string>(tl.shortDesc);
    const [desc, setDesc] = useState<string>(tl.description);

    async function submitUpdate() {
      const response: UpdateStatusUpdateTimelineResponse = await api
        .post(
          `${endpoints.useStatusUpdateTimeline.url}/${tl.statusUpdateTimelineId}`,
          {
            json: {
              header: header,
              shortDesc: shortDesc,
              description: desc,
            },
            timeout: 30000,
          }
        )
        .json();

      if (response.success) {
        setEditMode(false);
        loadStatusUpdate(statusUpdateId);
        showNotification({
          title: "Success!",
          message: "Timeline edits have been saved.",
          color: "green",
        });
      } else {
        showNotification({
          title: "Error",
          message: "Error saving changes: " + response.response,
          color: "red",
        });
      }
    }

    return (
      <>
        {!editMode && (
          <Paper
            p="xs"
            shadow="xs"
            m="xs"
            withBorder
            key={tl.statusUpdateTimelineId}
          >
            <Stack spacing="xs" mb="sm">
              <Group position="apart">
                <Title order={3}>{tl.header}</Title>
                {tl.createdByUserId === me!.graphUserId && edit && (
                  <ActionIcon onClick={() => setEditMode(true)}>
                    <Edit color="gray" size={22} />
                  </ActionIcon>
                )}
              </Group>

              <Text mt={-7} fz="xs" c="dimmed">
                {new Date(convertedTime).toLocaleString()} {" by "} {userName}
              </Text>
            </Stack>

            <Text fz="sm">{tl.shortDesc}</Text>
            <Text fz="sm" mt="sm">
              {tl.description}
            </Text>
          </Paper>
        )}
        {editMode && (
          <Paper
            p="xs"
            shadow="xs"
            m="xs"
            withBorder
            key={tl.statusUpdateTimelineId}
          >
            <TextInput
              label="Header"
              value={header}
              onChange={(e) => setHeader(e.currentTarget.value)}
            />

            <Textarea
              label="Short Description"
              value={shortDesc}
              onChange={(e) => setShortDesc(e.currentTarget.value)}
            />
            <Textarea
              label="Description"
              value={desc}
              onChange={(e) => setDesc(e.currentTarget.value)}
            />

            <Button
              mt="xs"
              compact
              style={{ alignSelf: "right" }}
              onClick={() => submitUpdate()}
            >
              Save Changes
            </Button>
          </Paper>
        )}
      </>
    );
  }

  async function loadAllData() {
    form.reset();
    loadUser();
    loadUserList();
    loadPriorityItems();
    loadStatusUpdate(statusUpdateId);
    loadServices();
  }

  async function setDataLoaded() {
    await loadAllData();
    setIsDataLoaded(true);
  }

  useEffect(() => {
    //if (!isDataLoaded) {
    setDataLoaded();

    //}
    //setIsDataLoaded(false);
  }, [statusUpdateId]);

  return (
    <>
      {fromDash && (
        <Anchor
          onClick={() => {
            setOpenUpdate(false);
            console.log(openUpdate);
          }}
        >
          Back
        </Anchor>
      )}
      {!fromDash && (
        <Anchor component={Link} to={`/active-alerts`}>
          Back
        </Anchor>
      )}

      <form onSubmit={form.onSubmit((values) => submitUpdate())}>
        {edit && (
          <Group align="flex-end">
            <Title order={2}>
              Edit Alert #{statusUpdateId}
              {form.values["statusUpdateTitle"] !== "" &&
                " - " + form.values["statusUpdateTitle"]}
            </Title>

            <Button
              radius="xs"
              compact
              color="orange"
              component={Link}
              to={`/view-alert/${statusUpdateId}`}
            >
              Switch to View
            </Button>
          </Group>
        )}
        {!edit && !userIsTechnician && (
          <Title order={2}>
            View Alert #{statusUpdateId}
            {form.values["statusUpdateTitle"] !== "" &&
              " - " + form.values["statusUpdateTitle"]}
          </Title>
        )}
        {!edit && userIsTechnician && (
          <Group align="flex-end">
            <Title order={2}>
              View Alert #{statusUpdateId}
              {form.values["statusUpdateTitle"] !== "" &&
                " - " + form.values["statusUpdateTitle"]}
            </Title>
            <Button
              radius="xs"
              compact
              color="orange"
              component={Link}
              to={`/edit-alert/${statusUpdateId}`}
            >
              Switch to Edit
            </Button>
          </Group>
        )}
        {!isDataLoaded && (
          <Center>
            <Loader mt="xl" size="xl" />
          </Center>
        )}
        {isDataLoaded && (
          <>
            {form.values["statusUpdateDescription"] !== "" && (
              <>
                <Container fluid className={classes.formHeading} mt="sm">
                  <Title order={3} color="white">
                    Alert Details
                  </Title>
                </Container>
                <Paper p="sm" shadow="xs" withBorder radius="xs">
                  {edit && (
                    <>
                      <TextInput
                        label="Title"
                        {...form.getInputProps("statusUpdateTitle")}
                      />
                      <Textarea
                        label="Description"
                        {...form.getInputProps("statusUpdateDescription")}
                      />
                    </>
                  )}
                  {!edit && (
                    <>
                      <Text fz="lg">
                        {form.values["statusUpdateDescription"]}
                      </Text>
                    </>
                  )}
                </Paper>
              </>
            )}

            {form.values["statusUpdateDescription"] !== "" && (
              <Container fluid className={classes.formSubheading}>
                <Title order={3} color="white">
                  Affected Services
                </Title>
              </Container>
            )}

            {form.values["statusUpdateDescription"] === "" && (
              <Container fluid className={classes.formHeading} mt="xs">
                <Title order={3} color="white">
                  Affected Services
                </Title>
              </Container>
            )}

            <Paper p="sm" shadow="xs" withBorder radius="xs">
              {serviceError && (
                <Text fz="xs" color="red">
                  {form.errors["affectedServices"]}
                </Text>
              )}
              {affectedServices.length > 0 && <>{affectedServices}</>}
              {affectedServices.length < 1 && !isDataLoaded && (
                <Loader size="md" />
              )}
              {edit && (
                <>
                  <Button
                    my="sm"
                    variant="light"
                    color="gray"
                    onClick={() =>
                      form.insertListItem("affectedServices", {
                        statusServicesAffectedId: "",
                        serviceId: "",
                        statusId: "",
                        preLoad: false,
                        key: randomId(),
                      })
                    }
                  >
                    <Plus size={16} /> Add Affected Service
                  </Button>
                  <Box
                    mt="md"
                    sx={{
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <Button
                      loading={isSubmittingStatuses}
                      onClick={() => submitUpdate()}
                    >
                      Save Changes
                    </Button>
                  </Box>
                </>
              )}
            </Paper>

            <Container fluid className={classes.formHeading} mt="md">
              <Title order={3} color="white">
                Timeline
              </Title>
            </Container>

            <Paper p="sm" shadow="xs" withBorder radius="xs">
              {!phone && (
                <Group grow align="flex-start">
                  {edit && (
                    <Stack spacing="xs">
                      <Title order={5} c="dimmed">
                        New Timeline Update
                      </Title>
                      {alertIsResolved && (
                        <Center p="md">
                          <Text fw={600} align="center">
                            {" "}
                            This alert has been marked as resolved. You must
                            create a new alert in order to change the status or
                            send messaging regarding the services impacted in
                            this alert.
                          </Text>
                        </Center>
                      )}
                      {!addNewTimelineCheck && !alertIsResolved && (
                        <Center>
                          <Button
                            mt="sm"
                            onClick={() => {
                              setAddNewTimelineCheck(true);
                            }}
                          >
                            Add New Timeline Update
                          </Button>
                        </Center>
                      )}
                      {addNewTimelineCheck && !alertIsResolved && (
                        <Paper p="xs" shadow="xs" withBorder>
                          {!timelineSuccess && submitError === "" && (
                            <>
                              <TextInput
                                withAsterisk
                                label="Header"
                                description="Enter a header message for the Timeline Item. This will be shown on the dashboard and should be kept to 100 characters or less. "
                                {...form.getInputProps("header")}
                              />

                              <Textarea
                                withAsterisk
                                my="sm"
                                label="Short Description"
                                description="Enter a header message for the Timeline Item. This will be shown on the details screen. This should be kept to 200 characters or less."
                                {...form.getInputProps("shortDesc")}
                              />

                              <Textarea
                                label="Description"
                                description="Enter a header message for the Timeline Item. This will be shown on the details screen."
                                {...form.getInputProps("desc")}
                              />
                              <Box style={{ width: "20%", minWidth: "100px" }}>
                                <Select
                                  mt="sm"
                                  withAsterisk
                                  label="Priority Level"
                                  itemComponent={PriorityItem}
                                  data={priorityList}
                                  maxDropdownHeight={400}
                                  nothingFound="No priority options found"
                                  {...form.getInputProps("priorityLevel")}
                                  styles={(theme) => ({
                                    item: {
                                      // applies styles to selected item
                                      "&[data-selected]": {
                                        "&, &:hover": {
                                          backgroundColor: "#fff",
                                          color: theme.black,
                                          border: "2px solid #087f5b",
                                          borderRadius: theme.radius.md,
                                        },
                                      },

                                      // applies styles to hovered item (with mouse or keyboard)
                                      "&[data-hovered]": {},
                                    },
                                  })}
                                />
                              </Box>
                              <Checkbox
                                mt="sm"
                                label="Submit As Resolution?"
                                checked={submitAsResolution}
                                onChange={(e) =>
                                  setSubmitAsResolution(e.currentTarget.checked)
                                }
                              />
                              {submitAsResolution && (
                                <Alert
                                  icon={<AlertCircle size="1rem" />}
                                  title="Alert!"
                                  color="yellow"
                                  radius="lg"
                                  mt="xs"
                                  mb="xs"
                                >
                                  Submitting a resolution will resolve this
                                  alert for all affected services. Please ensure
                                  the entire issue is resolved before resolving
                                  the alert.
                                </Alert>
                              )}
                              <Checkbox
                                mt="sm"
                                label="Display as Banner?"
                                checked={displayAsBanner}
                                onChange={(e) =>
                                  setDisplayAsBanner(e.currentTarget.checked)
                                }
                              />
                              {displayAsBanner && (
                                <Alert
                                  icon={<AlertCircle size="1rem" />}
                                  title="Alert!"
                                  color="yellow"
                                  radius="lg"
                                  mt="xs"
                                  mb="xs"
                                >
                                  Submitting the timeline update with the
                                  Display as Banner checkbox checked will
                                  display this alert to all users as a banner.
                                  Please ensure this alert is critical and
                                  widespread enough to display to all users.
                                </Alert>
                              )}
                              <Checkbox
                                mt="sm"
                                label="Override and Alert All?"
                                checked={overrideAndAlertAll}
                                onChange={(e) =>
                                  setOverrideAndAlertAll(
                                    e.currentTarget.checked
                                  )
                                }
                              />
                              {overrideAndAlertAll && (
                                <Alert
                                  icon={<AlertCircle size="1rem" />}
                                  title="Alert!"
                                  color="yellow"
                                  radius="lg"
                                  mt="xs"
                                  mb="xs"
                                >
                                  Submitting the timeline update with the
                                  Override and Alert All checkbox checked will
                                  send an update email to all users regardless
                                  of notification settings. Please ensure this
                                  alert is critical enough to send an email to
                                  all users to inform them of the status change.
                                </Alert>
                              )}
                              <Group mt="xs" grow position="apart">
                                <Button
                                  color="gray"
                                  variant="light"
                                  onClick={() => {
                                    setAddNewTimelineCheck(false);
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  onClick={() => submitTimeline()}
                                  loading={isSubmitting}
                                >
                                  Submit
                                </Button>
                              </Group>
                            </>
                          )}
                          {timelineSuccess && submitError === "" && (
                            <Center>
                              <Stack align="center">
                                <Title order={3} c="teal">
                                  Timeline Item Added!
                                </Title>
                                <Loader />
                              </Stack>
                            </Center>
                          )}
                          {submitError !== "" && (
                            <Center>
                              <Title>{submitError}</Title>
                            </Center>
                          )}
                        </Paper>
                      )}
                    </Stack>
                  )}
                  <Box>
                    <Title order={5} c="dimmed">
                      Existing Timeline
                    </Title>
                    {isDataLoaded &&
                      currentTimelineItems.length > 0 &&
                      currentTimelineItems.sort().map((tl) => {
                        return (
                          <TimelineBox
                            tl={tl}
                            key={tl.statusUpdateTimelineId}
                          />
                        );
                      })}
                    {isDataLoaded && currentTimelineItems.length < 1 && (
                      <Center mt="md">
                        <Loader size="lg" />
                      </Center>
                    )}
                  </Box>
                </Group>
              )}
              {phone && (
                <Box>
                  {!addNewTimelineCheck && edit && !alertIsResolved && (
                    <Center>
                      <Button
                        mt="sm"
                        onClick={() => {
                          setAddNewTimelineCheck(true);
                        }}
                      >
                        Add New Timeline Update
                      </Button>
                    </Center>
                  )}
                  {addNewTimelineCheck && edit && !alertIsResolved && (
                    <Paper p="xs" shadow="xs" withBorder>
                      {!timelineSuccess && submitError === "" && (
                        <>
                          <TextInput
                            withAsterisk
                            label="Header"
                            description="Enter a header message for the Timeline Item. This will be shown on the dashboard and should be kept to 100 characters or less. "
                            {...form.getInputProps("header")}
                          />

                          <Textarea
                            withAsterisk
                            my="sm"
                            label="Short Description"
                            description="Enter a header message for the Timeline Item. This will be shown on the details screen. This should be kept to 200 characters or less."
                            {...form.getInputProps("shortDesc")}
                          />

                          <Textarea
                            label="Description"
                            description="Enter a header message for the Timeline Item. This will be shown on the details screen."
                            {...form.getInputProps("desc")}
                          />
                          <Box style={{ width: "20%", minWidth: "100px" }}>
                            <Select
                              mt="sm"
                              withAsterisk
                              label="Priority Level"
                              itemComponent={PriorityItem}
                              data={priorityList}
                              maxDropdownHeight={400}
                              nothingFound="No priority options found"
                              {...form.getInputProps("priorityLevel")}
                              styles={(theme) => ({
                                item: {
                                  // applies styles to selected item
                                  "&[data-selected]": {
                                    "&, &:hover": {
                                      backgroundColor: "#fff",
                                      color: theme.black,
                                      border: "2px solid #087f5b",
                                      borderRadius: theme.radius.md,
                                    },
                                  },

                                  // applies styles to hovered item (with mouse or keyboard)
                                  "&[data-hovered]": {},
                                },
                              })}
                            />
                          </Box>
                          <Checkbox
                            mt="sm"
                            label="Submit As Resolution?"
                            checked={submitAsResolution}
                            onChange={(e) =>
                              setSubmitAsResolution(e.currentTarget.checked)
                            }
                          />
                          <Checkbox
                            mt="sm"
                            label="Display as Banner?"
                            checked={displayAsBanner}
                            onChange={(e) =>
                              setDisplayAsBanner(e.currentTarget.checked)
                            }
                          />
                          {displayAsBanner && (
                            <Alert
                              icon={<AlertCircle size="1rem" />}
                              title="Alert!"
                              color="yellow"
                              radius="lg"
                              mt="xs"
                              mb="xs"
                            >
                              Submitting the timeline update with the Display as
                              Banner checkbox checked will display this alert to
                              all users as a banner. Please ensure this alert is
                              critical and widespread enough to display to all
                              users.
                            </Alert>
                          )}
                          <Checkbox
                            mt="sm"
                            label="Override and Alert All?"
                            checked={overrideAndAlertAll}
                            onChange={(e) =>
                              setOverrideAndAlertAll(e.currentTarget.checked)
                            }
                          />
                          {overrideAndAlertAll && (
                            <Alert
                              icon={<AlertCircle size="1rem" />}
                              title="Alert!"
                              color="yellow"
                              radius="lg"
                              mt="xs"
                              mb="xs"
                            >
                              Submitting the timeline update with the Override
                              and Alert All checkbox checked will send an update
                              email to all users regardless of notification
                              settings. Please ensure this alert is critical
                              enough to send an email to all users to inform
                              them of the status change.
                            </Alert>
                          )}
                          <Group mt="xs" grow position="apart">
                            <Button
                              color="gray"
                              variant="light"
                              onClick={() => {
                                setAddNewTimelineCheck(false);
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={() => submitTimeline()}
                              loading={isSubmitting}
                            >
                              Submit
                            </Button>
                          </Group>
                        </>
                      )}
                      {timelineSuccess && submitError === "" && (
                        <Center>
                          <Stack align="center">
                            <Title order={3} c="teal">
                              Timeline Item Added!
                            </Title>
                            <Loader />
                          </Stack>
                        </Center>
                      )}
                      {submitError !== "" && (
                        <Center>
                          <Title>{submitError}</Title>
                        </Center>
                      )}
                    </Paper>
                  )}
                  <Title order={5} c="dimmed" mt="xs">
                    Existing Timeline
                  </Title>
                  {isDataLoaded &&
                    currentTimelineItems.length > 0 &&
                    currentTimelineItems.sort().map((tl) => {
                      return (
                        <TimelineBox tl={tl} key={tl.statusUpdateTimelineId} />
                      );
                    })}
                  {isDataLoaded && currentTimelineItems.length < 1 && (
                    <Center mt="xs">
                      <Loader size="lg" />
                    </Center>
                  )}
                </Box>
              )}
            </Paper>
          </>
        )}
      </form>
      {/* <Code block>{JSON.stringify(form.values, null, 2)}</Code> */}
    </>
  );
};
