import React, { Dispatch, SetStateAction, forwardRef } from "react";

import { useState, useEffect } from "react";
import {
  Stack,
  Title,
  Center,
  Box,
  Paper,
  Stepper,
  Group,
  Button,
  Select,
  TextInput,
  Textarea,
  Checkbox,
  Loader,
  Text,
  Spoiler,
  Anchor,
  Alert,
  createStyles,
  Flex,
  ScrollArea,
  Divider,
  Container,
  SelectItem,
} from "@mantine/core";

import { useForm } from "@mantine/form";

import { useMediaQuery } from "@mantine/hooks";

import externalApi from "../api";
import endpoints from "../api/endpoints";

import { Link } from "react-router-dom";

import { statusIcons } from "../common/Data";

import {
  CircleCheck,
  AlertTriangle,
  Tool,
  CircleX,
  AlertCircle,
} from "tabler-icons-react";

import {
  SelectData,
  Service,
  NewStatusUpdateTimelineResponse,
  StatusUpdateResponse,
  GraphUser,
  StatusUpdateTimeline,
  StatusServiceAffectedResponse,
  PriorityLevel,
  Site,
  AddNewServiceAlertRequest,
  AddNewServiceAlertResponse,
} from "types";

export const CreateServiceAlert = ({
  serviceId,
  isModal,
  isOpened,
  setIsOpened,
}: {
  serviceId?: number;
  isModal: boolean;
  isOpened?: boolean;
  setIsOpened?: Dispatch<SetStateAction<boolean>>;
}) => {
  // Api data
  const [me, setMe] = useState<GraphUser>();
  const [allServices, setAllServices] = useState<Service[]>([]);
  const [selectServices, setSelectServices] = useState<SelectData[]>([]);
  const [servicesLoaded, setServicesLoaded] = useState(false);
  const [selectedServiceValue, setSelectedServiceValue] = useState<
    string | null
  >(null);
  const [selectedService, setSelectedService] = useState<Service>();
  const [connectedResourcesList, setConnectedResourcesList] = useState<
    string[]
  >([]);
  const [connectedResourcesLoaded, setConnectedResourcesLoaded] =
    useState<boolean>(false);
  const siteError = false;
  const [existingAlertError, setExistingAlertError] = useState<boolean>(false);
  //const [opened, setOpened] = useState(false);
  const opened = false;
  const [foundServiceAlert, setFoundServiceAlert] =
    useState<StatusUpdateResponse>();
  const [foundAlertFlag, setFoundAlertFlag] = useState<boolean>(false);
  const [findingAlertLoader, setFindingAlertLoader] = useState<boolean>(false);
  const [addToExistingAlert, setAddToExistingAlert] = useState<boolean>(false);
  const [activeStatusUpdatesList, setActiveStatusUpdateList] = useState<
    StatusUpdateResponse[]
  >([]);
  const [timelineItems, setTimelineItems] = useState<StatusUpdateTimeline[]>(
    []
  );
  const [statusServices, setStatusServices] = useState<
    StatusServiceAffectedResponse[]
  >([]);
  const [priorityList, setPriorityList] = useState<SelectItem[]>([]);
  const [submitError, setSubmitError] = useState<boolean>(false);
  const [submitErrorDetails, setSubmitErrorDetails] = useState<string>("");
//   const [submitErrorInstructions, setSubmitErrorInstructions] =
//     useState<string>("");

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const statusList = [
    {
      image: <CircleCheck color="green" />,
      label: "Available",
      value: "2",
      statusId: 2,
    },
    {
      image: <AlertTriangle color="orange" />,
      label: "Issue",
      value: "4",
      statusId: 4,
    },
    {
      image: <CircleX color="red" />,
      label: "Outage",
      value: "5",
      statusId: 5,
    },
    {
      image: <Tool color="blue" />,
      label: "Maintenance",
      value: "3",
      statusId: 3,
    },
  ];

  const useStyles = createStyles(
    (
      theme,
      { opened, siteError }: { opened: boolean; siteError: boolean }
    ) => ({
      control: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: `${theme.spacing.xs} ${theme.spacing.md}`,
        borderRadius: theme.radius.sm,
        borderSpacing: theme.spacing.xs,
        border: siteError
          ? `1px solid ${theme.colors.red[7]}`
          : `1px solid ${theme.colors.gray[3]}`,
        transition: "background-color 150ms ease",
        backgroundColor:
          theme.colorScheme === "dark"
            ? theme.colors.dark[opened ? 5 : 6]
            : opened
            ? theme.colors.gray[0]
            : theme.white,

        "&:hover": {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[5]
              : theme.colors.gray[0],
        },
      },

      label: {
        fontWeight: 500,
        fontSize: theme.fontSizes.sm,
      },

      icon: {
        transition: "transform 150ms ease",
        transform: opened ? "rotate(180deg)" : "rotate(0deg)",
      },
      formHeading: {
        backgroundColor: theme.fn.variant({
          variant: "filled",
          color: theme.primaryColor,
        }).background,
        borderBottom: 0,
        padding: theme.spacing.sm,
      },
    })
  );

  const { classes } = useStyles({ opened, siteError });

  interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
    image: string;
    label: string;
    statusId: number;
  }

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ image, label, statusId, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          {image}

          <div>
            <Text size="sm">{label}</Text>
          </div>
        </Group>
      </div>
    )
  );

  interface PriorityProps extends React.ComponentPropsWithoutRef<"div"> {
    label: string;
    description: string;
  }

  const PriorityItem = forwardRef<HTMLDivElement, PriorityProps>(
    ({ label, description, ...others }: PriorityProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text size="sm" fw={500}>
              {label}
            </Text>
            <Text size="xs" opacity={0.65}>
              {description}
            </Text>
          </div>
        </Group>
      </div>
    )
  );

  const form = useForm<any>({
    initialValues: {
      serviceId: "",
      statusUpdateTitle: "",
      statusUpdateDescription: "",
      header: "",
      shortDesc: "",
      desc: "",
      priorityLevel: "",
      displayOnBanner: false,
      override: false,
    },
    validate: (values) => {
      if (active === 0) {
        return {
          serviceId:
            values.serviceId === "" ? "You must select a service" : null,
        };
      }

      if (active === 1) {
        return {
          header:
            values.header.trim().length < 1
              ? "You must include a header message."
              : null,
          shortDesc:
            values.shortDesc.trim().length < 1
              ? "You must include a short description."
              : values.shortDesc.trim().length > 300
              ? "Short Description must be 300 characters or less"
              : null,
          statusUpdateTitle:
            values.statusUpdateTitle.trim().length < 1
              ? "You must include a title."
              : values.statusUpdateTitle.trim().length > 200
              ? "Title must be 200 characters or less"
              : null,
          statusUpdateDescription:
            values.statusUpdateDescription.trim().length < 1
              ? "You must include an alert description."
              : values.statusUpdateDescription.trim().length > 3000
              ? "Description must be 3000 characters or less"
              : null,
        };
      }

      return {};
    },
  });

  async function loadUser() {
    const user: GraphUser = await externalApi.get(endpoints.getMe.url).json();
    setMe(user);
  }

  async function loadServices() {
    const services: Service[] = await externalApi
      .get(endpoints.getServiceList.url)
      .json();

    setAllServices(services);

    const mapService: SelectData[] = services.map((s) => {
      return {
        key: s.serviceId,
        value: s.serviceName,
        label: s.serviceName,
      };
    });

    setSelectServices(mapService);

    if (serviceId !== undefined) {
      const propService = mapService.filter((ms) => ms.key === serviceId)[0]
        ?.value;
      const serv = services.filter((ser) => ser.serviceName === propService)[0];

      setSelectedServiceValue(propService);
      setSelectedService(serv);

      if (serv.connectedResources !== "" && serv.connectedResources !== null) {
        const connectedResourceIds = serv.connectedResources.split(",");

        const connectedResourceNames = connectedResourceIds.map(
          async (crId: string) => {
            const service: Service = await externalApi
              .get(`${endpoints.useService.url}/${crId}`)
              .json();

            return service.serviceName;
          }
        );

        Promise.all(connectedResourceNames)
          .then((values) => {
            setConnectedResourcesList(values);
          })
          .then(() => {
            setConnectedResourcesLoaded(true);
          });
      }
    }
  }

  async function loadPriorityItems() {
    const priorities: PriorityLevel[] = await externalApi
      .get(`${endpoints.getPriorityList.url}`)
      .json();

    const mapPriority: SelectItem[] = priorities.map((p) => {
      return {
        key: p.priorityLevelId,
        value: p.priorityLevelId.toString(),
        label: p.priorityLevelName,
        description: p.priorityLevelDescription,
      };
    });

    setPriorityList(mapPriority);
  }

  async function loadSites() {
    const siteList: Site[] = await externalApi
      .get(endpoints.getSiteList.url)
      .json();

    siteList.forEach((si) => {
      form.insertListItem("siteDetails", {
        siteName: si.siteName,
        siteStatus: 0,
        siteStatusName: "Unaffected",
      });
    });
  }

  function pickService(serviceValue: string | null) {
    setSelectedServiceValue(serviceValue);

    const serv = allServices.filter(
      (ser) => ser.serviceName === serviceValue
    )[0];
    form.setFieldValue("serviceId", serv.serviceId.toString());

    setSelectedService(serv);
  }

  function setServiceId(serviceNumber: number | undefined) {
    if (serviceNumber !== undefined) {
      const serviceId: string | null = serviceNumber.toString();
      form.setFieldValue("serviceId", serviceId);
    }
  }

  async function submitUpdate() {
    setIsSubmitting(true);
    //Status Update
    const createdByUserID = me!.graphUserId;
    const displayOnBanner = form.values["displayOnBanner"];
    const statusUpdateTitle = form.values["statusUpdateTitle"];
    const statusUpdateDescription = form.values["statusUpdateDescription"];

    //Status Update Timeline
    const priorityLevel = form.values["priorityLevel"];

    const header = form.values["header"];
    const shortDesc = form.values["shortDesc"];
    const desc = form.values["desc"];
    const override = form.values["override"];

    const serviceId = parseInt(form.values["serviceId"]);
    const statusId = parseInt(form.values["statusId"]);

    const newServiceAlert: AddNewServiceAlertRequest = {
      statusUpdate: {
        createdByUserId: createdByUserID,
        title: statusUpdateTitle,
        description: statusUpdateDescription,
        displayOnBanner: displayOnBanner,
        createDateTime: undefined,
        startDateTime: undefined,
        resolved: undefined,
        active: undefined,
      },
      timeline: {
        priorityLevelId: parseInt(priorityLevel),
        header: header,
        shortDesc: shortDesc,
        description: desc,
        resolved: false,
        overrideAndAlertAll: override,
        updateDateTime: undefined,
        active: undefined,
      },
      affectedServices: [
        {
          serviceId: serviceId,
          statusId: statusId,
          serviceDetails: undefined,
          createdDateTime: undefined,
          active: undefined,
        },
      ],
      sendEmails: undefined
    };

    setExistingAlertError(false);

    const response: AddNewServiceAlertResponse = await externalApi
      .put(endpoints.addNewServiceAlert.url, {
        json: {
          ...newServiceAlert,
        },
        timeout: 30000,
      })
      .json();

    if (!response.success) {
      setIsSubmitting(false);
      setSubmitError(true);
      setSubmitErrorDetails(
        "Error submitting service alert. - " + response.response
      );
    }

    setIsSubmitting(false);
    nextStep();
  }

  async function submitToExistingAlert(statusUpdateId: number) {
    //Status Update
    const createdByUserID = me!.graphUserId;
    //const displayOnBanner = form.values["displayOnBanner"];

    //Status Update Timeline
    const priorityLevel = form.values["priorityLevel"];
    const header = form.values["header"];
    const shortDesc = form.values["shortDesc"];
    const desc = form.values["desc"];
    const override = form.values["override"];

    const serviceId = parseInt(form.values["serviceId"]);
    const statusId = parseInt(form.values["statusId"]);

    const timelineRequestValues = {
      statusUpdateId: statusUpdateId,
      createdByUserId: createdByUserID,
      priorityLevelId: parseInt(priorityLevel),
      header: header,
      shortDesc: shortDesc,
      description: desc,
      resolved: false,
      overrideAndAlertAll: override,
    };

    const timelineResponse: NewStatusUpdateTimelineResponse = await externalApi
      .put(endpoints.useStatusUpdateTimeline.url, {
        json: {
          ...timelineRequestValues,
        },
        timeout: 30000,
      })
      .json();

    if (timelineResponse.success) {
      await externalApi
        .post(
          `${endpoints.sendNotification.url}/${timelineResponse.statusUpdateTimelineId}`
        )
        .json();
    }

    await externalApi
      .put(endpoints.useStatusServicesAffected.url, {
        json: {
          statusUpdateId: statusUpdateId,
          serviceId: serviceId,
          statusId: statusId,
        },
        timeout: 30000,
      })
      .json();

    nextStep();
  }

  async function findExistingActiveAlert() {
    setFindingAlertLoader(true);
    if (selectedService) {
      const statusUpdates: StatusUpdateResponse[] = await externalApi
        .get(
          `${endpoints.getStatusUpdateListByService.url}/${selectedService.serviceId}/true`
        )
        .json();

      const activeUpdates: StatusUpdateResponse[] = statusUpdates.filter(
        (su: StatusUpdateResponse) => su.resolved === false
      );

      if (activeUpdates.length > 0) {
        setFoundServiceAlert(activeUpdates[0]);
        setFoundAlertFlag(true);
      }
    }
    setFindingAlertLoader(false);
  }

  async function loadStatusUpdates() {
    const statusUpdatePull: StatusUpdateResponse[] = await externalApi
      .get(`${endpoints.getStatusUpdateList.url}`)
      .json();

    const activeUpdates = statusUpdatePull.filter(
      (sp: StatusUpdateResponse) => sp.resolved === false && sp.active === true
    );

    setActiveStatusUpdateList(activeUpdates);

    const statusTimelinePull: StatusUpdateTimeline[] = await externalApi
      .get(`${endpoints.getStatusUpdateTimelineList.url}`)
      .json();

    setTimelineItems(statusTimelinePull);

    const statusServicesPull: StatusServiceAffectedResponse[] =
      await externalApi
        .get(`${endpoints.getStatusServicesAffectedList.url}`)
        .json();

    setStatusServices(statusServicesPull);
  }

  function existingAlert(statusUpdate: StatusUpdateResponse) {
    const lastUpdated = timelineItems
      .filter((tl) => tl.statusUpdateId === statusUpdate.statusUpdateId)
      .sort()[0]?.updateDateTime;
    const affectedServices = statusServices.filter(
      (ss) => ss.statusUpdateId === statusUpdate.statusUpdateId
    );

    return (
      <>
        <Group position="apart" mb="sm" key={statusUpdate.statusUpdateId}>
          <Stack spacing="xs">
            <Group>
              <Text fz="sm" fw={500}>
                Stats Update #{statusUpdate.statusUpdateId}
                {statusUpdate.title !== "" && " - " + statusUpdate.title}
              </Text>
              <Text c="dimmed" fz="sm" ml={-5}>
                {" "}
                -{" "}
                {new Date(lastUpdated).toLocaleDateString() +
                  " - " +
                  new Date(lastUpdated).toLocaleTimeString()}{" "}
              </Text>
            </Group>
            <Text fz="sm">Affected Services: </Text>
            {affectedServices.map((as: StatusServiceAffectedResponse) => {
              const recentStatusIcon = statusIcons.find((e) => {
                return e.statusId === as.statusId;
              })?.icon;

              return (
                <Group key={as.serviceId}>
                  {recentStatusIcon}
                  <Text fz="sm">{as.service?.serviceName}</Text>
                </Group>
              );
            })}
          </Stack>
          <Button
            compact
            mr={3}
            onClick={() => submitToExistingAlert(statusUpdate.statusUpdateId)}
          >
            Add to Alert
          </Button>
        </Group>
        <Divider my="sm" />
      </>
    );
  }

  // Stepper
  const [active, setActive] = useState(0);

  const nextStep = () => {
    setActive((current) => {
      if (form.validate().hasErrors) {
        return current;
      }

      if (current === 0) {
        findExistingActiveAlert();
      }

      return current < 4 ? current + 1 : current;
    });
  };

  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  // Media

  const mobile = useMediaQuery("(max-width: 767px)");

  useEffect(() => {
    setServiceId(serviceId);
    loadUser();
    loadServices();
    loadSites();
    loadStatusUpdates();
    loadPriorityItems();
    setServicesLoaded(true);
  }, []);

  return (
    <Box>
      <form>
        <Stepper
          active={active}
          onStepClick={setActive}
          //breakpoint="sm"
          size="sm"
          styles={
            mobile
              ? {
                  stepBody: {
                    display: "none",
                  },

                  step: {
                    padding: 0,
                  },

                  stepIcon: {
                    borderWidth: 4,
                  },

                  separator: {
                    marginLeft: -2,
                    marginRight: -2,
                    height: 4,
                  },
                }
              : undefined
          }
          allowNextStepsSelect={false}
        >
          <Stepper.Step label="Service">
            <Paper p="sm" shadow="xs" withBorder>
              <Group position="apart">
                <Title order={2} color="gray.4" ml="sm">
                  Service
                </Title>
                {isModal && serviceId !== undefined && (
                  <Anchor component={Link} to={`/service-alert/${serviceId}`}>
                    <Text fz="xs">View in Service Alert page</Text>
                  </Anchor>
                )}
                {isModal && serviceId === undefined && (
                  <Anchor component={Link} to={`/new-alert`}>
                    <Text fz="xs">View in Service Alert page</Text>
                  </Anchor>
                )}
              </Group>

              {servicesLoaded && (
                <Center mt="sm">
                  <Box sx={{ width: "50%" }}>
                    {serviceId !== undefined && (
                      <Select
                        data={selectServices}
                        placeholder="Select a Service"
                        error={form.errors["serviceId"]}
                        searchable
                        nothingFound="No services found"
                        disabled
                        value={selectedServiceValue}
                        onChange={(sel) => {
                          pickService(sel);
                        }}
                      />
                    )}
                    {serviceId === undefined && (
                      <Select
                        data={selectServices}
                        error={form.errors["serviceId"]}
                        placeholder="Select a Service"
                        searchable
                        nothingFound="No services found"
                        value={selectedServiceValue}
                        onChange={(sel) => {
                          pickService(sel);
                        }}
                      />
                    )}
                  </Box>
                </Center>
              )}
              {!servicesLoaded && (
                <Center>
                  <Loader />
                </Center>
              )}
              {selectedServiceValue !== null && (
                <Box mt="md">
                  <Center>
                    <Stack
                      spacing={2}
                      justify="flex-start"
                      sx={{ width: "50%" }}
                    >
                      <Text fz="xs" color="dimmed">
                        Service Details
                      </Text>
                      <Paper p="xs" withBorder>
                        <Group align="flex-start" grow>
                          <Stack spacing="xs">
                            <Text fz="xs" c="dimmed">
                              Service Description
                            </Text>
                            <Spoiler
                              maxHeight={120}
                              showLabel="Show more"
                              hideLabel="Show less"
                            >
                              <Text mt={-4} fz="md">
                                {selectedService?.serviceDescription}
                              </Text>
                            </Spoiler>
                          </Stack>
                          <Stack spacing="sm">
                            <Stack spacing="xs">
                              <Text fz="xs" c="dimmed">
                                Launch Date
                              </Text>
                              <Text mt={-4}>
                                {new Date(
                                  selectedService?.launchDate ?? new Date()
                                ).toLocaleDateString()}
                              </Text>
                            </Stack>
                          </Stack>
                        </Group>
                        <Group align="flex-start" grow mt="sm">
                          <Stack spacing="xs">
                            <Text fz="xs" c="dimmed">
                              Connected Resources
                            </Text>
                            {connectedResourcesLoaded &&
                              connectedResourcesList.length > 0 && (
                                <Text mt={-4} fw={500} td="underline">
                                  {connectedResourcesList.map(
                                    (cr: string, index: number) => {
                                      return index ===
                                        connectedResourcesList.length - 1
                                        ? cr
                                        : cr + ", ";
                                    }
                                  )}
                                </Text>
                              )}
                            {!connectedResourcesLoaded && (
                              <Text mt={-4} fz="sm" c="dimmed">
                                No connected resources found.
                              </Text>
                            )}
                          </Stack>
                          <Stack spacing="xs">
                            <Text fz="xs" c="dimmed">
                              Key Stakeholders
                            </Text>
                            <Text mt={-4}>{selectedService?.stakeholders}</Text>
                          </Stack>
                        </Group>
                      </Paper>
                    </Stack>
                  </Center>
                </Box>
              )}
            </Paper>
          </Stepper.Step>
          <Stepper.Step label="Alert Details">
            {findingAlertLoader && (
              <Center>
                <Loader />
              </Center>
            )}
            {foundAlertFlag && !findingAlertLoader && (
              <Paper withBorder p="sm">
                <Stack spacing="xs" align="center">
                  <Stack align="center">
                    <Title order={3}>Existing Alert Found</Title>
                    <Text>
                      An existing, active alert has been found for this service.
                    </Text>
                  </Stack>

                  <Text align="center">
                    Please make any and all updates to the existing Alert
                    impacting this Service.
                  </Text>

                  <Group mt="sm">
                    <Button
                      color="blue"
                      component={Link}
                      to={`/edit-alert/${foundServiceAlert?.statusUpdateId}`}
                    >
                      View Alert
                    </Button>
                  </Group>
                </Stack>
              </Paper>
            )}
            {!foundAlertFlag && !findingAlertLoader && (
              <Paper withBorder>
                <Box>
                  <Container fluid className={classes.formHeading}>
                    <Title order={4} c="white">
                      Alert Details
                    </Title>
                  </Container>
                  <Box p="sm">
                    <TextInput
                      label="Title"
                      withAsterisk
                      {...form.getInputProps("statusUpdateTitle")}
                    />
                    <Textarea
                      withAsterisk
                      label="Alert Description"
                      {...form.getInputProps("statusUpdateDescription")}
                    />
                    <Checkbox
                      mt="xs"
                      label="Display as Banner?"
                      size="md"
                      {...form.getInputProps("displayOnBanner")}
                    />
                    {form.values["displayOnBanner"] && (
                      <Alert
                        radius="md"
                        title="Banner Alert Information"
                        color="orange"
                        my="sm"
                        icon={<AlertCircle size={22} />}
                      >
                        Please note that a banner message will be shown to the
                        entire organization at the top of the Service Health
                        page, as well as the Service Desk Hub page.
                      </Alert>
                    )}
                    <Checkbox
                      label="Override User Notification Preferences?"
                      size="md"
                      {...form.getInputProps("override")}
                    />
                    {form.values["override"] && (
                      <Alert
                        color="yellow"
                        my="sm"
                        radius="lg"
                        title="Warning"
                        icon={<AlertCircle size={22} />}
                      >
                        <Stack align="center">
                          <Text>
                            Overriding Notification preferences should only be
                            used in the event of a critical issue that would
                            impact the organization. This will alert all email
                            users regardless of their notification preferences.{" "}
                          </Text>
                          <Text fw={700}>
                            Please ensure this issue is critical enough for this
                            type of communication before hitting submit.
                          </Text>
                        </Stack>
                      </Alert>
                    )}
                  </Box>
                  <Container fluid className={classes.formHeading}>
                    <Title order={4} c="white">
                      Service Information
                    </Title>
                  </Container>
                  <Box p="sm">
                    <Select
                      label="Current Status"
                      withAsterisk
                      data={statusList}
                      itemComponent={SelectItem}
                      maxDropdownHeight={400}
                      nothingFound="No statuses found"
                      {...form.getInputProps("statusId")}
                      icon={
                        form.values["statusId"] ? (
                          statusList.find(
                            (ssl) =>
                              ssl.statusId === parseInt(form.values["statusId"])
                          )?.image
                        ) : (
                          <></>
                        )
                      }
                      styles={(theme) => ({
                        item: {
                          // applies styles to selected item
                          "&[data-selected]": {
                            "&, &:hover": {
                              backgroundColor: "#fff",
                              color: theme.black,
                              border: "2px solid #087f5b",
                              borderRadius: theme.radius.md,
                            },
                          },

                          // applies styles to hovered item (with mouse or keyboard)
                          "&[data-hovered]": {},
                        },
                      })}
                    />
                  </Box>
                  <Container fluid className={classes.formHeading}>
                    <Title order={4} c="white">
                      Initial Timeline Item
                    </Title>
                  </Container>
                  <Box p="sm">
                    <TextInput
                      withAsterisk
                      label="Timeline Item Header"
                      {...form.getInputProps("header")}
                    />
                    <Textarea
                      withAsterisk
                      mt="xs"
                      label="Timeline Item Short Description"
                      description="This will be used on the Dashboard, kept to 200 characters or less."
                      {...form.getInputProps("shortDesc")}
                    />
                    <Textarea
                      mt="xs"
                      label="Timeline Item Full Description"
                      description="This will be used on the Service Details page, leave a full description of the status and history here."
                      {...form.getInputProps("desc")}
                    />
                    <Select
                      withAsterisk
                      mt="sm"
                      label="Priority Level"
                      itemComponent={PriorityItem}
                      data={priorityList}
                      maxDropdownHeight={400}
                      nothingFound="No priority options found"
                      {...form.getInputProps("priorityLevel")}
                      styles={(theme) => ({
                        item: {
                          // applies styles to selected item
                          "&[data-selected]": {
                            "&, &:hover": {
                              backgroundColor: "#fff",
                              color: theme.black,
                              border: "2px solid #087f5b",
                              borderRadius: theme.radius.md,
                            },
                          },

                          // applies styles to hovered item (with mouse or keyboard)
                          "&[data-hovered]": {},
                        },
                      })}
                    />
                  </Box>
                </Box>
              </Paper>
            )}
          </Stepper.Step>
          <Stepper.Step label="Finalize">
            <Paper withBorder p="sm">
              <Title order={3}>Finalize New Alert</Title>
              <Center my="sm">
                <Paper
                  p="md"
                  shadow="xs"
                  withBorder
                  style={{ maxWidth: "60%", minWidth: "300px" }}
                >
                  <Flex direction="column" align="center" gap="xs">
                    <Text fw={700}>{form.values["statusUpdateTitle"]}</Text>
                    <Group spacing="xs">
                      {
                        statusList.filter(
                          (sl) =>
                            sl.statusId === parseInt(form.values["statusId"])
                        )[0]?.image
                      }
                      <Text>
                        {
                          statusList.filter(
                            (sl) =>
                              sl.statusId === parseInt(form.values["statusId"])
                          )[0]?.label
                        }
                      </Text>
                    </Group>
                    <Text fz="sm">
                      {form.values["statusUpdateDescription"]}
                    </Text>
                    <Title order={5}>Timeline Item</Title>
                    <Paper withBorder p="sm">
                      <Text fw={500}>{form.values["header"]}</Text>
                      <Text c="dimmed" fz="sm">
                        Priority Level: {form.values["priorityLevel"]}
                      </Text>
                      <Text>{form.values["shortDesc"]}</Text>
                      <Text>{form.values["desc"]}</Text>
                    </Paper>
                  </Flex>
                </Paper>
              </Center>
              {form.values["displayOnBanner"] && (
                <>
                  <Text size="md">
                    This is how the banner message will appear at the top of the
                    Service Health and Service Desk Hub pages.
                  </Text>
                  <Alert
                    mt="xs"
                    title={form.values["statusUpdateTitle"]}
                    color="red"
                    icon={<AlertCircle size={22} />}
                    radius="sm"
                  >
                    {form.values["statusUpdateDescription"]}
                  </Alert>
                </>
              )}
              <Center>
                <Checkbox
                  label="Add to existing Status Alert?"
                  checked={addToExistingAlert}
                  onChange={(event) =>
                    setAddToExistingAlert(event.currentTarget.checked)
                  }
                />
              </Center>

              {addToExistingAlert && (
                <Paper withBorder p="sm">
                  <ScrollArea h={250} scrollbarSize={4} offsetScrollbars>
                    {activeStatusUpdatesList.map((asu) => existingAlert(asu))}
                  </ScrollArea>
                </Paper>
              )}
            </Paper>
          </Stepper.Step>
        </Stepper>
        {active === 3 && !existingAlertError && (
          <Center mt="xl">
            <Stack justify="space-between">
              <Title order={3}>Service Event Submitted!</Title>
              {/* {isModal && (
                <Button
                  variant="light"
                  onClick={() => {
                    setIsOpened!(false);
                  }}
                >
                  Click here to return to the Dashboard
                </Button>
              )} */}
              {!isModal && (
                <Button variant="light" component={Link} to="/">
                  Click here to return to the Dashboard
                </Button>
              )}
            </Stack>
          </Center>
        )}

        {active === 3 && submitError && (
          <Center mt="xl">
            <Stack
              justify="space-between"
              align="center"
              style={{ width: "80%" }}
            >
              <Title order={3} c="red">
                Service Event Submission Failed
              </Title>
              <Text fw={500}>{submitErrorDetails}</Text>
              {/* <Text align="center">{submitErrorInstructions}</Text> */}
              {/* {isModal && (
               <Button
                 variant="light"
                 onClick={() => {
                   setIsOpened!(false);
                   //renavRefresh!();
                 }}
               >
                 Click here to return to the Dashboard
               </Button>
             )} */}
              {!isModal && (
                <Button variant="light" component={Link} to="/">
                  Click here to return to the Dashboard
                </Button>
              )}
            </Stack>
          </Center>
        )}

        {active === 3 && existingAlertError && (
          <Center mt="xl">
            <Stack
              justify="space-between"
              align="center"
              style={{ width: "80%" }}
            >
              <Title order={3} c="red">
                Service Event Submission Failed
              </Title>
              <Text fw={500}>
                An existing service alert was found that has not yet been
                resolved.
              </Text>
              <Text align="center">
                Please either update the existing alert with new information, or
                resolve the previous alert before submitting a new one.
              </Text>
              {/* {isModal && (
                <Button
                  variant="light"
                  onClick={() => {
                    setIsOpened!(false);
                    //renavRefresh!();
                  }}
                >
                  Click here to return to the Dashboard
                </Button>
              )} */}
              {!isModal && (
                <Button variant="light" component={Link} to="/">
                  Click here to return to the Dashboard
                </Button>
              )}
            </Stack>
          </Center>
        )}
        {!foundAlertFlag && !addToExistingAlert && (
          <Group position="center" mt="xl">
            {active !== 0 && active !== 3 && (
              <Button variant="outline" onClick={prevStep}>
                Back
              </Button>
            )}
            {active !== 2 && active !== 3 && (
              <Button onClick={nextStep}>Next</Button>
            )}
            {active === 2 && (
              <Button loading={isSubmitting} onClick={submitUpdate} uppercase>
                Submit
              </Button>
            )}
          </Group>
        )}
        {foundAlertFlag && active === 0 && (
          <Group position="center" mt="xl">
            <Button onClick={nextStep}>Next</Button>
          </Group>
        )}
      </form>
      {/* <Code block>{JSON.stringify(form.values, null, 2)}</Code>
      <Code block>{JSON.stringify(form.errors, null, 2)}</Code> */}
    </Box>
  );
};
