import { Group, Avatar, Text, createStyles, Box } from "@mantine/core";

import { Coffee } from "tabler-icons-react";

const useStyles = createStyles((theme) => ({
  user: {
    display: "block",
    width: "100%",
    //padding: theme.spacing.md,
    color: theme.colors.dark[0],
  },
}));

interface UserProfileProps {
  firstName: string;
  lastName: string;
  email: string;
  image?: string;
  tech?: boolean;
  icon?: React.ReactNode;
}

export function UserProfile({
  image,
  firstName,
  lastName,
  email,
  tech,
  icon,
  ...others
}: UserProfileProps) {
  const { classes } = useStyles();

  return (
    <Box className={classes.user} {...others}>
      <Group position="apart" spacing="xl">
        <Group>
          <Avatar src={image} size={36} radius="sm">
            {firstName[0]}
            {lastName[0]}
          </Avatar>

          <div style={{ flex: 1 }}>
            <Text size="md" weight={500}>
              {lastName}, {firstName}
            </Text>

            <Text color="dimmed" size="sm">
              {email}
            </Text>
          </div>
        </Group>

        {tech && <Coffee size={24} color="gray" />}
      </Group>
    </Box>
  );
}
