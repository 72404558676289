import { useState, useEffect, SetStateAction, Dispatch } from "react";

import {
  Modal,
  Checkbox,
  Button,
  Center,
  Box,
  LoadingOverlay,
} from "@mantine/core";

import { showNotification } from "@mantine/notifications";

import {
  Service,
  UserNotificationPreference,
  AddUserServiceNotificationPreferenceRequest,
  AddUserServiceNotificationPreferenceResponse,
  UpdateUserServiceNotificationPreferenceResponse
} from "types";

import api from "../../api";
import endpoints from "../../api/endpoints";

type EmailNotificationModalProps = {
  opened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
  selectedService: Service;
  graphUserId: number;
  notificationPreference: UserNotificationPreference[];
};

export const EmailNotificiationModal = ({
  opened,
  setOpened,
  selectedService,
  graphUserId,
  notificationPreference,
}: EmailNotificationModalProps) => {
  const [emailEnabled, setEmailEnabled] = useState(false);

//   const [siteList, setSiteList] = useState<CheckOption[]>([]);
//   const [selectedSites, setSelectedSites] = useState<string[]>([]);

//   const [defaultSelectedSites, setDefaultSelectedSites] = useState<string[]>(
//     []
//   );

  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);

  async function saveChanges() {
    const request: AddUserServiceNotificationPreferenceRequest = {
        graphUserId: graphUserId,
        serviceId: selectedService.serviceId,
        emailEnabled: emailEnabled,
    };

    const response: AddUserServiceNotificationPreferenceResponse = await api
      .put(`${endpoints.useUserServiceNotificationPreferencesFavourites.url}`, {
        json: {
            ...request
        },
        timeout: 30000,
      })
      .json();

    if (response.success === false) {
      const rePost: UpdateUserServiceNotificationPreferenceResponse = await api
        .post(
          `${
            endpoints.useUserServiceNotificationPreferencesFavourites.url
          }/${graphUserId}/${selectedService.serviceId}`,
          {
            json: {
              graphUserId: graphUserId,
              serviceId: selectedService.serviceId,
              emailEnabled: emailEnabled,
              active: true,
            },
            timeout: 30000,
          }
        )
        .json();

      if (rePost.success === true) {
        setOpened(false);
        showNotification({
          title: "Success",
          color: "green",
          message: "Notification preferences saved successfully.",
          styles: (theme) => ({
            root: {
              backgroundColor: theme.colors.green[6],
              borderColor: theme.colors.green[6],

              "&::before": { backgroundColor: theme.white },
            },

            title: { color: theme.white },
            description: { color: theme.white },
            closeButton: {
              color: theme.white,
              "&:hover": { backgroundColor: theme.colors.green[7] },
            },
          }),
        });
      } else {
        showNotification({
          title: "Error Saving Preferences",
          color: "red",
          autoClose: false,
          message:
            "There was an error saving your notification preferences. Please try again and if issues persist, please contact the IT Service Desk.",
          styles: (theme) => ({
            root: {
              backgroundColor: theme.colors.red[6],
              borderColor: theme.colors.red[6],

              "&::before": { backgroundColor: theme.white },
            },

            title: { color: theme.white },
            description: { color: theme.white },
            closeButton: {
              color: theme.white,
              "&:hover": { backgroundColor: theme.colors.red[7] },
            },
          }),
        });
      }
    } else {
      setOpened(false);
      showNotification({
        title: "Success",
        color: "green",
        message: "Notification preferences saved successfully.",
        styles: (theme) => ({
          root: {
            backgroundColor: theme.colors.green[6],
            borderColor: theme.colors.green[6],

            "&::before": { backgroundColor: theme.white },
          },

          title: { color: theme.white },
          description: { color: theme.white },
          closeButton: {
            color: theme.white,
            "&:hover": { backgroundColor: theme.colors.green[7] },
          },
        }),
      });
    }

    // if (emailEnabled) {
    //   selectedSites.map(async (site) => {
    //     //console.log(site);
    //     //console.log(selectedService);

    //     if (defaultSelectedSites.includes(site)) {
    //       const response = await api
    //         .post(
    //           `${endpoints.useUserServiceNotificationPreferencesFavourites.url}/${graphUserId}/${selectedService.serviceId}/${site}`,
    //           {
    //             json: {
    //               graphUserId: graphUserId,
    //               serviceId: selectedService.serviceId,
    //               siteId: site,
    //               emailEnabled: emailEnabled,
    //               active: true,
    //             },
    //             timeout: 30000,
    //           }
    //         )
    //         .json();
    //     } else {
    //       const response = await api
    //         .put(
    //           `${endpoints.useUserServiceNotificationPreferencesFavourites.url}`,
    //           {
    //             json: {
    //               graphUserId: graphUserId,
    //               serviceId: selectedService.serviceId,
    //               siteId: site,
    //               emailEnabled: emailEnabled,
    //               active: true,
    //             },
    //             timeout: 30000,
    //           }
    //         )
    //         .json();
    //     }
    //   });
    // } else {
    //   notificationPreference.map(async (np) => {
    //     //console.log(site);
    //     //console.log(selectedService);

    //     const response = await api
    //       .post(
    //         `${endpoints.useUserServiceNotificationPreferencesFavourites.url}/${graphUserId}/${selectedService.serviceId}/${np.siteId}`,
    //         {
    //           json: {
    //             graphUserId: graphUserId,
    //             serviceId: selectedService.serviceId,
    //             siteId: np.siteId,
    //             emailEnabled: emailEnabled,
    //             active: true,
    //           },
    //           timeout: 30000,
    //         }
    //       )
    //       .json();
    //   });
    //}

    // const response = await externalApi
    //   .post(
    //     `${endpoints.useUserServiceNotificationPreferencesFavourites.url}/${graphUserId}/${selectedService.serviceId}/1`,
    //     {
    //       json: {
    //         graphUserId: userId,
    //         serviceId: selectedServiceId,
    //         siteId: 1,
    //         emailEnabled: emailEnabled,
    //       },
    //       timeout: 30000,
    //     }
    //   )
    //   .json();

    // console.log(response);
  }

  useEffect(() => {
    async function loadSites() {
      if (notificationPreference[0].emailEnabled) {
        setEmailEnabled(true);
      }
      // const siteList: any[] = await api.get(endpoints.getSiteList.url).json();

      // const sites = siteList.map((sl) => {
      //   return {
      //     key: sl.siteId,
      //     label: sl.siteName,
      //     value: String(sl.siteId),
      //   };
      // });

      // var sitesWithNotifications: any[] = [];

      // notificationPreference.map((np) => {
      //   sitesWithNotifications.push(np.siteId.toString());
      // });

      // if (sitesWithNotifications.length > 0) {
      //   setSelectedSites(sitesWithNotifications);
      //   setDefaultSelectedSites(sitesWithNotifications);
      // }

      // setSiteList(sites);
    }

    if (!isDataLoaded) {
      loadSites();
      setIsDataLoaded(true);
    }
  }, [graphUserId, selectedService]);

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={"Email Notifications for " + selectedService.serviceName}
      >
        <Box pos="relative">
          <LoadingOverlay visible={!isDataLoaded} overlayBlur={1} />
          <Center>
            <Checkbox
              label="Enable Email Notifications"
              checked={emailEnabled}
              onChange={(e) => setEmailEnabled(e.currentTarget.checked)}
              size="md"
              mb={12}
            />
          </Center>

          {/* {emailEnabled && (
            <Box>
              <Center>
                <MultiSelect
                  data={siteList}
                  value={selectedSites}
                  onChange={setSelectedSites}
                  p="md"
                  label="Sites"
                  description="Select which sites you would like to receive notifications about"
                />
              </Center>
            </Box>
          )} */}
          {/* <Code>EmailSites: {selectedSites}</Code> */}
          <Center>
            <Button color="green" size="md" onClick={() => saveChanges()}>
              Save Changes
            </Button>
          </Center>
        </Box>
      </Modal>
    </>
  );
};
