import React, { Fragment, useState, useEffect } from "react";
import {
  AppShell,
  Overlay,
  useMantineTheme,
  Alert,
  Text,
  Collapse,
  Divider,
  Button,
  Box,
  Anchor,
  Stack,
  SimpleGrid,
} from "@mantine/core";
import { useToggle, useMediaQuery } from "@mantine/hooks";

import { HeaderSimple } from "./HeaderSimple";
import { NavbarSimple } from "./NavbarSimple";

import { ChevronsDown, ChevronsUp } from "tabler-icons-react";

import { Link } from "react-router-dom";

import externalApi from "../../api";
import endpoints from "../../api/endpoints";
import {
  Service,
  StatusServiceAffectedResponse,
  StatusUpdateTimeline,
} from "types";

type Props = {
  children: React.ReactNode;
};

const Layout = (props: Props) => {
  const theme = useMantineTheme();
  const collapsed = useMediaQuery(`(max-width: ${theme.breakpoints.lg}px)`);
  const [open, toggleOpen] = useToggle([false, true]);

  const [collOpen, setCollOpen] = useToggle([true, false]);

  const tablet = useMediaQuery("(max-width: 1235px) and (min-width: 631px)");
  const phone = useMediaQuery("(max-width: 630px)");

  const [activePriorityAlerts, setActivePriorityAlerts] = useState<
    StatusUpdateTimeline[]
  >([]);
  const [allServicesAffected, setAllServicesAffected] = useState<
    StatusServiceAffectedResponse[]
  >([]);
  const [allServices, setAllServices] = useState<Service[]>([]);

  async function loadActivePriorityAlerts() {
    const servicesAffected: StatusServiceAffectedResponse[] = await externalApi
      .get(endpoints.getStatusServicesAffectedList.url)
      .json();

    const services: Service[] = await externalApi
      .get(endpoints.getServiceList.url)
      .json();

    const recentTimelineUpdates: StatusUpdateTimeline[] = await externalApi
      .get(endpoints.getActivePriorityList.url)
      .json();

    setAllServicesAffected(servicesAffected);

    setAllServices(services);

    setActivePriorityAlerts(recentTimelineUpdates);
  }

  const alertBoxes = activePriorityAlerts.map((pa) => {
    let affectedServiceNames: string[] = [];

    let affectedServices: number[] = allServicesAffected
      .filter((asa) => asa.statusUpdateId === pa.statusUpdateId)
      .map((svc) => svc.serviceId);

    if (affectedServices.length > 0) {
      affectedServiceNames = allServices
        .filter((svc) => affectedServices.includes(svc.serviceId))
        .map((svc) => svc.serviceName);
    }

    let alertHeader: string;
    //let alertDesc: string;
    let convertedTime = new Date(pa.updateDateTime + "Z");
    let lastUpdated: string = convertedTime.toLocaleTimeString("en-US", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      timeZone: "America/New_York",
      hour12: true,
    });

    if (pa.statusUpdate?.title) {
      alertHeader = pa.statusUpdate.title;
      //alertDesc = pa.statusUpdate?.description;
    } else {
      alertHeader = pa.header;
      //alertDesc = pa.shortDesc;
    }

    return (
      <Anchor
        component={Link}
        to={`/view-alert/${pa.statusUpdateId}`}
        key={pa.statusUpdateTimelineId}
      >
        <Alert
          title={
            <Stack spacing="xs">
              <Text>{alertHeader}</Text>
              <Text fz="xs" fw={600} mb={-10}>
                Last Updated At
              </Text>
              <Text fw={400}>{lastUpdated}</Text>
            </Stack>
          }
          color="red.8"
          radius="lg"
          h="100%"
          mb="md"
          ml="md"
          mr="md"
          style={{
            border: "1.5px solid #ff6b6b",
            color: "black",
          }}
        >
          <Stack spacing="xs">
            {affectedServiceNames.length > 0 && (
              <Text fw={400} fz="xs">
                Currently Affecting:{" "}
                {affectedServiceNames.map((cr: string, index: number) => {
                  return index === affectedServiceNames.length - 1
                    ? cr
                    : cr + ", ";
                })}
              </Text>
            )}

            {/* <Group spacing={3}>
                    <Text>{alertDesc}</Text>
                    </Group> */}
          </Stack>
        </Alert>
      </Anchor>
    );
  });
  useEffect(() => {
    loadActivePriorityAlerts();
    const interval = setInterval(() => {
      loadActivePriorityAlerts();
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  return (
    <AppShell
      fixed
      navbarOffsetBreakpoint="lg"
      padding="md"
      navbar={
        <NavbarSimple
          p="md"
          isOpen={open}
          setOpen={() => toggleOpen()}
          width={{ xs: 300 }}
        />
      }
      header={
        collapsed ? (
          <HeaderSimple isOpen={open} setOpen={toggleOpen} height={76} />
        ) : (
          <Fragment />
        )
      }
    >
      {open && collapsed && (
        <Overlay
          opacity={0.5}
          color={theme.colors.dark[9]}
          sx={{ position: "fixed", zIndex: 5 }}
        />
      )}
      {activePriorityAlerts.length > 0 && (
        <Box>
          {phone && (
            <Divider
              mb="xs"
              labelPosition="center"
              label={
                <>
                  {!collOpen && <ChevronsDown size={15} />}
                  {collOpen && <ChevronsUp size={15} />}
                  <Button
                    variant="subtle"
                    compact
                    uppercase
                    size="xs"
                    color="gray"
                    onClick={() => {
                      setCollOpen();
                    }}
                  >
                    {collOpen && "Hide "} {!collOpen && "Show "}System Wide
                    Alerts
                  </Button>
                </>
              }
            />
          )}
          <Collapse in={collOpen}>
            {!tablet && !phone && (
              <SimpleGrid cols={4} spacing="xs" verticalSpacing="xs">
                {alertBoxes}
              </SimpleGrid>
            )}
            {tablet && (
              <SimpleGrid cols={2} spacing="xs" verticalSpacing="xs">
                {alertBoxes}
              </SimpleGrid>
            )}
            {phone && <Stack spacing="xs">{alertBoxes}</Stack>}
          </Collapse>
          {!phone && (
            <Divider
              mt="xs"
              labelPosition="center"
              label={
                <>
                  {!collOpen && <ChevronsDown size={15} />}
                  {collOpen && <ChevronsUp size={15} />}
                  <Button
                    variant="subtle"
                    compact
                    uppercase
                    size="xs"
                    color="gray"
                    onClick={() => {
                      setCollOpen();
                    }}
                  >
                    {collOpen && "Hide "} {!collOpen && "Show "}System Wide
                    Alerts
                  </Button>
                </>
              }
            />
          )}
        </Box>
      )}

      {props.children}
    </AppShell>
  );
};

export default Layout;
