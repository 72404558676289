import { EmailNotificiationModal } from "./EmailNotificationModal";
import { PhoneNotificiationModal } from "./PhoneNotificationModal";
import { PassiveNotificiationModal } from "./PassiveNotificationModal";
import { ServiceAlertModal } from "./ServiceAlertModal";
import { ExistingAlertsModal } from "./ExistingAlertsModal";
import { ResolveAlertModal } from "./ResolveAlertModal";

export const EmailModal = EmailNotificiationModal;
export const PhoneModal = PhoneNotificiationModal;
export const PassiveModal = PassiveNotificiationModal;
export const ServiceModal = ServiceAlertModal;
export const ExistingAlertModal = ExistingAlertsModal;
export const ResolveModal = ResolveAlertModal;
