import { useEffect } from "react";
import {
  createStyles,
  Navbar,
  Group,
  Image,
  NavbarProps,
  UnstyledButton,
  Anchor,
  Menu,
  MediaQuery,
} from "@mantine/core";
import {
  Logout,
  Home2,
  MessageDots,
  QuestionMark,
  DeviceDesktopAnalytics,
  AlertTriangle,
  Eye,
  Checkbox,
  Book,
  FileTime,
} from "tabler-icons-react";
import { NavLink, Link } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

import { UserProfile } from "./UserProfile";

import UserGuide from "../../assets/2023_Service Health Dashboard User-Guide.pdf";

import siteLogo from "../../assets/serviceHealth_TempLogo.png";

const useStyles = createStyles((theme, _params, getRef) => {
  const icon = getRef("icon");
  return {
    navbar: {
      backgroundColor: theme.colors.dark[4],
      borderRight: "none",
    },

    version: {
      backgroundColor: theme.colors[theme.primaryColor][8],
      color: theme.white,
      fontWeight: 700,
    },

    header: {
      paddingBottom: theme.spacing.md,
      //marginBottom: theme.spacing.md * 1.5,
      color: "white",
      borderBottom: `1px solid ${theme.colors[theme.primaryColor][8]}`,

      [theme.fn.smallerThan("lg")]: {
        display: "none",
      },
    },

    footer: {
      paddingTop: theme.spacing.md,
      marginTop: theme.spacing.md,
      borderTop: `1px solid ${theme.colors[theme.primaryColor][8]}`,
    },

    profileDisplay: {
      [theme.fn.smallerThan("lg")]: {
        display: "none",
      },
    },

    link: {
      ...theme.fn.focusStyles(),
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      fontSize: theme.fontSizes.sm,
      color: theme.white,
      padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
      borderRadius: theme.radius.sm,
      fontWeight: 500,
      width: "100%",

      "&:hover": {
        backgroundColor: theme.colors[theme.primaryColor][8],
      },
    },

    linkIcon: {
      ref: icon,
      color: theme.white,
      opacity: 0.75,
      marginRight: theme.spacing.sm,
    },

    linkActive: {
      "&, &:hover": {
        backgroundColor: theme.colors[theme.primaryColor][9],
        [`& .${icon}`]: {
          opacity: 0.9,
        },
      },
    },
  };
});

//const topData = [
//    { link: '/dashboard', label: 'Home', icon: Home2 },
//    { link: '/my-tickets', label: 'View My Tickets', icon: UserCheck },
//    { link: '/my-technician-tickets', label: 'View My Technician Tickets', icon: Coffee },
//];

// const data = [
//   {
//     link: "/incident-request",
//     label: "Incident Ticket",
//     icon: UserExclamation,
//   },
//   { link: "/service-request", label: "Service Request", icon: Tool },
//   { link: "/change-password", label: "Password Help", icon: Lock },
//   { link: "/new-access-ticket", label: "Access Request", icon: Login },
//   { link: "/device-request", label: "Device Request", icon: Devices },
//   //{ link: '/project-request', label: 'IT Project Request', icon: Bulb,},
//   //{ link: '/urgent-issue', label: 'Report an Urgent Issue', icon: Urgent },
// ];

type NavbarSimpleProps = Omit<NavbarProps, "children"> & {
  isOpen: boolean;
  setOpen(): void;
};

export const NavbarSimple = (props: NavbarSimpleProps) => {
  const { accounts, instance } = useMsal();
  const account = accounts[0];

  const { isOpen, setOpen, ...rest } = props;
  //const [userCheck, setUserCheck] = useState<Boolean>(false);

  const { classes, cx } = useStyles();
  //const theme = useMantineTheme();

  //   const [topData, setTopData] = useState<any[]>([
  //     { link: "/", label: "Home", icon: Home2 },
  //     { link: "/service-manager", label: "Service Manager", icon: UserCheck },
  //   ]);

  const userIsTechnician: boolean | undefined =
    account?.idTokenClaims?.roles?.includes("ServiceHealth.Technician");

  //const topLinks = topData.map((item:any) => (
  //    <NavLink
  //        className={({ isActive }) => cx(classes.link, { [classes.linkActive]: isActive })}
  //        to={item.link}
  //        key={item.label}
  //        onClick={() => setOpen()}
  //    >
  //        <item.icon className={classes.linkIcon} />
  //        <span>{item.label}</span>
  //    </NavLink>
  //));

  //async function loadUser() {
  //    const me: GraphUser = await externalApi.get(endpoints.graphMe.url).json();
  //    setUser(me);
  //}

  useEffect(() => {}, []);

  return (
    <Navbar
      {...rest}
      hiddenBreakpoint="lg"
      hidden={!isOpen}
      className={classes.navbar}
    >
      <Navbar.Section grow>
        <Group className={classes.header}>
          <Anchor component={Link} to={`/`}>
            <MediaQuery smallerThan={390} styles={{ display: "none" }}>
              <Image radius="sm" src={siteLogo} fit="contain" alt="Site Logo" />
            </MediaQuery>
            <MediaQuery largerThan={390} styles={{ display: "none" }}>
              <Image
                radius="sm"
                src={siteLogo}
                fit="contain"
                style={{ marginRight: "10px" }}
                alt="Site Logo"
              />
            </MediaQuery>
          </Anchor>
        </Group>
        <Menu>
          <Menu.Target>
            <UnstyledButton
              className={classes.profileDisplay}
              p="md"
              mb={4}
              mt={4}
              sx={(theme) => ({
                width: "100%",
                borderRadius: theme.radius.md,
                "&:hover": {
                  backgroundColor: theme.colors.dark[6],
                },
              })}
            >
              <Group spacing="xs">
                <UserProfile
                  firstName={account.name!.split(", ")[1]}
                  lastName={account.name!.split(", ")[0]}
                  email={account.username}
                  tech={userIsTechnician ? userIsTechnician : false}
                />
              </Group>
            </UnstyledButton>
          </Menu.Target>
        </Menu>
        <NavLink
          className={({ isActive }) =>
            cx(classes.link, { [classes.linkActive]: isActive })
          }
          to="/"
          key="Home"
          onClick={() => setOpen()}
        >
          <Home2 className={classes.linkIcon} />
          <span>Dashboard</span>
        </NavLink>
        {userIsTechnician && (
          <NavLink
            className={({ isActive }) =>
              cx(classes.link, { [classes.linkActive]: isActive })
            }
            to="/new-alert"
            key="New Alert"
            onClick={() => setOpen()}
          >
            <AlertTriangle className={classes.linkIcon} />
            <span>Create New Service Alert</span>
          </NavLink>
        )}
        {userIsTechnician && (
          <NavLink
            className={({ isActive }) =>
              cx(classes.link, { [classes.linkActive]: isActive })
            }
            to="/active-alerts"
            key="Active Alerts"
            onClick={() => setOpen()}
          >
            <Eye className={classes.linkIcon} />
            <span>View Active Alerts</span>
          </NavLink>
        )}
        {userIsTechnician && (
          <NavLink
            className={({ isActive }) =>
              cx(classes.link, { [classes.linkActive]: isActive })
            }
            to="/resolved-alerts"
            key="Resolved Alerts"
            onClick={() => setOpen()}
          >
            <Checkbox className={classes.linkIcon} />
            <span>View Resolved Alerts</span>
          </NavLink>
        )}
        {userIsTechnician && (
          <NavLink
            className={({ isActive }) =>
              cx(classes.link, { [classes.linkActive]: isActive })
            }
            to="/service-manager"
            key="Service Manager"
            onClick={() => setOpen()}
          >
            <DeviceDesktopAnalytics className={classes.linkIcon} />
            <span>Service Manager</span>
          </NavLink>
        )}
        {userIsTechnician && (
          <NavLink
            className={({ isActive }) =>
              cx(classes.link, { [classes.linkActive]: isActive })
            }
            to="/add-historical-alert"
            key="Add Historical Alert"
            onClick={() => setOpen()}
          >
            <FileTime className={classes.linkIcon} />
            <span>Add Historical Alert</span>
          </NavLink>
        )}
      </Navbar.Section>

      <Navbar.Section className={classes.footer}>
        <UnstyledButton
          className={classes.link}
          component="a"
          target="_blank"
          href={UserGuide}
        >
          <Book className={classes.linkIcon} />
          <span>Service Health User Guide</span>
        </UnstyledButton>
        <UnstyledButton
          className={classes.link}
          component="a"
          target="_blank"
          href="https://forms.office.com/Pages/ResponsePage.aspx?id=U_dz6nZpnkGVKtP9eq4LpBSvF0xo-tdIosB-YnMSl7FUMTdaUUNMMloyRkZBTjgxRkhNTjNVNFpTVC4u"
        >
          <MessageDots className={classes.linkIcon} />
          <span>Feedback</span>
        </UnstyledButton>
        <UnstyledButton
          className={classes.link}
          component="a"
          target="_blank"
          href="https://servicedeskhub.lh.ca/"
        >
          <QuestionMark className={classes.linkIcon} />
          <span>Service Desk Hub</span>
        </UnstyledButton>

        <UnstyledButton
          className={classes.link}
          onClick={() =>
            instance.logoutRedirect({ postLogoutRedirectUri: "/" })
          }
        >
          <Logout className={classes.linkIcon} />
          <span>Logout</span>
        </UnstyledButton>
      </Navbar.Section>
    </Navbar>
  );
};
