const endpoints = {
  //-------------GRAPH-------------
  graphMe: {
    scope: "User.Read",
    url: "https://graph.microsoft.com/v1.0/me?$select=businessPhones,displayName,givenName,jobTitle,mail,mobilePhone,officeLocation,preferredLanguage,surname,department,userPrincipalName,id,extension_6d149d5b2b4b4755924426c8882310ae_employeeNumber",
  },
  graphUsers: {
    scope: "User.Read.All",
    url: "https://graph.microsoft.com/v1.0/users?$select=businessPhones,displayName,givenName,jobTitle,mail,mobilePhone,officeLocation,preferredLanguage,surname,department,userPrincipalName,id,extension_6d149d5b2b4b4755924426c8882310ae_employeeNumber&$filter=accountEnabled eq true",
  },
  graphProfilePhoto: {
    scope: "User.Read.All",
    url: "https://graph.microsoft.com/v1.0/users", //USERPRINCIPALNAME/photo/$value
  },
  //-------------SERVICES-------------
  getServiceList: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `Service/List`,
  },
  getDashboard: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `Service/Dashboard`,
  },
  // Could just utilize one endpoint (useService), a GET = getService, a PUT = addService, a POST = updateService, will try and see if it makes sense in code
  useService: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `Service`,
  },
  getService: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `Service`,
  },
  addService: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `Service`,
  },
  updateService: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `Service`,
  },
  //-------------SITES-------------
  getSiteList: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `Site/List`,
  },
  // See above comment
  useSite: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `Site`,
  },
  getSite: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `Site`,
  },
  addSite: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `Site`,
  },
  updateSite: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `Site`,
  },
  //-------------STATUS SITES AFFECTED-------------
  getStatusSitesAffectedList: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `StatusSitesAffected/List`,
  },
  // See above above comment
  useStatusSitesAffected: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `StatusSitesAffected`,
  },
  getStatusSitesAffected: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `StatusSitesAffected`,
  },
  addStatusSitesAffected: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `StatusSitesAffected`,
  },
  updateStatusSitesAffected: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `StatusSitesAffected`,
  },
  getStatusSitesAffectedListBySite: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `StatusSitesAffected/ListBySite`,
  },
  getStatusSitesAffectedListByService: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `StatusSitesAffected/ListByService`,
  },
  getStatusSitesAffectedListBySiteAndService: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `StatusSitesAffected/ListBySiteAndService`,
  },
  getStatusSitesAffectedListByStatusUpdate: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `StatusSitesAffected/ListByStatusUpdate`,
  },
  //-------------STATUS UPDATE-------------
  getStatusUpdateList: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `StatusUpdate/List`,
  },
  getStatusUpdateListByService: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `StatusUpdate/ListByService`,
  },
  getActivePriorityAlerts: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `StatusUpdate/ActivePriorityAlerts`,
  },
  // See above above comment
  useStatusUpdate: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `StatusUpdate`,
  },
  getStatusUpdate: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `StatusUpdate`,
  },
  addStatusUpdate: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `StatusUpdate`,
  },
  updateStatusUpdate: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `StatusUpdate`,
  },
  resolveStatusUpdate: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `StatusUpdate/resolve`,
  },
  addNewServiceAlert: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `StatusUpdate/NewServiceAlert`,
  },
  //-------------USERS-------------
  getUserList: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `User/List`,
  },
  getMe: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `User/Me`,
  },
  // See above above comment
  useUser: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `User`,
  },
  getUser: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `User`,
  },
  addUser: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `User`,
  },
  updateUser: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `User`,
  },
  //-------------USER SERVICE FAVOURITES-------------
  getUserServiceFavouritesList: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `UserServiceFavourites/List`,
  },
  // See above above comment
  useUserServiceFavourites: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `UserServiceFavourites`,
  },
  getUserServiceFavourites: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `UserServiceFavourites`,
  },
  addUserServiceFavourites: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `UserServiceFavourites`,
  },
  updateUserServiceFavourites: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `UserServiceFavourites`,
  },
  getUserServiceFavouritesListByUser: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `UserServiceFavourites/ListByUser`,
  },
  getUserServiceFavouritesListByService: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `UserServiceFavourites/ListByService`,
  },
  //-------------USER SERVICE NOTIFICATION PREFERENCES-------------
  getUserServiceNotificationPreferencesList: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `NotificationPreferences/List`,
  },
  // See above above comment
  useUserServiceNotificationPreferencesFavourites: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `UserServiceNotificationPreferences`,
  },
  getUserServiceNotificationPreferencesFavourites: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `UserServiceNotificationPreferences`,
  },
  addUserServiceNotificationPreferences: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `UserServiceNotificationPreferences`,
  },
  updateUserServiceNotificationPreferences: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `UserServiceNotificationPreferences`,
  },
  getUserServiceNotificationPreferencesListByUser: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `UserServiceNotificationPreferences/ListByUser`,
  },
  getUserServiceNotificationPreferencesListByService: {
    url: `UserServiceNotificationPreferences/ListByService`,
  },
  //-------------STATUSSERVICESAFFECTED----------------
  getStatusServicesAffectedList: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `StatusServicesAffected/List`,
  },
  getStatusServicesAffectedListBySite: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `StatusServicesAffected/ListBySite`,
  },
  getStatusServicesAffectedListByService: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `StatusServicesAffected/ListByService`,
  },
  getStatusServicesAffectedListByStatusUpdate: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `StatusServicesAffected/ListByStatusUpdate`,
  },
  getStatusServicesAffectedListBySiteAndService: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `StatusServicesAffected/ListBySiteAndService`,
  },
  useStatusServicesAffected: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `StatusServicesAffected`,
  },
  //-------------TIMELINES------------
  getStatusUpdateTimelineList: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `StatusUpdateTimeline/List`,
  },
  useStatusUpdateTimeline: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `StatusUpdateTimeline`,
  },
  getTimelinesFromStatusUpdates: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `StatusUpdateTimeline/TimelinesFromStatusUpdates`,
  },
  getActivePriorityList: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `StatusUpdateTimeline/ActivePriorityList`,    
  },
  //-------------STATUSES-------------
  getStatusList: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `Status/List`,
  },
  //-------------PRIORITY LEVEL-------------
  getPriorityList: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `PriorityLevel/List`,
  },
  //-------------EMAIL----------------
  sendNotification: {
    scope: "api://5f312dd9-d45d-439d-b8a0-ba8860f61860/access_as_user",
    url: `NotifyUsers`,
  },
};

export default endpoints;
