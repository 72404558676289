import { useState, useEffect } from "react";
import {
  Title,
  Center,
  Box,
  Paper,
  Group,
  Button,
  Select,
  TextInput,
  Textarea,
  Text,
  createStyles,
  Container,
  Stack,
} from "@mantine/core";

import { DatePicker, TimeInput } from "@mantine/dates";

import { useMediaQuery } from "@mantine/hooks";

import { useForm } from "@mantine/form";

import { showNotification } from "@mantine/notifications";

import externalApi from "../api";
import endpoints from "../api/endpoints";

import {
  SelectData,
  Service,
  AddNewServiceAlertRequest,
  AddNewServiceAlertResponse,
  GraphUser,
} from "types";

const useStyles = createStyles((theme) => ({
  formHeading: {
    backgroundColor: theme.fn.variant({
      variant: "filled",
      color: theme.primaryColor,
    }).background,
    borderBottom: 0,
    borderTopLeftRadius: theme.radius.md,
    borderTopRightRadius: theme.radius.md,
    padding: theme.spacing.sm,
  },
}));

const AddHistoricalAlert = () => {
  const [servicesList, setServicesList] = useState<SelectData[]>([]);
  const [me, setMe] = useState<GraphUser>();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const mobile = useMediaQuery("(max-width: 767px)");

  const { classes } = useStyles();

  interface FormData {
    serviceId: number;
    eventDate: Date;
    eventTime: Date;
    resolvedDate: Date;
    resolvedTime: Date;
    resolutionHeader: string;
    resolutionShortDesc: string;
  };

  const form = useForm<FormData>({
    initialValues: {
      serviceId: 0,
      eventDate: new Date(),
      eventTime: new Date(),
      resolvedDate: new Date(),
      resolvedTime: new Date(),
      resolutionHeader: "",
      resolutionShortDesc: "",
    },

    validate: {
        serviceId: (value) => (value === 0 ? "You must select a service" : null),
        resolutionHeader: (value) => (value.trim().length < 1 ? "You must include a header message." : null),
        resolutionShortDesc: (value) => (value.trim().length < 1 ? "You must include a short description."
            : value.trim().length > 300 ? "Short Description must be 300 characters or less" : null)
    }
  });

  async function loadUser() {
    const user: GraphUser = await externalApi.get(endpoints.getMe.url).json();

    setMe(user);
  }

  async function loadServices() {
    const services: Service[] = await externalApi
      .get(endpoints.getServiceList.url)
      .json();

    const mapService: SelectData[] = services.map((s) => {
      return {
        key: s.serviceId,
        value: s.serviceId.toString(),
        label: s.serviceName,
      };
    });

    setServicesList(mapService);
  }

  async function submitUpdate() {
    setIsSubmitting(true);

    //Set the time of eventDate from eventTime
    form.values["eventDate"].setHours(
        form.values["eventTime"].getHours(), 
        form.values["eventTime"].getMinutes(), 
        0, 
        0
    );
    
    //Set the time of resolvedDate from resolvedTime
    form.values["resolvedDate"].setHours(
        form.values["resolvedTime"].getHours(),
        form.values["resolvedTime"].getMinutes(),
        0,
        0
    );

    //Status Update
    const createdByUserID = me!.graphUserId;

    const newServiceAlert: AddNewServiceAlertRequest = {
        statusUpdate: {
            createdByUserId: createdByUserID,
            title: form.values["resolutionHeader"],
            description: form.values["resolutionShortDesc"],
            displayOnBanner: false,
            createDateTime: new Date(),
            startDateTime: form.values["eventDate"],
            resolved: true,
            active: undefined,
        },
        timeline: {
            priorityLevelId: 5,
            header: form.values["resolutionHeader"],
            shortDesc: form.values["resolutionShortDesc"],
            description: undefined,
            resolved: true,
            overrideAndAlertAll: false,
            updateDateTime: form.values["resolvedDate"],
            active: true,
        },
        affectedServices: [
            {
                serviceId: form.values["serviceId"],
                statusId: 2,
                serviceDetails: undefined,
                createdDateTime: undefined,
                active: false,
            },
        ],
        sendEmails: false,
    };

    const response: AddNewServiceAlertResponse = await externalApi
    .put(endpoints.addNewServiceAlert.url, {
        json: {
        ...newServiceAlert,
        },
        timeout: 30000,
    })
    .json();

    if (!response.success) {
        // Error
        setIsSubmitting(false);
        showNotification({
            title: "Error!",
            color: "red",
            message:
            "An error occurred when saving the historical alert. Please try again, or contact IT Support.",
        });
    } else {
        setIsSubmitting(false);
        form.reset();
        showNotification({
            title: "Success!",
            color: "green",
            message: "The historical alert was successfully saved.",
        });
    }
  }

  useEffect(() => {
    loadUser();
    loadServices();
  }, []);

  return (
    <Box p="lg">
      <Container fluid className={classes.formHeading}>
        <Title order={3} color="white">
          Add Historical Alert
        </Title>
      </Container>

      <Paper p="sm" withBorder shadow="xs">
        <Text fz="md" fw={500} mb="lg">
          This tool should be used to capture service alerts/events that
          occurred and were resolved but were not captured in the Service Health
          tool. <br />
          Entering those alerts here will allow them to cross over to reports
          based on data from the Service Health tool. Alerts saved within this
          screen will not alert users.
        </Text>
        <Box style={{ maxWidth: mobile ? "100%" : "50%" }}>
         <form onSubmit={form.onSubmit(() => submitUpdate())}>
          <Select
            data={servicesList}
            label="Affected Service"
            placeholder="Select a Service"
            searchable
            nothingFound="No services found"            
            {...form.getInputProps("serviceId")}
            withAsterisk
          />
          <Stack spacing="lg" my="xs">
            <Group noWrap spacing="xs" grow>
              <DatePicker
                placeholder="Pick date"
                label="Event Occurrence Date"
                description="Enter the date that the service event happened on."
                withAsterisk
                {...form.getInputProps("eventDate")}
              />
              <TimeInput
                clearable
                label="Event Occurrence Time"
                description="Enter the approximate time the event occurred at in 24 hour format."
                {...form.getInputProps("eventTime")}
              />
            </Group>

            <Group noWrap spacing="xs" grow>
              <DatePicker
                placeholder="Pick date"
                label="Event Resolved Date"
                description="Enter the date that the service event was resolved."
                withAsterisk
                {...form.getInputProps("resolvedDate")}
              />
              <TimeInput
                clearable
                label="Event Resolved Time"
                description="Enter the approximate time the event was resolved at in 24 hour format."
                {...form.getInputProps("resolvedTime")}
              />
            </Group>
          </Stack>
          <TextInput
            withAsterisk
            label="Resolution Header"
            description="Enter in a short message that would have been used to summarize the resolution."
            {...form.getInputProps("resolutionHeader")}
          />
          <Textarea
            withAsterisk
            label="Resolution Description"
            description="Enter in the full details of the resolution."
            {...form.getInputProps("resolutionShortDesc")}
          />
          <Center>
            <Button
              mt="md"
              type="submit"
              uppercase
              loading={isSubmitting}
            >
              Submit
            </Button>
          </Center>
          </form>
        </Box>
        {/* <Code block>{JSON.stringify(form.values, null, 2)}</Code> */}
        {/* <Code block>{JSON.stringify(form.errors, null, 2)}</Code> */}
      </Paper>
    </Box>
  );
};

export default AddHistoricalAlert;
