import { useEffect, useState } from "react";

import {
  Button,
  Group,
  Tooltip,
  Badge,
  MultiSelect,
  Modal,
  Loader,
  Center,
  Title,
  Text,
} from "@mantine/core";

import { showNotification } from "@mantine/notifications";

import { DataGrid } from "mantine-data-grid";

import { Trash, Plus, Tornado } from "tabler-icons-react";

import { RowSelectionState } from "@tanstack/react-table";

import api from "../api";
import endpoints from "../api/endpoints";

import { EditServiceModal } from "components/modals/EditServiceModal";

import { CreateFilter } from "components";

import { UpdateServiceResponse, Service } from "types";

const ServiceManager = () => {
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [serviceList, setServiceList] = useState<Service[]>([]);
  const [allServices, setAllServices] = useState<Service[]>([]);

  const [selected, setSelected] = useState<RowSelectionState>({});

  const [selectedService, setSelectedService] = useState<Service>();

  const [editModalOpened, setEditModalOpened] = useState<boolean>(false);
  const [newService, setNewService] = useState<boolean>(false);

  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);

  const categoryData = [
    { value: "Administrative", label: "Administrative" },
    { value: "Clinical", label: "Clinical" },
    { value: "Communications", label: "Communications" },
    { value: "Infrastructure", label: "Infrastructure" },
    { value: "Infrastructure/Server", label: "Infrastructure/Server" },
    { value: "Non Clinical", label: "Non Clinical" },
    { value: "Non-Clinical", label: "Non-Clinical" },
  ];

  const systemTypeData = [
    { value: "API", label: "API" },
    { value: "End User System", label: "End User System" },
    { value: "External Services", label: "External Services" },
    { value: "Interface - SSIS/ETL", label: "Interface - SSIS/ETL" },
    { value: "Servers", label: "Servers" },
  ];

  const deleteSelected = async () => {
    const newList = serviceList.filter((_, i) => !selected[i]);

    const serviceIdsToDelete = allServices
      .filter((_, i) => selected[i])
      .map((as) => as.serviceId);

    setSelected({});
    setServiceList(newList);

    let response: UpdateServiceResponse;

    setShowDeleteConfirm(false);

    serviceIdsToDelete.forEach(async (sid: number) => {
      try {
        response = await api
          .post(`${endpoints.useService.url}/${sid}`, {
            json: {
              active: false,
            },
            timeout: 30000,
          })
          .json();
      } catch (e) {
        showNotification({
          title: "Error Deleting Service",
          color: "red",
          autoClose: false,
          message: "Service ID: " + sid + "Error: " + String(e),
          styles: (theme) => ({
            root: {
              backgroundColor: theme.colors.red[6],
              borderColor: theme.colors.red[6],

              "&::before": { backgroundColor: theme.white },
            },

            title: { color: theme.white },
            description: { color: theme.white },
            closeButton: {
              color: theme.white,
              "&:hover": { backgroundColor: theme.colors.red[7] },
            },
          }),
        });

        response = { success: false, response: String(e), serviceId: -1, valueChanged: false };
      }

      if (response.success) {
        setSelected({});
        loadData();
        showNotification({
          title: "Success!",
          color: "green",
          message: "Services were successfully deleted.",
          styles: (theme) => ({
            root: {
              backgroundColor: theme.colors.green[6],
              borderColor: theme.colors.green[6],

              "&::before": { backgroundColor: theme.white },
            },

            title: { color: theme.white },
            description: { color: theme.white },
            closeButton: {
              color: theme.white,
              "&:hover": { backgroundColor: theme.colors.green[7] },
            },
          }),
        });
      }
    });
  };
  interface msFilterData {
    value: string;
    label: string;
  };
  const multiSelectFilter = (msData: msFilterData[]) =>
    CreateFilter<string, string[]>({
      init: () => [],
      operators: [
        {
          op: "select",
          filterFn: (rowValue, filterValue) => filterValue.includes(rowValue),
          element: ({ onChange, value, ...rest }) => (
            <MultiSelect
              {...rest}
              data={msData}
              value={value}
              onChange={onChange}
            />
          ),
        },
      ],
    });

  async function loadData() {
    const servicePull: Service[] = await api
      .get(`${endpoints.getServiceList.url}`)
      .json();

    const activeServices = servicePull.filter((sp: Service) => sp.active === true);

    setAllServices(activeServices);

    const services: Service[] = servicePull
      .filter((svc) => svc.active === true);

    setServiceList(services);
  }

  function openEditModal(row?: any) {
    if (row) {
      const service = allServices.find(
        (serv) => serv.serviceId === row.original.serviceId
      );
      setSelectedService(service);
      setNewService(false);
    } else {
      setNewService(true);
    }

    setEditModalOpened(true);
  }

  function ModalTitle() {
    if (!newService) {
      return (
        <Group>
          <Title fw={400} color="dimmed" order={3}>
            Edit
          </Title>
          <Title order={3}>{selectedService?.serviceName}</Title>
        </Group>
      );
    } else {
      return (
        <Group spacing="xs">
          <Title order={3}>Create New Service</Title>
        </Group>
      );
    }
  }

  useEffect(() => {
    if (!isDataLoaded) {
      loadData();
      setIsDataLoaded(true);
    }
  }, [isDataLoaded]);

  return (
    <>
      <Modal
        opened={editModalOpened}
        onClose={() => setEditModalOpened(false)}
        title={<ModalTitle />}
        size="xl"
      >
        <EditServiceModal
          opened={editModalOpened}
          setOpened={setEditModalOpened}
          selectedService={selectedService}
          newService={newService}
        />
      </Modal>
      <Modal
        opened={showDeleteConfirm}
        onClose={() => setShowDeleteConfirm(false)}
        title="Confirm Delete"
        size="lg"
      >
        <>
          <Center>
            <Text>Are you sure you want to delete </Text>
          </Center>
          <Center>
            <Group spacing={2}>
              {allServices
                .filter((_, i) => selected[i])
                .map((as, index) => {
                  return (
                    <Text key={as.serviceId}>
                      {as.serviceName}
                      {index ===
                      allServices.filter((_, i) => selected[i]).length - 1
                        ? ""
                        : ", "}
                    </Text>
                  );
                })}
              ?
            </Group>
          </Center>
          <Center mt="md">
            <Button
              color="red"
              onClick={() => setShowDeleteConfirm(false)}
              mr="md"
            >
              No
            </Button>
            <Button color="green" onClick={() => deleteSelected()}>
              Yes
            </Button>
          </Center>
        </>
      </Modal>
      <Title order={1} mb="sm">
        Service Manager
      </Title>
      {/* <Code>{JSON.stringify(selected)}</Code> as.serviceName */}
      <Group position="apart">
        <Button variant="outline" onClick={() => openEditModal(null)}>
          <Plus style={{ marginRight: "8px" }} /> Add New
        </Button>

        <Group spacing="lg">
          <Button
            variant="outline"
            mb="sm"
            color="red"
            onClick={() => setShowDeleteConfirm(true)}
          >
            <Trash style={{ marginRight: "8px" }} /> Delete Selected
          </Button>
          <Button
            variant="outline"
            mb="sm"
            color="cyan"
            onClick={() => setSelected({})}
          >
            <Tornado style={{ marginRight: "8px" }} /> Clear Selected
          </Button>
        </Group>
      </Group>

      <DataGrid
        withPagination
        highlightOnHover
        withColumnFilters
        withSorting
        withGlobalFilter
        withRowSelection
        state={{
          rowSelection: selected,
        }}
        empty={
          <Center mt="xl">
            <Loader size="lg" />
          </Center>
        }
        onRowSelectionChange={setSelected}
        data={serviceList}
        columns={[
          { header: "Service Name", accessorFn: (row) => row.serviceName },

          {
            header: "Service Description",
            accessorFn: (row) => row.serviceDescription,
          },
          {
            header: "Category",
            accessorFn: (row) => row.category,
            filterFn: multiSelectFilter(categoryData),
          },
          {
            header: "Type",
            accessorFn: (row) => row.systemType,
            filterFn: multiSelectFilter(systemTypeData),
            cell: (cell) => {
              var color;
              switch (cell.getValue()) {
                case "Servers":
                  color = "green";
                  break;
                case "API":
                  color = "violet";
                  break;
                case "External Services":
                  color = "red";
                  break;
                case "End User System":
                  color = "blue";
                  break;
                case "Interface - SSIS/ETL":
                  color = "cyan";
                  break;
                default:
                  color = "gray";
              }
              if (cell.getValue()) {
                return (
                  <Badge color={color}>{cell.getValue()?.toString()}</Badge>
                );
              } else {
                return <Badge color={color}>?</Badge>;
              }
            },
          },
          {
            header: "Launch Date",
            accessorFn: (row) => new Date(row.launchDate).toLocaleDateString(),
          },

          {
            accessorKey: "button",
            header: "",

            cell: (cell) => {
              return (
                <Group>
                  <Tooltip label="View and Edit Service Details">
                    <Button onClick={() => openEditModal(cell.row)}>
                      View / Edit
                    </Button>
                  </Tooltip>
                </Group>
              );
            },
          },
        ]}
      />
    </>
  );
};

export default ServiceManager;
