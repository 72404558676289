import { RecentServiceHistory } from "./RecentServiceHistory";
import { ServiceHistoryCalendar } from "./ServiceHistoryCalendar";
import { HistoryCalendarIndicator } from "./CalendarIndicator";
import { CreateServiceAlert } from "./CreateServiceAlert";
import { ServiceHistory } from "./ServiceHistory";
import { ExistingServiceAlerts } from "./ExistingServiceAlerts";
import { ViewAlert } from "./ViewAlert";
import { ResolveAlert } from "./ResolveAlert";
import { createOperatorFilter } from "./CreateOperatorFilter";
import { NewServiceAlert } from "./NewServiceAlert";
import { EditServiceAlert } from "./EditServiceAlert";

import { ActiveAlertBox } from "./ActiveAlertBox";

export const RecentHistory = RecentServiceHistory;
export const HistoryCalendar = ServiceHistoryCalendar;
export const CalendarIndicator = HistoryCalendarIndicator;
export const CreateAlert = CreateServiceAlert;
export const ServiceHistorySection = ServiceHistory;
export const ExistingAlerts = ExistingServiceAlerts;
export const ViewStatusAlert = ViewAlert;
export const ResolveStatusAlert = ResolveAlert;
export const CreateFilter = createOperatorFilter;
export const ActiveAlert = ActiveAlertBox;
export const NewAlert = NewServiceAlert;
export const EditAlert = EditServiceAlert;
