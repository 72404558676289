import { Link, useParams } from "react-router-dom";

import { useState, useEffect } from "react";
import {
  Stack,
  Title,
  Center,
  Box,
  Paper,
  Group,
  Text,
  Anchor,
  Spoiler,
  Flex,
  Loader,
  Skeleton,
} from "@mantine/core";

import { statusIcons } from "../common/Data";

import { ServiceHistorySection } from "components";

import { Service, StatusServiceAffectedResponse, StatusUpdateResponse, StatusUpdateTimeline } from "types";

import externalApi from "../api";
import endpoints from "../api/endpoints";

const ServiceDetails = () => {
  const { serviceId } = useParams();

  const [service, setService] = useState<Service>();
  const [mostRecentUpdateHeader, setMostRecentUpdateHeader] = useState<string>("");
  const [mostRecentUpdate, setMostRecentUpdate] = useState<StatusUpdateResponse>();
  const [mostRecentUpdateDateTime, setMostRecentUpdateDateTime] = useState<Date>();
  const [recentStatusIcon, setRecentStatusIcon] = useState<JSX.Element>();
  const [allStatusUpdates, setAllStatusUpdates] = useState<StatusUpdateResponse[]>([]);
  const [serviceDescription, setServiceDescription] = useState<string>();
  const [serviceLaunchDate, setServiceLaunchDate] = useState<string>();
  const [connectedResourcesList, setConnectedResourcesList] = useState<string[]>([]);
  const [serviceLoaded, setServiceLoaded] = useState<boolean>(false);
  const [statusesLoaded, setStatusesLoaded] = useState<boolean>(false);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);

  async function loadData() {}

  async function loadService(serviceId: string) {
    setServiceLoaded(false);
    const selectedService: Service = await externalApi
      .get(`${endpoints.useService.url}/${serviceId}`)
      .json();

    setService(selectedService);
    setServiceDescription(selectedService?.serviceDescription);
    setServiceLaunchDate(
      new Date(selectedService?.launchDate).toLocaleDateString()
    );

    if (selectedService.connectedResources) {
      const connectedResourceIds =
        selectedService.connectedResources.split(",");

      const connectedResourceNames = connectedResourceIds.map(async (crId) => {
        const service: Service = await externalApi
          .get(`${endpoints.useService.url}/${crId}`)
          .json();

        return service.serviceName;
      });

      Promise.all(connectedResourceNames).then((values) => {
        setConnectedResourcesList(values);
      });
    }

    setServiceLoaded(true);

    const timelineItems: StatusUpdateTimeline[] = await externalApi
      .get(`${endpoints.getStatusUpdateTimelineList.url}`)
      .json();

    const statusServicesAffected: StatusServiceAffectedResponse[] = await externalApi
      .get(`${endpoints.getStatusServicesAffectedListByService.url}/${serviceId}/false`) // Allow inactive records to come through
      .json();

    const statusUpdates: StatusUpdateResponse[] = await externalApi
      .get(`${endpoints.getStatusUpdateListByService.url}/${serviceId}/false`) // Allow inactive records to come through
      .json();

    setAllStatusUpdates(statusUpdates);

    const mostRecentStatus = statusUpdates.sort()[0];

    if (mostRecentStatus) {
      const status = statusServicesAffected.filter(
        (as) => as.statusUpdateId === mostRecentStatus.statusUpdateId
      )[0].statusId;

      const mostRecentTimeline = timelineItems.filter(
        (tl) => tl.statusUpdateId === mostRecentStatus.statusUpdateId
      )[0];

      const recentStatusIcon = statusIcons.find((e) => {
        return e.statusId === status;
      })?.icon;

      setMostRecentUpdate(mostRecentStatus);
      setMostRecentUpdateHeader(mostRecentTimeline.header);
      setMostRecentUpdateDateTime(mostRecentTimeline?.updateDateTime);
      setRecentStatusIcon(recentStatusIcon);
    }
    setStatusesLoaded(true);
  }

  useEffect(() => {
    if (!dataLoaded) {
      loadService(serviceId!);
      loadData();
      setDataLoaded(true);
    }
  }, [dataLoaded, serviceId]);

  return (
    <Center>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.colors.gray[0],
          //minWidth: 1200,
          width: "75%",
          padding: theme.spacing.sm,
          borderRadius: theme.radius.md,
        })}
      >
        <Group position="apart">
          <Group>
            <Anchor color="gray" component={Link} to={`/`}>
              <Title order={2} ml="xs" mb="xs" mt="xs" fw={200}>
                Back
              </Title>
            </Anchor>
            {serviceLoaded && (
              <Title order={2} ml={-10}>
                | {service?.serviceName}
              </Title>
            )}
            {!serviceLoaded && (
              <Group>
                <Title order={2} ml={-10}>
                  |
                </Title>
                <Skeleton width={200} radius="lg" height={40} />
              </Group>
            )}
          </Group>
          {statusesLoaded && (
            <>
              {mostRecentUpdate !== undefined && (
                <Stack spacing="xs">
                  <Text fz="xs" c="dimmed">
                    Most Recent Status{" "}
                    {mostRecentUpdateDateTime &&
                      new Date(mostRecentUpdateDateTime).toLocaleDateString() +
                        " - " +
                        new Date(mostRecentUpdateDateTime).toLocaleTimeString()}
                  </Text>
                  <Group mr="xs" mt={-5} mb="xs">
                    {recentStatusIcon}
                    <Title order={3}>{mostRecentUpdateHeader}</Title>
                  </Group>
                </Stack>
              )}

              {mostRecentUpdate === undefined && (
                <Stack spacing="xs">
                  <Text fz="xs" c="dimmed">
                    No recent status data available.
                  </Text>
                </Stack>
              )}
            </>
          )}
          {!statusesLoaded && (
            <Skeleton height={60} width={200} radius="lg" mb="xs" />
          )}
        </Group>

        {/* Description/Service Info Box */}
        <Paper shadow="xs" p="xs">
          <Flex
            align="flex-start"
            wrap="nowrap"
            p="xs"
            direction="row"
            justify="flex-start"
            gap="xl"
          >
            {/* Service Description Stack */}
            <Stack spacing="xs">
              <Text fz="xs" c="dimmed">
                Service Description
              </Text>
              <Spoiler
                maxHeight={120}
                showLabel="Show more"
                hideLabel="Show less"
              >
                {serviceLoaded && (
                  <Text mt={-4} fz="md">
                    {serviceDescription}
                  </Text>
                )}
                {!serviceLoaded && <Loader size="sm" variant="dots" />}
              </Spoiler>
            </Stack>
            {/* Resources/Launch Stack */}
            <Stack spacing="sm">
              <Stack spacing="xs">
                <Text fz="xs" c="dimmed">
                  Launch Date
                </Text>
                {serviceLoaded && <Text mt={-4}>{serviceLaunchDate}</Text>}
                {!serviceLoaded && <Loader size="sm" variant="dots" />}
              </Stack>
              <Stack spacing="xs">
                <Text fz="xs" c="dimmed">
                  Connected Resources
                </Text>
                <Spoiler
                  maxHeight={30}
                  showLabel="Show more"
                  hideLabel="Show less"
                >
                  {serviceLoaded && (
                    <Text mt={-4} fw={400}>
                      {connectedResourcesList.map((cr, index) => {
                        return index === connectedResourcesList.length - 1
                          ? cr
                          : cr + ", ";
                      })}
                    </Text>
                  )}

                  {!serviceLoaded && <Loader size="sm" variant="dots" />}
                </Spoiler>
              </Stack>
            </Stack>
            {/* Stakeholders/Other Stack */}
            <Stack spacing="xs">
              <Text fz="xs" c="dimmed">
                Key Stakeholders
              </Text>
              <Text mt={-4}>{service?.stakeholders}</Text>
            </Stack>
          </Flex>
        </Paper>
        {/* Status History Info Box */}
        <Paper mt="md" shadow="xs" p="xs">
          <Text c="dimmed" fz="xs">
            Service History
          </Text>
          <Box ml="sm" mr="sm" mt="md" pb="md">
            <ServiceHistorySection
              serviceId={serviceId}
              statusUpdates={allStatusUpdates}
            />
          </Box>
        </Paper>
      </Box>
    </Center>
  );
};

export default ServiceDetails;
