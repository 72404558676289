import { useEffect, useState, useMemo, ReactElement } from "react";

import {
  Box,
  Title,
  Container,
  Paper,
  Stack,
  Text,
  Group,
  createStyles,
  Modal,
  Button,
  TextInput,
  Center,
  Pagination,
  Select,
} from "@mantine/core";

import api from "../api";
import endpoints from "../api/endpoints";
import { statusIcons } from "../common/Data";
import { GraphUser, ResolveStatusUpdateResponse, StatusServiceAffectedResponse, StatusUpdateResponse, StatusUpdateTimeline } from "types";

const useStyles = createStyles((theme) => ({
  formHeading: {
    backgroundColor: theme.fn.variant({
      variant: "filled",
      color: theme.primaryColor,
    }).background,
    borderBottom: 0,
    borderTopLeftRadius: theme.radius.md,
    borderTopRightRadius: theme.radius.md,
    padding: theme.spacing.sm,
  },
}));

const ResolvedAlerts = () => {
  const [me, setMe] = useState<GraphUser>();

  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [alertListLoaded, setAlertListLoaded] = useState<boolean>(false);

  const [resolveModalShow, setResolveModalShow] = useState<boolean>(false);
  const [resolveAgree, setResolveAgree] = useState<boolean>(false);

  const [selectedStatusUpdate, setSelectedStatusUpdate] = useState<StatusUpdateResponse | null>();
  const [resolveSuccess, setResolveSuccess] = useState<boolean>(false);
  const [resolveFail, setResolveFail] = useState<boolean>(false);
  const [resolutionMessage, setResolutionMessage] = useState<ReactElement>();

  const [resolutionHeader, setResolutionHeader] = useState<string>("");
  const [headerError, setHeaderError] = useState<string>("");
  const [resolutionShortDesc, setResolutionShortDesc] = useState<string>("");
  const [shortDescError, setShortDescError] = useState<string>("");
  const [resolutionDescription, setResolutionDescription] = useState<string>("");

  const [statusUpdates, setStatusUpdates] = useState<StatusUpdateResponse[]>([]);
  const [timelineItems, setTimelineItems] = useState<StatusUpdateTimeline[]>([]);
  const [statusServices, setStatusServices] = useState<StatusServiceAffectedResponse[]>([]);

  const [activePage, setPage] = useState(1);
  const [alertsPerPage, setAlertsPerPage] = useState(10);
  const [maxPages, setMaxPages] = useState(10);

  const [userList, setUserList] = useState<GraphUser[]>([]);

  const [search, setSearch] = useState("");

  const { classes } = useStyles();

  type ResolvedAlertProps = {
    statusUpdate: StatusUpdateResponse;
    key: number;
  };

  function ResolvedAlertBox({ statusUpdate }: ResolvedAlertProps) {
    const relevantTimelineItems = timelineItems.filter(
      (tl) => tl.statusUpdateId === statusUpdate.statusUpdateId
    );

    const affectedServices = statusServices.filter(
      (ss) => ss.statusUpdateId === statusUpdate.statusUpdateId
    );

    return (
      <Box key={statusUpdate.statusUpdateId}>
        <Container fluid className={classes.formHeading}>
          <Stack spacing="xs">
            <Group position="apart">
              <Title order={3} color="white">
                Alert ID #{statusUpdate.statusUpdateId}
              </Title>
            </Group>
            {relevantTimelineItems.length > 0 && (
              <Text fz="xs" color="white">
                Last Updated at{" "}
                {new Date(
                  new Date(relevantTimelineItems.sort()[0].updateDateTime + "Z")
                ).toLocaleTimeString("en-US", {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",
                  timeZone: "America/New_York",
                  hour12: true,
                })}
              </Text>
            )}
          </Stack>
        </Container>
        <Paper withBorder p="sm" shadow="xs" mb="sm">
          <Stack>
            <Box>
              <Text>Impacting: </Text>
              {affectedServices.map((as: StatusServiceAffectedResponse) => {
                const recentStatusIcon = statusIcons.find((e) => {
                  return e.statusId === 2;
                })?.icon;

                return (
                  <Group key={as.serviceId}>
                    {recentStatusIcon}
                    <Text>{as.service?.serviceName}</Text>
                  </Group>
                );
              })}
            </Box>
            <Box>
              {relevantTimelineItems.length > 0 && (
                <Title order={6} color="gray">
                  Updates Timeline
                </Title>
              )}
              {relevantTimelineItems.sort().map((tl: StatusUpdateTimeline) => {
                const convertedTime = new Date(tl.updateDateTime + "Z");

                const userName = userList.filter(
                  (ul) => tl.createdByUserId === ul.graphUserId
                )[0]?.displayName;

                return (
                  <Paper
                    p="sm"
                    withBorder
                    radius="lg"
                    mb="xs"
                    key={tl.statusUpdateTimelineId}
                  >
                    <Group spacing="xs">
                      <Title order={4}>{tl.header} - </Title>
                      <Title order={5} color="gray" ml={-3}>
                        {" "}
                        {convertedTime.toLocaleTimeString("en-US", {
                          day: "numeric",
                          month: "numeric",
                          year: "numeric",
                          timeZone: "America/New_York",
                          hour12: true,
                        })}
                      </Title>
                    </Group>
                    <Text fz="sm" c="dimmed">
                      By {userName}
                    </Text>
                    <Text fz="sm" mt="sm">
                      {tl.shortDesc}
                    </Text>
                  </Paper>
                );
              })}
            </Box>
          </Stack>
        </Paper>
      </Box>
    );
  }

  async function loadData() {
    const user: GraphUser = await api.get(endpoints.getMe.url).json();

    setMe(user);

    const statusUpdatePull: StatusUpdateResponse[] = await api
      .get(`${endpoints.getStatusUpdateList.url}`)
      .json();

    setStatusUpdates(statusUpdatePull.filter((su) => su.resolved === true));

    setMaxPages(Math.ceil(statusUpdatePull.length / alertsPerPage));

    const statusTimelinePull: StatusUpdateTimeline[] = await api
      .get(`${endpoints.getStatusUpdateTimelineList.url}`)
      .json();

    setTimelineItems(statusTimelinePull);

    const statusServicesPull: StatusServiceAffectedResponse[] = await api
      .get(`${endpoints.getStatusServicesAffectedList.url}`)
      .json();

    setStatusServices(statusServicesPull);

    const users: GraphUser[] = await api.get(`${endpoints.getUserList.url}`).json();

    setUserList(users);

    setAlertListLoaded(true);
  }

  const allAlerts = useMemo(() => {
    let currentAllAlerts = statusUpdates;

    if (search) {
      let fSS = statusServices
        .filter((e) =>
          e.service?.serviceName.toLowerCase().includes(search.toLowerCase())
        )
        .map((e) => e.statusUpdateId);

      currentAllAlerts = currentAllAlerts.filter((e) =>
        fSS.includes(e.statusUpdateId)
      );

      setPage(1);
    }

    setMaxPages(Math.ceil(currentAllAlerts.length / alertsPerPage));

    return currentAllAlerts.slice(
      (activePage - 1) * alertsPerPage,
      (activePage - 1) * alertsPerPage + alertsPerPage
    );
  }, [statusUpdates, activePage, search, alertsPerPage]);

  function resetResolution() {
    setResolutionHeader("");
    setResolutionDescription("");
    setResolutionShortDesc("");
    setResolutionMessage(<></>);
    setHeaderError("");
    setShortDescError("");
    setResolveAgree(false);
    setResolveFail(false);
    setResolveSuccess(false);
    setResolveModalShow(false);
    loadData();
  }

  async function resolveStatusUpdate(statusUpdateId: number) {
    setHeaderError("");
    setShortDescError("");

    const resolvedByUserId = me!.graphUserId;

    console.log(resolvedByUserId);

    let err: boolean = false;

    if (resolutionHeader.length < 1) {
      setHeaderError("You must enter a Resolution Header");
      err = true;
    } else if (resolutionHeader.length > 100) {
      setHeaderError("Resolution Headers must be 100 characters or less.");
      err = true;
    }

    if (resolutionShortDesc.length < 1) {
      setShortDescError("You must enter a short description.");
      err = true;
    } else if (resolutionShortDesc.length > 200) {
      setShortDescError("Short descriptions must be 200 characters or less.");
      err = true;
    }

    if (!err) {
      const response: ResolveStatusUpdateResponse = await api
        .post(`${endpoints.resolveStatusUpdate.url}/${statusUpdateId}`, {
          json: {
            statusUpdateId: statusUpdateId,
            resolvedByUserId: resolvedByUserId,
            resolvedHeader: resolutionHeader,
            resolvedShortDesc: resolutionShortDesc,
            resolvedDescription: resolutionDescription,
          },
          timeout: 30000,
        })
        .json();
    
      setResolveSuccess(response.success);

      if (response.success) {
        setResolutionMessage(
          <>
            <Text>Alert successfully resolved!</Text>
            <Text mt="sm">
              A notification will be sent to all users subscribed to
              notifications.
            </Text>
          </>
        );
        const timer = setTimeout(() => {
          resetResolution();
        }, 3000);
        return () => clearTimeout(timer);
      } else {
        setResolveFail(true);
        setResolutionMessage(
          <Text>Error marking Alert as resolved. Please try again or contact the Innovation Lab for support.</Text>
        );
        const timer = setTimeout(() => {
          setResolutionMessage(<></>);
          setResolveFail(false);
        }, 3000);
        return () => clearTimeout(timer);
      }
    }
  }

  useEffect(() => {
    if (!isDataLoaded) {
      loadData();
      setIsDataLoaded(true);
    }
  }, [isDataLoaded]);

  return (
    <>
      <Modal
        opened={resolveModalShow}
        onClose={() => {
          setResolveModalShow(false);
        }}
        centered
        withCloseButton={false}
      >
        {resolveSuccess === false &&
          resolveFail === false &&
          resolveAgree === false && (
            <>
              <Title order={4}>
                Are you sure you want to resolve Alert #
                {selectedStatusUpdate?.statusUpdateId}?
              </Title>
              <Group grow mt="sm">
                <Button
                  color="red"
                  onClick={() => {
                    setSelectedStatusUpdate(null);
                    setResolveModalShow(false);
                  }}
                >
                  No
                </Button>
                <Button
                  color="teal"
                  onClick={() => {
                    setResolveAgree(true);
                  }}
                >
                  Yes
                </Button>
              </Group>
            </>
          )}
        {resolveAgree === true &&
          resolveSuccess === false &&
          resolveFail === false && (
            <>
              <Title order={5}>Resolution Details</Title>
              <Stack>
                <TextInput
                  withAsterisk
                  label="Resolution Header"
                  value={resolutionHeader}
                  onChange={(e) => setResolutionHeader(e.currentTarget.value)}
                  error={headerError}
                />
                <TextInput
                  withAsterisk
                  label="Resolution Short Description"
                  value={resolutionShortDesc}
                  onChange={(e) =>
                    setResolutionShortDesc(e.currentTarget.value)
                  }
                  error={shortDescError}
                />
                <TextInput
                  label="Resolution Description"
                  value={resolutionDescription}
                  onChange={(e) =>
                    setResolutionDescription(e.currentTarget.value)
                  }
                />
              </Stack>
              <Group grow mt="sm">
                <Button
                  color="red"
                  onClick={() => {
                    setSelectedStatusUpdate(null);
                    setResolveModalShow(false);
                    setResolveAgree(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  color="teal"
                  onClick={() => {
                    if (selectedStatusUpdate)
                        resolveStatusUpdate(selectedStatusUpdate.statusUpdateId);
                  }}
                >
                  Submit
                </Button>
              </Group>
            </>
          )}
        {resolveSuccess === true && resolveFail === false && (
          <>
            <Center>
              <Title order={4} color="teal">
                {resolutionMessage}
              </Title>
            </Center>
          </>
        )}

        {resolveFail === true && resolveSuccess === false && (
          <>
            <Center>
              <Title order={4} color="red">
                {resolutionMessage}
              </Title>
            </Center>
          </>
        )}
      </Modal>
      <Title order={1}>Resolved Alerts</Title>

      <Group position="apart" align="flex-end">
        <TextInput
          placeholder="Search by Impacted Service..."
          radius="xl"
          mb="sm"
          style={{ width: "50%" }}
          value={search}
          onChange={(e) => setSearch(e.currentTarget.value)}
        />
        <Select
          mb="sm"
          data={["5", "10", "15", "20"]}
          label="Alerts per page"
          value={alertsPerPage.toString()}
          onChange={(e) => setAlertsPerPage(parseInt(e!))}
        />
      </Group>

      {alertListLoaded &&
        allAlerts.map((su: StatusUpdateResponse) => {
          return <ResolvedAlertBox statusUpdate={su} key={su.statusUpdateId} />;
        })}

      <Center mt="md">
        <Pagination
          mb="md"
          page={activePage}
          onChange={setPage}
          total={maxPages}
        />
      </Center>
    </>
  );
};

export default ResolvedAlerts;
