import { useParams } from "react-router-dom";

import { useState, useEffect } from "react";
import { Box } from "@mantine/core";

import { EditAlert } from "components";

import externalApi from "../api";
import endpoints from "../api/endpoints";
import { useMsal } from "@azure/msal-react";
import { StatusUpdateResponse } from "types";

const EditServiceAlert = () => {
  const { statusUpdateId } = useParams();

  const { accounts } = useMsal();
  const account = accounts[0];

  const userIsTechnician: boolean | undefined =
    account?.idTokenClaims?.roles?.includes("ServiceHealth.Technician");

  const [resolvedCheck, setResolvedCheck] = useState<boolean>(false);

  const [edit, setEdit] = useState<boolean>(false);

  async function checkResolved() {
    if (statusUpdateId !== undefined) {
      const statusUpdate: StatusUpdateResponse = await externalApi
        .get(`${endpoints.useStatusUpdate.url}/${statusUpdateId}`)
        .json();

      if (statusUpdate) {
        const resolved = statusUpdate.resolved;

        if (resolved) {
          setEdit(false);
        } else if (userIsTechnician && !resolved) {
          setEdit(true);
        } else {
          setEdit(false);
        }

        setResolvedCheck(true);
      }
    }
  }

  useEffect(() => {
    if (!resolvedCheck) {
      checkResolved();
    }
  }, []);

  const [openUpdate, setOpenUpdate] = useState<boolean>(true);

  return (
    <Box p="lg">
      {statusUpdateId !== undefined && resolvedCheck && (
        <EditAlert
          statusUpdateId={parseInt(statusUpdateId)}
          edit={edit}
          openUpdate={openUpdate}
          setOpenUpdate={setOpenUpdate}
          fromDash={false}
        />
      )}
    </Box>
  );
};

export default EditServiceAlert;
