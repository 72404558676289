import {
  Modal,
  Text,
  Group,
  Center,
  Title,
  TextInput,
  Stack,
  Button,
  Loader,
} from "@mantine/core";

import { Dispatch, SetStateAction, useState, ReactElement } from "react";
import {
  StatusUpdateResponse,
  ResolveStatusUpdateResponse,
  GraphUser,
} from "types";
import { useForm } from "@mantine/form";

import api from "../../api";
import endpoints from "../../api/endpoints";

type ServiceAlertModalProps = {
  opened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
  selectedStatusUpdate: StatusUpdateResponse | null | undefined;
  setSelectedStatusUpdate: Dispatch<
    SetStateAction<StatusUpdateResponse | null | undefined>
  >;
  me: GraphUser | undefined;
  renavRefresh: () => void;
};

export const ResolveAlertModal = ({
  opened,
  setOpened,
  selectedStatusUpdate,
  setSelectedStatusUpdate,
  me,
  renavRefresh,
}: ServiceAlertModalProps) => {
  const [resolveSuccess, setResolveSuccess] = useState<boolean>(false);
  const [resolveFail, setResolveFail] = useState<boolean>(false);
  const [resolveAgree, setResolveAgree] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [resolutionMessage, setResolutionMessage] = useState<ReactElement>();

  const resolutionForm = useForm<any>({
    initialValues: {
      statusUpdateId: "",
      resolvedByUserId: "",
      resolvedHeader: "",
      resolvedShortDesc: "",
      resolvedDescription: "",
    },

    validate: (values) => {
      return {
        resolvedHeader:
          values.resolvedHeader.trim().length < 1
            ? "You must include a header message."
            : null,
        resolvedShortDesc:
          values.resolvedShortDesc.trim().length < 1
            ? "You must include a short description."
            : values.resolvedShortDesc.trim().length > 300
            ? "Short Description must be 300 characters or less"
            : null,
      };
    },
  });

  function resetResolution() {
    resolutionForm.reset();
    setResolutionMessage(<></>);
    setResolveAgree(false);
    setResolveFail(false);
    setResolveSuccess(false);
    setOpened(false);
  }

  async function resolveStatusUpdate(statusUpdateId: number) {
    setIsSubmitting(true);

    const resolvedByUserId = me!.graphUserId;

    resolutionForm.validate();

    if (resolutionForm.isValid()) {
      setResolveAgree(false);
      console.log("not err");
      const response: ResolveStatusUpdateResponse = await api
        .post(`${endpoints.resolveStatusUpdate.url}/${statusUpdateId}`, {
          json: {
            statusUpdateId: statusUpdateId,
            resolvedByUserId: resolvedByUserId,
            resolvedHeader: resolutionForm.values["resolvedHeader"],
            resolvedShortDesc: resolutionForm.values["resolvedShortDesc"],
            resolvedDescription: resolutionForm.values["resolvedDescription"],
          },
          timeout: 30000,
        })
        .json();

      setResolveSuccess(response.success);

      if (response.success) {
        setResolutionMessage(
          <>
            <Text>Alert successfully resolved!</Text>
            <Text mt="sm">
              A notification will be sent to all users subscribed to
              notifications.
            </Text>
          </>
        );

        const timer = setTimeout(() => {
          setIsSubmitting(false);
          resetResolution();
          renavRefresh();
        }, 3000);
        return () => clearTimeout(timer);
      } else {
        setResolutionMessage(
          <Text>
            Error marking Alert as resolved. Please try again or contact the
            Innovation Lab for support.
          </Text>
        );
        const timer = setTimeout(() => {
          setResolutionMessage(<></>);
          setIsSubmitting(false);
          setResolveFail(false);
          setResolveSuccess(false);
          setResolveAgree(true);
        }, 3000);
        return () => clearTimeout(timer);
      }
    } else {
      setResolveFail(true);
      setResolutionMessage(
        <Text>
          Error marking Alert as resolved. Please try again or contact the
          Innovation Lab for support.
        </Text>
      );
      const timer = setTimeout(() => {
        setResolutionMessage(<></>);
        setIsSubmitting(false);
        setResolveFail(false);
        setResolveSuccess(false);
        setResolveAgree(true);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => {
          setOpened(false);
          renavRefresh();
        }}
        centered
        withCloseButton={false}
      >
        {resolveAgree === false &&
          resolveSuccess === false &&
          resolveFail === false && (
            <>
              {!isSubmitting && (
                <>
                  <Title order={4}>
                    Are you sure you want to resolve Alert #
                    {selectedStatusUpdate?.statusUpdateId}?
                  </Title>
                  <Group grow mt="sm">
                    <Button
                      color="red"
                      onClick={() => {
                        setSelectedStatusUpdate(null);
                        setOpened(false);
                      }}
                    >
                      No
                    </Button>
                    <Button
                      color="teal"
                      onClick={() => {
                        setResolveAgree(true);
                      }}
                    >
                      Yes
                    </Button>
                  </Group>
                </>
              )}
              {isSubmitting && (
                <Center>
                  <Loader />
                </Center>
              )}
            </>
          )}
        {resolveAgree === true && (
          <>
            <Title order={5}>Resolution Details</Title>
            <Stack>
              <TextInput
                withAsterisk
                label="Resolution Header"
                {...resolutionForm.getInputProps("resolvedHeader")}
              />
              <TextInput
                withAsterisk
                label="Resolution Short Description"
                {...resolutionForm.getInputProps("resolvedShortDesc")}
              />
              <TextInput
                label="Resolution Description"
                {...resolutionForm.getInputProps("resolvedDescription")}
              />
            </Stack>
            <Group grow mt="sm">
              <Button
                color="red"
                onClick={() => {
                  setSelectedStatusUpdate(null);
                  setOpened(false);
                  setResolveAgree(false);
                  resolutionForm.reset();
                }}
              >
                Cancel
              </Button>
              <Button
                color="teal"
                loading={isSubmitting}
                onClick={() => {
                  if (selectedStatusUpdate)
                    resolveStatusUpdate(selectedStatusUpdate.statusUpdateId);
                }}
              >
                Submit
              </Button>
            </Group>
          </>
        )}
        {resolveSuccess === true && resolveFail === false && (
          <>
            <Center>
              <Title order={4} color="teal">
                {resolutionMessage}
              </Title>
            </Center>
          </>
        )}

        {resolveFail === true && resolveSuccess === false && (
          <>
            <Center>
              <Title order={4} color="red">
                {resolutionMessage}
              </Title>
            </Center>
          </>
        )}
      </Modal>
    </>
  );
};
