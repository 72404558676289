import { useState, useEffect, Dispatch, SetStateAction } from "react";
import {
  Center,
  Table,
  Group,
  Button,
  Text,
  ScrollArea,
  Collapse,
  Menu,
  ActionIcon,
} from "@mantine/core";

import { useDisclosure, useMediaQuery } from "@mantine/hooks";

import externalApi from "../api";
import endpoints from "../api/endpoints";

import { List, Eye, Edit, CircleCheck } from "tabler-icons-react";

import {
  ResolveStatusAlert,
  EditAlert,
} from "components";

import { StatusUpdateResponse, StatusUpdateTimeline } from "types";

export const ExistingServiceAlerts = ({
  serviceId,
  isModal,
  isOpened,
  setIsOpened,
  renavRefresh,
  setModalTitle,
}: {
  serviceId?: number;
  isModal: boolean;
  isOpened?: boolean;
  setIsOpened?: Dispatch<SetStateAction<boolean>>;
  renavRefresh: () => void;
  setModalTitle: Dispatch<SetStateAction<string>>;
}) => {
  const [statusUpdatesByService, setStatusUpdatesByService] = useState<StatusUpdateResponse[]>([]);
  const [resolvedUpdates, setResolvedUpdates] = useState<StatusUpdateResponse[]>([]);
  const [timelinesList, setTimelinesList] = useState<StatusUpdateTimeline[]>([]);

  const [openUpdate, setOpenUpdate] = useState(false);
  const [openResolve, setOpenResolve] = useState(false);

  const [selectedStatusUpdate, setSelectedStatusUpdate] = useState<StatusUpdateResponse>();
  const [isEdit, setIsEdit] = useState(false);

  const [opened, { toggle }] = useDisclosure(false);

  const tablet = useMediaQuery("(max-width: 1235px) and (min-width: 631px)");
  const phone = useMediaQuery("(max-width: 630px)");

  async function loadStatusUpdates(serviceId: number | undefined) {
    const statusUpdateListFromApi: StatusUpdateResponse[] = await externalApi
      .get(`${endpoints.getStatusUpdateListByService.url}/${serviceId}/true`)
      .json();

    setStatusUpdatesByService(
      statusUpdateListFromApi.filter((su) => su.resolved === false)
    );

    setResolvedUpdates(
      statusUpdateListFromApi.filter((su) => su.resolved === true)
    );

    const timelines: StatusUpdateTimeline[] = await externalApi
      .get(`${endpoints.getStatusUpdateTimelineList.url}`)
      .json();

    setTimelinesList(timelines);
  }

  function viewStatusUpdate(statusUpdate: StatusUpdateResponse, isEdit: boolean) {
    setSelectedStatusUpdate(statusUpdate);
    setIsEdit(isEdit);
    setOpenUpdate(true);
  }

  function resolveStatusUpdate(statusUpdate: StatusUpdateResponse) {
    setSelectedStatusUpdate(statusUpdate);
    setOpenResolve(true);
  }

  const rows = statusUpdatesByService.map((upd) => {
    const mostRecentTimelineItem = timelinesList
      .filter((tl) => tl.statusUpdateId === upd.statusUpdateId)
      .sort()[0];

    return (
      <tr key={upd.statusUpdateId}>
        <td>
          {" "}
          <Text fz={phone ? "xs" : tablet ? "sm" : "sm"} fw={700} lineClamp={1}>
            {mostRecentTimelineItem?.header}
          </Text>
          <Text fz={phone ? "xs" : tablet ? "sm" : "sm"} lineClamp={1}>
            {mostRecentTimelineItem?.shortDesc}
          </Text>
        </td>
        <td>
          {phone && (
            <Text fz={phone ? "xs" : tablet ? "sm" : "sm"}>
              {new Date(new Date(upd.createDateTime) + "Z").toLocaleDateString(
                "en-US",
                {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",
                  timeZone: "America/New_York",
                  hour12: true,
                }
              )}
            </Text>
          )}
          {tablet && (
            <Text fz={phone ? "xs" : tablet ? "sm" : "sm"}>
              {new Date(new Date(upd.createDateTime) + "Z").toLocaleDateString(
                "en-US",
                {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",
                  timeZone: "America/New_York",
                  hour12: true,
                }
              )}
            </Text>
          )}
          {!phone && !tablet && (
            <Text fz={phone ? "xs" : tablet ? "sm" : "sm"}>
              {new Date(new Date(upd.createDateTime) + "Z").toLocaleTimeString(
                "en-US",
                {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",
                  timeZone: "America/New_York",
                  hour12: true,
                }
              )}
            </Text>
          )}
        </td>
        <td>
          {phone && mostRecentTimelineItem && (
            <Text fz={phone ? "xs" : tablet ? "sm" : "sm"}>
              {new Date(new Date(upd.createDateTime) + "Z").toLocaleDateString(
                "en-US",
                {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",
                  timeZone: "America/New_York",
                  hour12: true,
                }
              )}
            </Text>
          )}
          {tablet && mostRecentTimelineItem && (
            <Text fz={phone ? "xs" : tablet ? "sm" : "sm"}>
              {new Date(new Date(upd.createDateTime) + "Z").toLocaleDateString(
                "en-US",
                {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",
                  timeZone: "America/New_York",
                  hour12: true,
                }
              )}
            </Text>
          )}
          {!phone && !tablet && mostRecentTimelineItem && (
            <Text fz={phone ? "xs" : tablet ? "sm" : "sm"}>
              {new Date(new Date(upd.createDateTime) + "Z").toLocaleTimeString(
                "en-US",
                {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",
                  timeZone: "America/New_York",
                  hour12: true,
                }
              )}
            </Text>
          )}
          {!mostRecentTimelineItem && ""}
        </td>
        {tablet && (
          <td>
            <Group noWrap>
              <Button
                compact
                color="orange"
                onClick={() => {
                  viewStatusUpdate(upd, false);
                }}
              >
                View
              </Button>
              <Button
                compact
                color="blue"
                onClick={() => {
                  viewStatusUpdate(upd, true);
                }}
              >
                Edit
              </Button>
              <Button
                compact
                color="green"
                onClick={() => {
                  resolveStatusUpdate(upd);
                }}
              >
                Resolve
              </Button>
            </Group>
          </td>
        )}
        {phone && (
          <td>
            <Menu
              // key={service.service.service.serviceId}
              // shadow="md"
              // width={200}
              // opened={menuOpened}
              // onChange={setMenuOpened}
              position="bottom-start"
              offset={0}
            >
              <Menu.Target>
                <ActionIcon
                  color={"teal.7"}
                  variant="outline"
                  radius="xl"
                  size={phone ? "sm" : tablet ? "md" : "lg"}
                >
                  <List size={phone ? "1rem" : "1.25rem"} />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  icon={<Eye size={14} />}
                  fz="sm"
                  onClick={() => {
                    viewStatusUpdate(upd, false);
                  }}
                >
                  View
                </Menu.Item>

                <Menu.Divider />

                <Menu.Item
                  icon={<Edit size={14} />}
                  fz="sm"
                  onClick={() => {
                    viewStatusUpdate(upd, true);
                  }}
                >
                  Edit
                </Menu.Item>

                <Menu.Divider />

                <Menu.Item
                  icon={<CircleCheck size={14} />}
                  fz="sm"
                  onClick={() => {
                    resolveStatusUpdate(upd);
                  }}
                >
                  Resolve
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </td>
        )}
        {!tablet && !phone && (
          <td>
            <Group noWrap>
              <Button
                compact
                color="orange"
                onClick={() => {
                  viewStatusUpdate(upd, false);
                }}
              >
                View
              </Button>
              <Button
                compact
                color="blue"
                onClick={() => {
                  viewStatusUpdate(upd, true);
                }}
              >
                Edit
              </Button>
              <Button
                compact
                color="green"
                onClick={() => {
                  resolveStatusUpdate(upd);
                }}
              >
                Resolve
              </Button>
            </Group>
          </td>
        )}
      </tr>
    );
  });

  const resolvedRows = resolvedUpdates.map((upd) => {
    const mostRecentTimelineItem = timelinesList
      .filter((tl) => tl.statusUpdateId === upd.statusUpdateId)
      .sort()[0];

    return (
      <tr key={upd.statusUpdateId}>
        <td>
          <Text fz={phone ? "xs" : tablet ? "sm" : "sm"} fw={700} lineClamp={1}>
            {mostRecentTimelineItem?.header}
          </Text>
          <Text fz={phone ? "xs" : tablet ? "sm" : "sm"} lineClamp={1}>
            {" "}
            {mostRecentTimelineItem?.shortDesc}
          </Text>
        </td>
        <td>
          {phone && (
            <Text fz={phone ? "xs" : tablet ? "sm" : "sm"}>
              {new Date(new Date(upd.createDateTime) + "Z").toLocaleDateString(
                "en-US",
                {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",
                  timeZone: "America/New_York",
                  hour12: true,
                }
              )}
            </Text>
          )}
          {tablet && (
            <Text fz={phone ? "xs" : tablet ? "sm" : "sm"}>
              {new Date(new Date(upd.createDateTime) + "Z").toLocaleDateString(
                "en-US",
                {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",
                  timeZone: "America/New_York",
                  hour12: true,
                }
              )}
            </Text>
          )}
          {!phone && !tablet && (
            <Text fz={phone ? "xs" : tablet ? "sm" : "sm"}>
              {new Date(new Date(upd.createDateTime) + "Z").toLocaleTimeString(
                "en-US",
                {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",
                  timeZone: "America/New_York",
                  hour12: true,
                }
              )}
            </Text>
          )}
        </td>
        <td>
          {phone && mostRecentTimelineItem && (
            <Text fz={phone ? "xs" : tablet ? "sm" : "sm"}>
              {new Date(new Date(upd.createDateTime) + "Z").toLocaleDateString(
                "en-US",
                {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",
                  timeZone: "America/New_York",
                  hour12: true,
                }
              )}
            </Text>
          )}
          {tablet && mostRecentTimelineItem && (
            <Text fz={phone ? "xs" : tablet ? "sm" : "sm"}>
              {new Date(new Date(upd.createDateTime) + "Z").toLocaleDateString(
                "en-US",
                {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",
                  timeZone: "America/New_York",
                  hour12: true,
                }
              )}
            </Text>
          )}
          {!phone && !tablet && mostRecentTimelineItem && (
            <Text fz={phone ? "xs" : tablet ? "sm" : "sm"}>
              {new Date(new Date(upd.createDateTime) + "Z").toLocaleTimeString(
                "en-US",
                {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",
                  timeZone: "America/New_York",
                  hour12: true,
                }
              )}
            </Text>
          )}

          {!mostRecentTimelineItem && ""}
        </td>
        <td>
          {phone && (
            <ActionIcon
              color={"orange.7"}
              variant="outline"
              radius="xl"
              onClick={() => {
                viewStatusUpdate(upd, false);
              }}
              size={phone ? "sm" : tablet ? "md" : "lg"}
            >
              <Eye size={phone ? "1rem" : "1.25rem"} />
            </ActionIcon>
          )}
          {tablet && (
            <ActionIcon
              color={"orange.7"}
              variant="outline"
              radius="xl"
              onClick={() => {
                viewStatusUpdate(upd, false);
              }}
              size={phone ? "sm" : tablet ? "md" : "lg"}
            >
              <Eye size={phone ? "1rem" : "1.25rem"} />
            </ActionIcon>
          )}
          {!tablet && !phone && (
            <Group noWrap>
              <Button
                compact
                color="orange"
                onClick={() => {
                  viewStatusUpdate(upd, false);
                }}
              >
                View
              </Button>
            </Group>
          )}
        </td>
      </tr>
    );
  });

  useEffect(() => {
    loadStatusUpdates(serviceId);
    if (openUpdate) {
      setModalTitle("Alert ");
    } else if (openResolve) {
      setModalTitle("Resolving Alert ");
    } else {
      setModalTitle("Existing Alerts ");
    }
  }, [serviceId, openUpdate, openResolve]);

  return (
    <>
      <ScrollArea style={{ minHeight: tablet ? 300 : phone ? 500 : 0 }}>
        {!openUpdate && !openResolve && (
          <>
            <Table
              fontSize={phone ? "xs" : tablet ? "sm" : "md"}
              style={{ tableLayout: phone ? "fixed" : "auto" }}
            >
              <thead>
                {phone && (
                  <tr>
                    <th style={{ width: "40%" }}>Most Recent Update</th>
                    <th>{phone ? "Created" : "Alert Created"}</th>
                    <th>{phone ? "Updated" : "Last Updated"}</th>
                    <th style={{ width: "10%" }}> </th>
                  </tr>
                )}
                {!phone && (
                  <tr>
                    <th>Most Recent Update</th>
                    <th>{phone ? "Created" : "Alert Created"}</th>
                    <th>{phone ? "Updated" : "Last Updated"}</th>
                    <th> </th>
                  </tr>
                )}
              </thead>
              <tbody>{rows}</tbody>
            </Table>
            {phone && (
              <Center>
                <Button
                  variant="outline"
                  compact
                  size="sm"
                  color="green"
                  onClick={toggle}
                  mt="md"
                >
                  {opened && "Hide "} {!opened && "Show "}Resolved Alerts
                </Button>
              </Center>
            )}
            {!phone && (
              <Button
                variant="outline"
                compact
                size="sm"
                color="green"
                onClick={toggle}
                mt="md"
              >
                {opened && "Hide "} {!opened && "Show "}Resolved Alerts
              </Button>
            )}

            <Collapse in={opened}>
              <Table
                fontSize={phone ? "xs" : tablet ? "sm" : "md"}
                style={{ tableLayout: phone ? "fixed" : "auto" }}
              >
                <thead>
                  {phone && (
                    <tr>
                      <th style={{ width: "30%" }}>Most Recent Update</th>
                      <th>{phone ? "Created" : "Alert Created"}</th>
                      <th>{phone ? "Updated" : "Last Updated"}</th>
                      <th style={{ width: "10%" }}> </th>
                    </tr>
                  )}
                  {!phone && (
                    <tr>
                      <th>Most Recent Update</th>
                      <th>{phone ? "Created" : "Alert Created"}</th>
                      <th>{phone ? "Updated" : "Last Updated"}</th>
                      <th> </th>
                    </tr>
                  )}
                </thead>
                <tbody>{resolvedRows}</tbody>
              </Table>
            </Collapse>
          </>
        )}

        {openUpdate && !openResolve && (
          <EditAlert
            statusUpdateId={selectedStatusUpdate!.statusUpdateId}
            edit={isEdit}
            openUpdate={openUpdate}
            setOpenUpdate={setOpenUpdate}
            fromDash={true}
          />
        )}
        {openResolve && !openUpdate && (
          <ResolveStatusAlert
            statusUpdate={selectedStatusUpdate!}
            openResolve={openResolve}
            setOpenResolve={setOpenResolve}
          />
        )}
      </ScrollArea>
    </>
  );
};
