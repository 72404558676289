import { useState, useEffect, Dispatch, SetStateAction, ReactElement } from "react";
import {
  Stack,
  Center,
  Paper,
  Group,
  Button,
  TextInput,
  Text,
  Anchor,
  Title,
} from "@mantine/core";

import externalApi from "../api";
import endpoints from "../api/endpoints";

import { Link } from "react-router-dom";

import { StatusUpdateResponse, ResolveStatusUpdateResponse, Service, GraphUser, StatusServiceAffectedResponse, SendNotificationResponse } from "types";

import { CircleCheck, AlertTriangle, Tool, CircleX } from "tabler-icons-react";

type ViewAlertProps = {
  openResolve: boolean;
  setOpenResolve: Dispatch<SetStateAction<boolean>>;
  statusUpdate: StatusUpdateResponse;
};

export const ResolveAlert = ({
  statusUpdate,
  openResolve,
  setOpenResolve,
}: ViewAlertProps) => {
  const [me, setMe] = useState<GraphUser>();

  const [resolutionHeader, setResolutionHeader] = useState<string>("");
  const [headerError, setHeaderError] = useState<string>("");
  const [resolutionShortDesc, setResolutionShortDesc] = useState<string>("");
  const [shortDescError, setShortDescError] = useState<string>("");
  const [resolutionDescription, setResolutionDescription] = useState<string>("");

  const [resolveAgree, setResolveAgree] = useState<boolean>(false);
  const [resolveSuccess, setResolveSuccess] = useState<boolean>(false);
  const [resolveFail, setResolveFail] = useState<boolean>(false);
  const [resolutionMessage, setResolutionMessage] = useState<ReactElement>();

  const [servicesList, setServicesList] = useState<Service[]>([]);
  const [impactedServices, setImpactedServices] = useState<StatusServiceAffectedResponse[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  function setFields() {
    setResolutionHeader("");
    setResolutionShortDesc("");
    setResolutionDescription("");
  }

  function resetResolution() {
    setResolutionHeader("");
    setResolutionDescription("");
    setResolutionShortDesc("");
    setResolutionMessage(<></>);
    setHeaderError("");
    setShortDescError("");
    setResolveAgree(false);
    setResolveFail(false);
    setResolveSuccess(false);
    setOpenResolve(false);
  }

  const statusList = [
    {
      image: <CircleCheck color="green" />,
      label: "Available",
      value: "2",
      statusId: 2,
      color: "green",
    },
    {
      image: <AlertTriangle color="orange" />,
      label: "Issue",
      value: "4",
      statusId: 4,
      color: "orange",
    },
    {
      image: <CircleX color="red" />,
      label: "Outage",
      value: "5",
      statusId: 5,
      color: "red",
    },
    {
      image: <Tool color="blue" />,
      label: "Maintenance",
      value: "3",
      statusId: 3,
      color: "blue",
    },
  ];

  async function loadStatusUpdate(statusUpdateId: number) {
    const ssa: StatusServiceAffectedResponse[] = await externalApi
      .get(`${endpoints.getStatusServicesAffectedListByStatusUpdate.url}/${statusUpdateId}`)
      .json();

    setImpactedServices(ssa);

    const allServices: Service[] = await externalApi
      .get(`${endpoints.getServiceList.url}`)
      .json();

    setServicesList(allServices);
  }

  async function loadUser() {
    const user: GraphUser = await externalApi.get(endpoints.getMe.url).json();

    setMe(user);
  }

  async function resolveStatusUpdate(statusUpdateId: number) {
    setIsSubmitting(true);
    setHeaderError("");
    setShortDescError("");

    const resolvedByUserId = me!.graphUserId;

    let err: boolean = false;

    if (resolutionHeader.length < 1) {
      setHeaderError("You must enter a Resolution Header");
      err = true;
    } else if (resolutionHeader.length > 100) {
      setHeaderError("Resolution Headers must be 100 characters or less.");
      err = true;
    }

    if (resolutionShortDesc.length < 1) {
      setShortDescError("You must enter a short description.");
      err = true;
    } else if (resolutionShortDesc.length > 300) {
      setShortDescError("Short descriptions must be 300 characters or less.");
      err = true;
    }

    if (!err) {
      const response: ResolveStatusUpdateResponse = await externalApi
        .post(`${endpoints.resolveStatusUpdate.url}/${statusUpdateId}`, {
          json: {
            statusUpdateId: statusUpdateId,
            resolvedByUserId: resolvedByUserId,
            resolvedHeader: resolutionHeader,
            resolvedShortDesc: resolutionShortDesc,
            resolvedDescription: resolutionDescription,
          },
          timeout: 30000,
        })
        .json();

      if (response.success) {
        const emailResponse: SendNotificationResponse = await externalApi
          .post(
            `${endpoints.sendNotification.url}/${response.statusUpdateTimelineId}`
          )
          .json();

        setResolveSuccess(true);

        if (emailResponse.success) {
          setIsSubmitting(false);
          setResolutionMessage(
            <>
              <Text>Alert successfully resolved!</Text>
              <Text mt="sm">
                A notification will be sent to all users subscribed to
                notifications.
              </Text>
            </>
          );
        } else {
          setIsSubmitting(false);
          setResolutionMessage(
            <>
              <Text>Alert successfully resolved!</Text>
              <Text mt="sm" c="red">
                There was an issue sending notifications to users about this
                resolution.
              </Text>
            </>
          );
        }

        const timer = setTimeout(() => {
          resetResolution();
        }, 3000);
        return () => clearTimeout(timer);
      } else {
        setResolveFail(true);
        setResolutionMessage(
          <Text>Error marking Alert as resolved. Please try again or contact the Innovation Lab for support.</Text>
        );
        const timer = setTimeout(() => {
          setIsSubmitting(false);
          setResolutionMessage(<></>);
          setResolveFail(false);
          setResolveSuccess(false);
        }, 3000);
        return () => clearTimeout(timer);
      }
    }
  }

  useEffect(() => {
    loadUser();
    loadStatusUpdate(statusUpdate.statusUpdateId);
    setFields();
  }, []);

  return (
    <>
      {resolveSuccess === false &&
        resolveFail === false &&
        resolveAgree === false && (
          <>
            <Stack align="center">
              <Text>
                This Status Alert is currently impacting the following services:
              </Text>
              {impactedServices.map((item, index: number) => {
                const serviceName = servicesList.filter(
                  (svc) => svc.serviceId === item.serviceId
                )[0]?.serviceName;

                const statusName = statusList.filter(
                  (st) => st.statusId === item.statusId
                )[0]?.label;

                const statusColour = statusList.filter(
                  (st) => st.statusId === item.statusId
                )[0]?.color;

                return (
                  <Group key={index}>
                    <Text fw={700}>{serviceName}</Text>
                    {" - "}
                    <Text fw={500} c={statusColour}>
                      {statusName}
                    </Text>
                  </Group>
                );
              })}
              <Text align="center" fz="sm">
                Please ensure all impacted Services are in working order before
                resolving the alert.
              </Text>
              <Text align="center" fz="sm">
                If you'd like to set this specific Service's status back to
                Available, please
                <Anchor
                  component={Link}
                  to={`/edit-alert/${statusUpdate.statusUpdateId}`}
                >
                  {" "}
                  edit the alert{" "}
                </Anchor>
                instead of resolving it.
              </Text>
              <Paper withBorder p="sm">
                <Title order={4}>
                  Are you sure you want to resolve Alert #
                  {statusUpdate?.statusUpdateId}?
                </Title>
                <Group grow mt="sm">
                  <Button
                    color="red"
                    onClick={() => {
                      setOpenResolve(false);
                    }}
                  >
                    No
                  </Button>
                  <Button
                    color="teal"
                    onClick={() => {
                      setResolveAgree(true);
                    }}
                  >
                    Yes
                  </Button>
                </Group>
              </Paper>
            </Stack>
          </>
        )}
      {resolveAgree === true &&
        resolveSuccess === false &&
        resolveFail === false && (
          <>
            <Title order={5}>Resolution Details</Title>
            <Stack>
              <TextInput
                withAsterisk
                label="Resolution Header"
                value={resolutionHeader}
                onChange={(e) => setResolutionHeader(e.currentTarget.value)}
                error={headerError}
              />
              <TextInput
                withAsterisk
                label="Resolution Short Description"
                value={resolutionShortDesc}
                onChange={(e) => setResolutionShortDesc(e.currentTarget.value)}
                error={shortDescError}
              />
              <TextInput
                label="Resolution Description"
                value={resolutionDescription}
                onChange={(e) =>
                  setResolutionDescription(e.currentTarget.value)
                }
              />
            </Stack>
            <Group grow mt="sm">
              <Button
                color="red"
                onClick={() => {
                  setOpenResolve(false);
                  setResolveAgree(false);
                }}
              >
                Cancel
              </Button>
              <Button
                color="teal"
                loading={isSubmitting}
                onClick={() => {
                  resolveStatusUpdate(statusUpdate?.statusUpdateId);
                }}
              >
                Submit
              </Button>
            </Group>
          </>
        )}
      {resolveSuccess === true && resolveFail === false && (
        <>
          <Center>
            <Title order={4} color="teal">
              {resolutionMessage}
            </Title>
          </Center>
        </>
      )}

      {resolveFail === true && resolveSuccess === false && (
        <>
          <Center>
            <Title order={4} color="red">
              {resolutionMessage}
            </Title>
          </Center>
        </>
      )}
    </>
  );
};
