import { CircleCheck, AlertTriangle, Tool, CircleX } from "tabler-icons-react";
import { StatusIcon } from "types";

export const statusIcons: StatusIcon[] = [
  {
    statusName: "available",
    statusId: 2,
    icon: <CircleCheck color="green" />,
  },
  {
    statusName: "incident",
    statusId: 4,
    icon: <AlertTriangle color="orange" />,
  },
  {
    statusName: "outage",
    statusId: 5,
    icon: <CircleX color="red" />,
  },
  {
    statusName: "maintenance",
    statusId: 3,
    icon: <Tool color="blue" />,
  },
];

export const allServicesData = [
  {
    id: 1,
    isFavourite: true,
    name: "Sample Service 1",
    LHOstatus: "available",
    LHBstatus: "available",
    LHAPstatus: "available",
    LHPPstatus: "available",
    LHWstatus: "available",
    notificationEnabled: true,
  },
  {
    id: 2,
    isFavourite: true,
    name: "Sample Service 2",
    LHOstatus: "available",
    LHBstatus: "outage",
    LHAPstatus: "available",
    LHPPstatus: "available",
    LHWstatus: "available",
    notificationEnabled: true,
  },
  {
    id: 3,
    isFavourite: true,
    name: "Sample Service 3",
    LHOstatus: "incident",
    LHBstatus: "incident",
    LHAPstatus: "incident",
    LHPPstatus: "incident",
    LHWstatus: "incident",
    notificationEnabled: false,
  },
  {
    id: 4,
    isFavourite: true,
    name: "Sample Service 4",
    LHOstatus: "available",
    LHBstatus: "available",
    LHAPstatus: "maintenance",
    LHPPstatus: "available",
    LHWstatus: "available",
    notificationEnabled: true,
  },
  {
    id: 5,
    isFavourite: true,
    name: "Sample Service 5",
    LHOstatus: "available",
    LHBstatus: "available",
    LHAPstatus: "available",
    LHPPstatus: "available",
    LHWstatus: "outage",
    notificationEnabled: true,
  },
  {
    id: 6,
    isFavourite: false,
    name: "Sample Service 6",
    LHOstatus: "outage",
    LHBstatus: "outage",
    LHAPstatus: "outage",
    LHPPstatus: "outage",
    LHWstatus: "outage",
    notificationEnabled: true,
  },
  {
    id: 7,
    isFavourite: false,
    name: "Sample Service 7",
    LHOstatus: "available",
    LHBstatus: "outage",
    LHAPstatus: "available",
    LHPPstatus: "available",
    LHWstatus: "available",
    notificationEnabled: true,
  },
  {
    id: 8,
    isFavourite: false,
    name: "Sample Service 8",
    LHOstatus: "incident",
    LHBstatus: "incident",
    LHAPstatus: "incident",
    LHPPstatus: "incident",
    LHWstatus: "incident",
    notificationEnabled: false,
  },
  {
    id: 9,
    isFavourite: false,
    name: "Sample Service 9",
    LHOstatus: "maintenance",
    LHBstatus: "maintenance",
    LHAPstatus: "maintenance",
    LHPPstatus: "maintenance",
    LHWstatus: "maintenance",
    notificationEnabled: true,
  },
  {
    id: 10,
    isFavourite: false,
    name: "Sample Service 10",
    LHOstatus: "available",
    LHBstatus: "available",
    LHAPstatus: "available",
    LHPPstatus: "available",
    LHWstatus: "outage",
    notificationEnabled: true,
  },
];

export const myTableData = [
  {
    id: 1,
    isFavourite: true,
    name: "Sample Service 1",
    LHOstatus: "available",
    LHBstatus: "available",
    LHAPstatus: "available",
    LHPPstatus: "available",
    LHWstatus: "available",
    notificationEnabled: true,
  },
  {
    id: 2,
    isFavourite: true,
    name: "Sample Service 2",
    LHOstatus: "available",
    LHBstatus: "outage",
    LHAPstatus: "available",
    LHPPstatus: "available",
    LHWstatus: "available",
    notificationEnabled: true,
  },
  {
    id: 3,
    isFavourite: true,
    name: "Sample Service 3",
    LHOstatus: "incident",
    LHBstatus: "incident",
    LHAPstatus: "incident",
    LHPPstatus: "incident",
    LHWstatus: "incident",
    notificationEnabled: false,
  },
  {
    id: 4,
    isFavourite: true,
    name: "Sample Service 4",
    LHOstatus: "available",
    LHBstatus: "available",
    LHAPstatus: "maintenance",
    LHPPstatus: "available",
    LHWstatus: "available",
    notificationEnabled: true,
  },
  {
    id: 5,
    isFavourite: true,
    name: "Sample Service 5",
    LHOstatus: "available",
    LHBstatus: "available",
    LHAPstatus: "available",
    LHPPstatus: "available",
    LHWstatus: "outage",
    notificationEnabled: true,
  },
];

export const allTableData = [
  {
    id: 6,
    isFavourite: true,
    name: "Sample Service 6",
    LHOstatus: "outage",
    LHBstatus: "outage",
    LHAPstatus: "outage",
    LHPPstatus: "outage",
    LHWstatus: "outage",
    notificationEnabled: true,
  },
  {
    id: 7,
    isFavourite: true,
    name: "Sample Service 7",
    LHOstatus: "available",
    LHBstatus: "outage",
    LHAPstatus: "available",
    LHPPstatus: "available",
    LHWstatus: "available",
    notificationEnabled: true,
  },
  {
    id: 8,
    isFavourite: true,
    name: "Sample Service 8",
    LHOstatus: "incident",
    LHBstatus: "incident",
    LHAPstatus: "incident",
    LHPPstatus: "incident",
    LHWstatus: "incident",
    notificationEnabled: false,
  },
  {
    id: 9,
    isFavourite: true,
    name: "Sample Service 9",
    LHOstatus: "maintenance",
    LHBstatus: "maintenance",
    LHAPstatus: "maintenance",
    LHPPstatus: "maintenance",
    LHWstatus: "maintenance",
    notificationEnabled: true,
  },
  {
    id: 10,
    isFavourite: true,
    name: "Sample Service 10",
    LHOstatus: "available",
    LHBstatus: "available",
    LHAPstatus: "available",
    LHPPstatus: "available",
    LHWstatus: "outage",
    notificationEnabled: true,
  },
];

export const serviceDetails = [
  {
    serviceId: 1,
    serviceName: "Sample Service 1",
    shortDescription:
      "This is a short description for an example of a service description, used for Sample Service 1.",
    recentStatus: "available",
    recentStatusUpdateSubject: "All systems restored",
    recentStatusUpdateDescription:
      "Services restored to Sample Service 1, all locations are available",
    recentStatusUpdateDateTime: "2022-01-10",
  },
  {
    serviceId: 2,
    serviceName: "Sample Service 2",
    shortDescription:
      "This is a short description for an example of a service description, used for Sample Service 2.",
    recentStatus: "outage",
    recentStatusUpdateSubject: "Network connectivity issues at LHB",
    recentStatusUpdateDescription:
      "No network at LHB is causing this service to be down",
    recentStatusUpdateDateTime: "2022-01-11",
  },
  {
    serviceId: 3,
    serviceName: "Sample Service 3",
    shortDescription:
      "This is a short description for an example of a service description, used for Sample Service 3.",
    recentStatus: "incident",
    recentStatusUpdateSubject: "Database connection error",
    recentStatusUpdateDescription:
      "Currently a 'SQL Timeout' issue affecting application, it is still up but experiencing issues",
    recentStatusUpdateDateTime: "2022-01-08",
  },
  {
    serviceId: 4,
    serviceName: "Sample Service 4",
    shortDescription:
      "This is a short description for an example of a service description, used for Sample Service 4.",
    recentStatus: "maintenance",
    recentStatusUpdateSubject: "LHAP Maintenance",
    recentStatusUpdateDescription:
      "Service is down for maintenance at LHAP, service will be restored in 30 mins",
    recentStatusUpdateDateTime: "2022-01-11",
  },
  {
    serviceId: 5,
    serviceName: "Sample Service 5",
    shortDescription:
      "This is a short description for an example of a service description, used for Sample Service 5.",
    recentStatus: "outage",
    recentStatusUpdateSubject: "LHW Issue",
    recentStatusUpdateDescription:
      "Service is down at LHW, tech is on route to investigate in person",
    recentStatusUpdateDateTime: "2022-01-10",
  },
  {
    serviceId: 6,
    serviceName: "Sample Service 6",
    shortDescription:
      "This is a short description for an example of a service description, used for Sample Service 6.",
    recentStatus: "outage",
    recentStatusUpdateSubject: "System Outage",
    recentStatusUpdateDescription:
      "Server appears to be down causing the application to be down at every site, tech is troubleshooting",
    recentStatusUpdateDateTime: "2022-01-09",
  },
  {
    serviceId: 7,
    serviceName: "Sample Service 7",
    shortDescription:
      "This is a short description for an example of a service description, used for Sample Service 7.",
    recentStatus: "outage",
    recentStatusUpdateSubject: "Outage at LHB",
    recentStatusUpdateDescription:
      "Service is down at LHB, citing network issue",
    recentStatusUpdateDateTime: "2022-01-10",
  },
  {
    serviceId: 8,
    serviceName: "Sample Service 8",
    shortDescription:
      "This is a short description for an example of a service description, used for Sample Service 8.",
    recentStatus: "incident",
    recentStatusUpdateSubject: "SQL Issue impacting all sites",
    recentStatusUpdateDescription:
      "Service has a database issue, IL Team is investigating",
    recentStatusUpdateDateTime: "2022-01-11",
  },
  {
    serviceId: 9,
    serviceName: "Sample Service 9",
    shortDescription:
      "This is a short description for an example of a service description, used for Sample Service 9.",
    recentStatus: "maintenance",
    recentStatusUpdateSubject: "Service Maintenance",
    recentStatusUpdateDescription:
      "Maintenance being performed on service's server, service should be restored at 2pm",
    recentStatusUpdateDateTime: "2022-01-10",
  },
  {
    serviceId: 10,
    serviceName: "Sample Service 10",
    shortDescription:
      "This is a short description for an example of a service description, used for Sample Service 10.",
    recentStatus: "outage",
    recentStatusUpdateSubject:
      "LHW Service is down at LHW, tech is going there already",
    recentStatusUpdateDateTime: "2022-01-10",
  },
];

export const OpenStatusUpdates = [
  {
    openStatusId: 1,
    serviceId: 1,
    statusUpdateHeader: "Incident",
    statusUpdateShortDesc: "Example short desc of what happened",
    statusUpdateFullDesc: "",
    statusUpdateDateTime: "2023-01-20",
    resolvedDateTime: "2022-01-23 7:00AM",
    resolvedMessageHeader: "All systems restored",
    resolvedMessageShortDesc: "Example short desc of resolution",
    resolvedMessageLongDesc: "",
  },
  {
    openStatusId: 2,
    serviceId: 1,
    statusUpdateHeader: "Outage at LHO",
    statusUpdateShortDesc: "Example short desc of reported outage",
    statusUpdateFullDesc: "",
    statusUpdateDateTime: "2023-01-22",
    resolvedDateTime: "2023-01-23 7:00AM",
    resolvedMessageHeader: "All systems restored",
    resolvedMessageShortDesc: "Example short desc of resolution",
    resolvedMessageLongDesc: "",
  },
  {
    openStatusId: 3,
    serviceId: 1,
    statusUpdateHeader: "Maintenance at LHO",
    statusUpdateShortDesc: "Example short desc of maintenance timelines",
    statusUpdateFullDesc: "",
    statusUpdateDateTime: "2023-01-22",
    resolvedDateTime: "2023-01-23 7:00AM",
    resolvedMessageHeader: "All systems restored",
    resolvedMessageShortDesc: "Example short desc of resolution",
    resolvedMessageLongDesc: "",
  },
  {
    openStatusId: 4,
    serviceId: 1,
    statusUpdateHeader: "All systems restored",
    statusUpdateShortDesc: "Example short desc of resolution",
    statusUpdateFullDesc: "",
    statusUpdateDateTime: "2023-01-23",
    resolvedDateTime: "2023-01-23 7:00AM",
    resolvedMessageHeader: "All systems restored",
    resolvedMessageShortDesc: "Example short desc of resolution",
    resolvedMessageLongDesc: "",
  },
];

export const OpenStatusSites = [
  {
    openStatusId: 1,
    affectedSiteId: 1,
    status: "incident",
  },
  {
    openStatusId: 1,
    affectedSiteId: 2,
    status: "incident",
  },
  {
    openStatusId: 1,
    affectedSiteId: 3,
    status: "incident",
  },
  {
    openStatusId: 1,
    affectedSiteId: 4,
    status: "incident",
  },
  {
    openStatusId: 1,
    affectedSiteId: 5,
    status: "incident",
  },
  {
    openStatusId: 2,
    affectedSiteId: 1,
    status: "outage",
  },
  {
    openStatusId: 3,
    affectedSiteId: 1,
    status: "maintenance",
  },
  {
    openStatusId: 4,
    affectedSiteId: 1,
    status: "available",
  },
  {
    openStatusId: 4,
    affectedSiteId: 2,
    status: "available",
  },
  {
    openStatusId: 4,
    affectedSiteId: 3,
    status: "available",
  },
  {
    openStatusId: 4,
    affectedSiteId: 4,
    status: "available",
  },
  {
    openStatusId: 4,
    affectedSiteId: 5,
    status: "available",
  },
];

export const Sites = [
  {
    siteId: 1,
    siteName: "LHO",
  },
  {
    siteId: 2,
    siteName: "LHAP",
  },
  {
    siteId: 3,
    siteName: "LHB",
  },
  {
    siteId: 4,
    siteName: "LHPP",
  },
  {
    siteId: 5,
    siteName: "LHW",
  },
];
